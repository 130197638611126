import React, {Dispatch, SetStateAction, useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Popover,
    Select,
    SelectChangeEvent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    FilterList as FilterListIcon,
    Search as SearchIcon
} from '@mui/icons-material';
import {IFilterListLegacyQuotationFlight, IListLegacyQuotationFlight} from "../../types";
import {ISort} from "../../../../../../core/types";
import EmptyTable from "../../../../../../core/components/EmptyTable/EmptyTable";
import dayjs, {Dayjs} from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    defaultFilterLegacyQuotationFlightList
} from "../../containers/LegacyQuotationFlightListContainer/LegacyQuotationFlightListContainer";
import LinkWithCopy from "../../../commons/components/LinkWithCopy/LinkWithCopy";

interface LegacyQuotationsFlightListProps {
    title: string;
    search: string;
    setSearch: (search: string) => void;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    filters: IFilterListLegacyQuotationFlight;
    setFilters: (filter: IFilterListLegacyQuotationFlight) => void;
    order: ISort[],
    setOrder: Dispatch<SetStateAction<ISort[]>>;
    items: IListLegacyQuotationFlight[];
    totalElements: number;
    onAdd: () => void;
    onEdit: (item: IListLegacyQuotationFlight) => void;
    onCopy: (item: IListLegacyQuotationFlight) => void;
    onDelete: (item: IListLegacyQuotationFlight) => void;
    onViewReadOnly: (item: IListLegacyQuotationFlight) => void;
}

const LegacyQuotationsFlightList: React.FC<LegacyQuotationsFlightListProps> = ({
                                                         title,
                                                         search,
                                                         setSearch,
                                                         page,
                                                         setPage,
                                                         pageSize,
                                                         totalElements,
                                                         setPageSize,
                                                         filters,
                                                         setFilters,
                                                         order,
                                                         setOrder,
                                                         items,
                                                         onAdd,
                                                         onEdit,
                                                         onDelete,
                                                         onCopy,
                                                         onViewReadOnly
                                                     }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<null | IListLegacyQuotationFlight>(null);
    const [internalFilters, setInternalFilters] = useState<IFilterListLegacyQuotationFlight>(defaultFilterLegacyQuotationFlightList);

    const [createdAtFrom, setCreatedAtFrom] = useState<Dayjs | null>(null);
    const [createdAtTo, setCreatedAtTo] = useState<Dayjs | null>(null);

    const [travelDateFrom, setTravelDateFrom] = useState<Dayjs | null>(null);
    const [travelDateTo, setTravelDateTo] = useState<Dayjs | null>(null);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleFilterChange = (field: keyof typeof filters) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalFilters({...filters, [field]: event.target.value});
    };

    const handleFilterChangeValue = (field: keyof typeof filters, value: string | number) => {
        setInternalFilters((prev) => ({...prev, [field]: value}));
    };

    const handleChangeOnlyMine = (event: SelectChangeEvent<unknown>) => {
        if (event.target.value !== undefined) {
            setInternalFilters((prev) => ({...prev, onlyMine: event.target.value == 'true'}));
        } else {
            setInternalFilters((prev) => ({...prev, onlyMine: undefined}));
        }

    };

    const handleApplyFilter = () => {
        setFilters(internalFilters);
        handleCloseMenu();
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property: string) => {
        const exist = order.find(o => o.field === property)
        const isAsc = order.find(o => o.field === property)?.order === 'asc';
        if (!exist) {
            setOrder(prevOrder => [
                ...prevOrder.filter(o => o.field !== property),
                {field: property, order: isAsc ? 'desc' : 'asc'},
            ]);
        } else {
            if (exist.order === 'asc') {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                    {field: property, order: 'desc'},
                ]);
            } else {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                ]);
            }
        }

    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleOpenDialog = (item: IListLegacyQuotationFlight | null = null) => {
        setSelectedItem(item);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedItem(null);
    };


    const handleDelete = () => {
        if (selectedItem) {
            onDelete(selectedItem);
            handleCloseDialog();
        }
    };

    const handleCancelFilters = () => {
        setInternalFilters(filters);
        handleCloseMenu();
    };


    return (
        <Box sx={{width: '100%', padding: 3}}>
            <Typography variant="h4" component="div" sx={{mb: 2}}>
                {title}
            </Typography>
            <Toolbar sx={{display: 'flex', justifyContent: 'space-between', mb: 2}} style={{paddingLeft: 0}}>
                <Button
                    variant="contained"
                    startIcon={<AddIcon/>}
                    onClick={() => onAdd()}
                >
                    Nueva cotización de vuelos
                </Button>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <TextField
                        variant="outlined"
                        placeholder="Buscar"
                        size="small"
                        value={search}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        sx={{mr: 2, width: 300}}
                    />
                    <Button variant="outlined" startIcon={<FilterListIcon/>} onClick={handleOpenMenu}>
                        Filtrar
                    </Button>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        disableRestoreFocus
                    >
                        <Box sx={{p: 2, display: 'flex', flexDirection: 'column', gap: 1}}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.quotationNumber}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('quotationNumber', value);
                                }}
                                label="Número de cotización igual a"
                                size="small"
                                type="text"

                            />
                            <TextField
                                label="Título contiene"
                                size='small'
                                value={internalFilters.title}
                                onChange={handleFilterChange('title')}
                                fullWidth
                            />
                            <TextField
                                label="Referencia contiene"
                                size='small'
                                value={internalFilters.reference}
                                onChange={handleFilterChange('reference')}
                                fullWidth
                            />



                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column', maxWidth: 300}}>
                                    <DatePicker
                                        label="Fecha de creación desde"
                                        format="YYYY-MM-DD"
                                        value={createdAtFrom ? dayjs(createdAtFrom) : null}
                                        onChange={(newValue) => {
                                            setCreatedAtFrom(newValue);
                                            handleFilterChangeValue('createdAtFrom', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                    <DatePicker
                                        label="Fecha de creación hasta"
                                        format="YYYY-MM-DD"
                                        value={createdAtTo ? dayjs(createdAtTo) : null}
                                        onChange={(newValue) => {
                                            setCreatedAtTo(newValue);
                                            handleFilterChangeValue('createdAtTo', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        minDate={createdAtFrom ? dayjs(createdAtFrom) : undefined}  // No permite seleccionar una fecha menor a la de inicio
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />

                                    <DatePicker
                                        label="Fecha de viaje desde"
                                        format="YYYY-MM-DD"
                                        value={travelDateFrom ? dayjs(travelDateFrom) : null}
                                        onChange={(newValue) => {
                                            setTravelDateFrom(newValue);
                                            handleFilterChangeValue('travelDateFrom', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                    <DatePicker
                                        label="Fecha de viaje hasta"
                                        format="YYYY-MM-DD"
                                        value={travelDateTo ? dayjs(travelDateTo) : null}
                                        onChange={(newValue) => {
                                            setTravelDateTo(newValue);
                                            handleFilterChangeValue('travelDateTo', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        minDate={travelDateFrom ? dayjs(travelDateFrom) : undefined}  // No permite seleccionar una fecha menor a la de inicio
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                </Box>
                            </LocalizationProvider>

                            <FormControl fullWidth>
                                <InputLabel id="only-mine-label">Solo las creadas por mi</InputLabel>
                                <Select
                                    size='small'
                                    labelId="only-mine-select-label"
                                    id="only-mine-select-label"
                                    value={internalFilters.onlyMine}
                                    label="Solo las creadas por mi"
                                    onChange={handleChangeOnlyMine}
                                >
                                    <MenuItem value={undefined} style={{minHeight: '24px'}}>
                                        <em></em>
                                    </MenuItem>
                                    <MenuItem value={'true'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="Sí"/>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value={'false'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="No"/>
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                label="Creado por contiene"
                                size='small'
                                value={internalFilters.createdBy}
                                onChange={handleFilterChange('createdBy')}
                                fullWidth
                            />
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                                <Button variant="contained" onClick={handleApplyFilter} sx={{mr: 2}}>
                                    Aplicar Filtros
                                </Button>
                                <Button variant="outlined" onClick={handleCancelFilters}>
                                    Cancelar
                                </Button>
                            </Box>
                        </Box>
                    </Popover>
                </Box>
            </Toolbar>
            <Typography variant="body2" sx={{mb: 1}}>
                Mostrando {items.length} resultados de {totalElements}
            </Typography>
            <TableContainer component="main" sx={{width: '100%'}}>
                <Table sx={{width: '100%'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'quotationNumber')}
                                    direction={order.find(o => o.field === 'quotationNumber')?.order || 'asc'}
                                    onClick={() => handleRequestSort('quotationNumber')}
                                >
                                    Número
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'title')}
                                    direction={order.find(o => o.field === 'title')?.order || 'asc'}
                                    onClick={() => handleRequestSort('title')}
                                >
                                    Título
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                            <TableSortLabel
                                active={order.some(o => o.field === 'reference')}
                                direction={order.find(o => o.field === 'reference')?.order || 'asc'}
                                onClick={() => handleRequestSort('reference')}
                            >
                                Referencia
                            </TableSortLabel>
                        </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'travelDate')}
                                    direction={order.find(o => o.field === 'travelDate')?.order || 'asc'}
                                    onClick={() => handleRequestSort('travelDate')}
                                >
                                    Fecha de viaje
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'createdAt')}
                                    direction={order.find(o => o.field === 'createdAt')?.order || 'asc'}
                                    onClick={() => handleRequestSort('createdAt')}
                                >
                                    Fecha de creación
                                </TableSortLabel>

                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'createdBy')}
                                    direction={order.find(o => o.field === 'createdBy')?.order || 'asc'}
                                    onClick={() => handleRequestSort('createdBy')}
                                >
                                    Creado por
                                </TableSortLabel>

                            </TableCell>

                            <TableCell>
                                URL para el cliente
                            </TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.quotationNumber}</TableCell>
                                <TableCell>{item.title}</TableCell>
                                <TableCell>{item.reference?item.reference:'-'}</TableCell>
                                <TableCell>{item.travelDate}</TableCell>
                                <TableCell>{item.createdAt}</TableCell>
                                <TableCell>{item.createdBy}</TableCell>
                                <LinkWithCopy url={item.urlToClient}></LinkWithCopy>
                                <TableCell>
                                    <Stack direction="row" spacing={1}>
                                        {item.isAllowEdit && <>
                                            <IconButton onClick={() => onEdit(item)} title={'Actualizar'}>
                                                <EditIcon/>
                                            </IconButton>
                                            <IconButton onClick={() => handleOpenDialog(item)} title={'Eliminar'}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </>}
                                        {!item.isAllowEdit && <>
                                            <IconButton onClick={() => onViewReadOnly(item)}
                                                        title={'Vista solo lectura'}>
                                                <VisibilityIcon/>
                                            </IconButton>

                                        </>}

                                        <IconButton onClick={() => onCopy(item)} title={'Copiar cotización'}>
                                            <ContentCopyIcon/>
                                        </IconButton>
                                    </Stack>

                                </TableCell>
                            </TableRow>
                        ))}
                        {items.length == 0 && <EmptyTable colSpan={4}/>}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage={'Filas por página'}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalElements}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>Eliminar Cotización</DialogTitle>
                <DialogContent>

                    <DialogContentText>
                        ¿Estás seguro de que deseas eliminar esta cotización?
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancelar</Button>
                    <Button onClick={handleDelete} color="primary">Eliminar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default LegacyQuotationsFlightList;
