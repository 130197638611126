import React from 'react';
import {Box, Card, CardContent, Divider, Stack, Typography, useTheme,} from '@mui/material';
import HotelIcon from '@mui/icons-material/Hotel';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupIcon from '@mui/icons-material/Group';
import BedIcon from '@mui/icons-material/Bed';
import PersonIcon from '@mui/icons-material/Person';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import {ILegacyQuotationPackageOptionPreview} from "../../types";

interface LegacyQuotationPackageOptionsPreviewProps {
    options: ILegacyQuotationPackageOptionPreview[];
}

const LegacyQuotationPackageOptionsPreview: React.FC<LegacyQuotationPackageOptionsPreviewProps> = ({ options }) => {
    const theme = useTheme();
    const roomTypesNames = options[0]?.roomTypeDistributionsPrices.map(x=> x.roomTypeName)||[];
    return (
        <Box
            sx={{
                border: 0,
                pl: "20px",
                mt: 1
            }}
        >
            <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                 sx={{backgroundColor: theme.palette.primary.main, borderRadius: 2}} mb={1} mr={2}>
                <Typography variant="h6" sx={{color: 'white'}}>
                   Opciones de hoteles
                </Typography>
            </Box>
            <Box mr={2}>
                <Stack spacing={2}>
                    {options.map((option, index) => (
                        <Card key={index} variant="outlined" sx={{ borderRadius: 3 }}>
                            <CardContent>
                                <Typography variant="h6" color="text.secondary" fontWeight={'bold'} gutterBottom>
                                    Opción #{index + 1}
                                </Typography>

                                {/* Hoteles */}
                                <Stack spacing={1} mb={2}>
                                    {option.hotelsDetail.map((hotel, i) => (
                                        <Box key={i}>
                                            <Typography variant="subtitle1" fontWeight="bold">
                                                <HotelIcon fontSize="small" sx={{ mr: 1, color: theme.palette.primary.main }} />
                                                {hotel.urlToViewDescription ? (
                                                    <a
                                                        href={hotel.urlToViewDescription}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        title={'Click para ver más información el hotel'}
                                                        style={{ cursor: "pointer", color: "inherit", textDecoration: "none" }}
                                                    >
                                                        {hotel.hotelName} - {hotel.destinationName}
                                                    </a>
                                                ) : (
                                                    `${hotel.hotelName} - ${hotel.destinationName}`
                                                )}
                                            </Typography>
                                            <Box display="flex" flexWrap="wrap" gap={2} ml={4}>
                                                {
                                                    roomTypesNames.map((roomTypeName)=>(
                                                    <Typography variant="body2" display="flex" alignItems="center">
                                                    <BedIcon fontSize="small" sx={{ mr: 0.5,'color': theme.palette.primary.main }} /> {roomTypeName}
                                                        </Typography>
                                                    ))

                                                }
                                                <Typography variant="body2" display="flex" alignItems="center">
                                                    <RestaurantIcon fontSize="small" sx={{ mr: 0.5,'color': theme.palette.primary.main }} /> {hotel.regimenName}
                                                </Typography>
                                                <Typography variant="body2" display="flex" alignItems="center">
                                                    <CalendarMonthIcon fontSize="small" sx={{ mr: 0.5 ,'color': theme.palette.primary.main}} /> {hotel.checkInDMY} - {hotel.checkOutDMY}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ))}
                                </Stack>

                                <Divider sx={{ mb: 2 }} />

                                {/* Precios */}
                                {option.roomTypeDistributionsPrices.map((price, j) => (
                                    <Box key={j} ml={1} mt={1}>
                                        <Typography variant="body2" fontWeight="bold" display="flex" alignItems="center">
                                            <GroupIcon fontSize="small" sx={{ mr: 1,'color': theme.palette.primary.main }} />
                                            Ocupación: {price.roomTypeName}
                                        </Typography>
                                        <ul style={{ margin: 0, paddingLeft: 32 }}>
                                            <li style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                                                <PersonIcon fontSize="small" sx={{ mr: 0.5,'color': theme.palette.primary.main }} /> Precio por adulto: <strong>USD {price.adultPrice}</strong>
                                            </li>
                                            {price.minorPrice != null && (
                                                <li style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                                                    <ChildCareIcon fontSize="small" sx={{ mr: 0.5,'color': theme.palette.primary.main }} /> Precio por menor: <strong>USD {price.minorPrice}</strong>
                                                </li>
                                            )}
                                        </ul>
                                    </Box>
                                ))}
                            </CardContent>
                        </Card>
                    ))}
                </Stack>
            </Box>

        </Box>
    );
};

export default LegacyQuotationPackageOptionsPreview;
