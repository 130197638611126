import React from "react";
import {Box, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme} from "@mui/material";
import {IListAllLegacyQuotationNoInclude} from "../../../legacy-quotation-no-includes/types";
import {IconRenderer} from "../IconRenderer/IconRenderer";

interface NoIncludePreviewPreviewProps {
    items: IListAllLegacyQuotationNoInclude[];
}

const NoIncludePreviewPreview: React.FC<NoIncludePreviewPreviewProps> = ({items}) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                border: 0,
                pl: "20px",
                mt: 1
            }}
        >
            <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                 sx={{backgroundColor: theme.palette.primary.main, borderRadius: 2}} mb={1} mr={2}>
                <Typography variant="h6" sx={{color: 'white'}}>
                    No incluye
                </Typography>
            </Box>
            <Box mr={2}>
                {items.length > 0 && <List dense>
                    {items.map((item) => (
                        <ListItem
                            key={item.id}
                        >
                            {item.iconName && (
                                <ListItemIcon
                                    sx={{
                                        // reduce the default minWidth (56px) to tighten spacing
                                        minWidth: 32,
                                    }}
                                >
                                    <IconRenderer iconName={item.iconName}/>
                                </ListItemIcon>
                            )}
                            <ListItemText primary={item.name}/>
                        </ListItem>
                    ))}
                </List>}
            </Box>

        </Box>
    );
};

export default NoIncludePreviewPreview;
