import React, {forwardRef, useEffect} from 'react';
import {v4 as uuidv4} from 'uuid';
import {Autocomplete, Box, Button, IconButton, TextField} from '@mui/material';
import {AddCircle, Refresh} from '@mui/icons-material';
import {FixedSizeList, ListChildComponentProps} from "react-window";
// react-hook-form
import {Control, Controller, useFieldArray, useFormContext, useWatch,} from 'react-hook-form';

// date pickers
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

// Tu interfaz de destino
import {IAutoCompleteDestinationList} from '../../../../../destinations/types';
import DeleteIcon from "@mui/icons-material/Delete";

// Interfaz para cada item del array (campos que se guardarán en el form)
export interface IDestinationFormItem {
    quotationDestinationId: string;
    destination: IAutoCompleteDestinationList | null;  // O undefined
    checkIn: string | null;                // Guardamos como string (ISO) para facilitar
    checkOut: string | null;
    nights: number;
}

// Props que recibirá el subcomponente
interface MultiDestinationFormProps {
    control: Control<any>;                    // Control genérico de React Hook Form
    availableDestinations: IAutoCompleteDestinationList[];  // Lista de opciones para el Autocomplete
    name?: string;                            // Nombre opcional del fieldArray (por defecto 'destinations')
    refreshData: () => Promise<void>;
    readonly?: boolean
}


const LISTBOX_PADDING = 8; // Espaciado interno para la lista

// Crear un componente de lista personalizado para Material UI
const ListboxComponent = forwardRef<HTMLDivElement, any>((props, ref) => {
    const {children, ...other} = props;
    const itemCount = Array.isArray(children) ? children.length : 0;

    return (
        <div ref={ref} {...other}>
            <FixedSizeList
                height={300} // Altura del contenedor
                width="100%"
                itemSize={40} // Altura de cada elemento
                itemCount={itemCount} // Número de elementos
                overscanCount={5} // Renderizar algunos elementos extra para fluidez
            >
                {({index, style}: ListChildComponentProps) => (
                    <div style={{...style, paddingLeft: LISTBOX_PADDING}}>
                        {children[index]}
                    </div>
                )}
            </FixedSizeList>
        </div>
    );
});

const MultiDestinationForm: React.FC<MultiDestinationFormProps> = ({
                                                                       control,
                                                                       availableDestinations,
                                                                       name = 'destinations',
                                                                       refreshData,
                                                                       readonly = false
                                                                   }) => {
    // Para poder usar setValue, podemos acceder al formContext
    const {setValue} = useFormContext();

    // useFieldArray nos permite manejar un array de items (destinos).
    const {fields, append, remove} = useFieldArray({
        control,
        name, // "destinations" por defecto
    });

    /**
     * Observamos todos los valores del array "destinations" en tiempo real.
     */
    const watchRows = useWatch({
        control,
        name,
    }) as IDestinationFormItem[];

    /**
     * Cuando agregamos una nueva línea:
     * - Si la última tenía un checkOut, usamos ese valor como checkIn de la nueva.
     */
    const handleAdd = () => {
        const lastIndex = watchRows.length - 1;
        if (lastIndex >= 0) {
            const lastRow = watchRows[lastIndex];
            if (lastRow.checkOut) {
                append({
                    quotationDestinationId: uuidv4(),
                    destination: null,
                    checkIn: lastRow.checkOut,
                    checkOut: null,
                    nights: 0,
                });
                return;
            }
        }
        // Si no hay última línea o no tiene checkOut, creamos una línea vacía
        append({
            quotationDestinationId: uuidv4(),
            destination: null,
            checkIn: null,
            checkOut: null,
            nights: 0,
        });
    };

    /**
     * useEffect para recalcular nights y checkOut cuando cambian los valores.
     * También encadena checkIn de la siguiente línea con el checkOut de la línea actual si la siguiente línea no tiene checkIn.
     */
    useEffect(() => {
        watchRows.forEach((row, index) => {
            const {checkIn, checkOut, nights} = row;

            // (1) Si tengo nights > 0 y checkIn, recalcular checkOut = checkIn + nights
            if (checkIn && nights > 0) {
                const checkInDate = dayjs(checkIn);
                const computedCheckOut = checkInDate.add(nights, 'day');

                // Solo actualizamos si difiere de lo que ya está
                if (!checkOut || !computedCheckOut.isSame(dayjs(checkOut), 'day')) {
                    setValue(`${name}.${index}.checkOut`, computedCheckOut.toISOString());
                }
            }

            // (2) Si tengo checkIn y checkOut, pero nights == 0,
            //     calculamos nights = (checkOut - checkIn) en días
            if (checkIn && checkOut && nights === 0) {
                const diff = dayjs(checkOut).diff(dayjs(checkIn), 'day');
                if (diff > 0) {
                    setValue(`${name}.${index}.nights`, diff);
                }
            }
        });

        // (3) Encadenar checkIn de la fila (i+1) con el checkOut de la fila i
        //     Solo si i+1 existe y no tiene checkIn definido.
        for (let i = 0; i < watchRows.length - 1; i++) {
            const currentRow = watchRows[i];
            const nextRow = watchRows[i + 1];
            if (currentRow.checkOut && !nextRow.checkIn) {
                setValue(`${name}.${i + 1}.checkIn`, currentRow.checkOut);
            }
        }
    }, [watchRows, name, setValue]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box display="flex" flexDirection="column" gap={2}>
                {fields.map((item, index) => (
                    <Box
                        key={item.id}
                        display="flex"
                        flexWrap="wrap"
                        alignItems="center"
                        gap={2}
                        sx={{p: 2, border: '1px solid #ccc', borderRadius: 2}}
                    >
                        {/* DESTINO (Autocomplete) */}
                        <Controller
                            name={`${name}.${index}.destination`}
                            control={control}
                            rules={{
                                validate: (val) => val !== null || "El destino es requerido",
                            }}
                            render={({field, fieldState}) => (
                                <Autocomplete
                                    sx={{width: 400}}
                                    disabled={readonly}
                                    options={availableDestinations}
                                    getOptionLabel={(option) =>
                                        `${option.code} - ${option.name}, ${option.countryName}`
                                    }
                                    value={field.value || null}
                                    onChange={(_, newValue) => field.onChange(newValue)}
                                    isOptionEqualToValue={(option, val) => option.code === val?.code}
                                    ListboxComponent={ListboxComponent} // 👈 Usamos la lista optimizada
                                    renderInput={(params) => (
                                        <TextField
                                            required={true}
                                            {...params}
                                            label="Destino"
                                            variant="outlined"
                                            disabled={readonly}
                                            size="small"
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {params.InputProps.endAdornment}
                                                        <IconButton onClick={refreshData} title="Refrescar opciones"
                                                                    size="small" disabled={readonly}>
                                                            <Refresh/>
                                                        </IconButton>
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />

                        {/* CHECK IN */}
                        <Controller
                            name={`${name}.${index}.checkIn`}
                            control={control}
                            // VALIDACIÓN: checkIn es requerido
                            rules={{
                                required: 'La fecha de Check In es obligatoria',
                            }}
                            render={({field, fieldState}) => {
                                const currentValue = field.value
                                    ? dayjs(field.value as string)
                                    : null;
                                return (
                                    <DatePicker
                                        label="Check In"
                                        format="YYYY-MM-DD"
                                        value={currentValue}
                                        disabled={readonly}
                                        onChange={(newDate) => {
                                            field.onChange(newDate ? newDate.toISOString() : null);
                                        }}
                                        slots={{
                                            textField: (textFieldProps) => (
                                                <TextField
                                                    required={true}
                                                    {...textFieldProps}
                                                    variant="outlined"
                                                    disabled={readonly}
                                                    sx={{width: 200}}
                                                    size="small"
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                />
                                            ),
                                        }}
                                    />
                                );
                            }}
                        />

                        {/* CHECK OUT */}
                        <Controller
                            name={`${name}.${index}.checkOut`}
                            control={control}
                            // VALIDACIÓN: checkOut es requerido
                            rules={{
                                required: 'La fecha de Check Out es obligatoria',
                            }}
                            render={({field, fieldState}) => {
                                const currentValue = field.value
                                    ? dayjs(field.value as string)
                                    : null;

                                // Para minDate, tomamos la fecha de checkIn
                                const checkInVal = watchRows[index]?.checkIn;
                                const minDate = checkInVal ? dayjs(checkInVal) : undefined;

                                return (
                                    <DatePicker
                                        label="Check Out"
                                        format="YYYY-MM-DD"
                                        value={currentValue}
                                        disabled={readonly}
                                        minDate={minDate} // deshabilita fechas antes de checkIn
                                        onChange={(newDate) => {
                                            field.onChange(newDate ? newDate.toISOString() : null);
                                        }}
                                        slots={{
                                            textField: (textFieldProps) => (
                                                <TextField
                                                    required={true}
                                                    {...textFieldProps}
                                                    variant="outlined"
                                                    disabled={readonly}
                                                    sx={{width: 200}}
                                                    size="small"
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                />
                                            ),
                                        }}
                                    />
                                );
                            }}
                        />

                        {/* NOCHES */}
                        <Controller
                            name={`${name}.${index}.nights`}
                            control={control}
                            // VALIDACIÓN: requerido y mínimo 1
                            rules={{
                                required: 'Las noches son obligatorias',
                                min: {
                                    value: 1,
                                    message: 'Mínimo 1 noche',
                                },
                            }}
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    required={true}
                                    label="Noches"
                                    disabled={readonly}
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    sx={{width: 100}}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                />
                            )}
                        />

                        {/* Eliminar línea (solo si hay más de 1 o si lo deseas) */}
                        {fields.length > 1 && !readonly && (
                            <IconButton color="error" onClick={() => remove(index)}>
                                <DeleteIcon/>
                            </IconButton>
                        )}
                    </Box>
                ))}

                {/* Botón para añadir líneas */}
                {!readonly && <Button
                    variant="outlined"
                    onClick={handleAdd}
                    startIcon={<AddCircle/>}
                >
                    Agregar destino
                </Button>}
            </Box>
        </LocalizationProvider>
    );
};

export default MultiDestinationForm;
