import React from 'react';
import { Card, CardContent } from '@mui/material';
import { IFlightItinerarySegmentInformation } from '../../../legacy-quotation-packages/types';
import { FlightItinerarySegmentMobile } from './FlightItinerarySegmentMobile';
import useBreakpoints from '../../../../../../core/useBreakPoints';

interface Props {
    segments: IFlightItinerarySegmentInformation[];
}

export const FlightItineraryMobile = (props: Props): JSX.Element => {
    const { segments } = props;
    const { smallDown } = useBreakpoints();

    const cardStyles = {
        mt: 1,
        pl: 0,
        pr: 0,
        minHeight: '100%',
        width: smallDown ? '100%' : 'auto'
    };

    const contentStyles = {
        minWidth: smallDown ? '85vw' : '640px',
        // Puedes agregar padding adicional o margin según necesites
        p: smallDown ? 1 : 2
    };

    return (
        <Card sx={cardStyles}>
            <CardContent sx={contentStyles}>
                {segments.map((s) => (
                    <FlightItinerarySegmentMobile key={s.flightNumber} segment={s} />
                ))}
            </CardContent>
        </Card>
    );
};
