import {
    IAutoCompleteDestinationList,
    IDestinationCreate,
    IDestinationDetail,
    IDestinationList,
    IDestinationListFilter,
    IDestinationUpdate
} from "./types";
import {ApiService} from "../../core/services";
import {AxiosResponse} from "axios";
import {IPaginatedResult, ISort} from "../../core/types";
import {cleanFilters, formatSortingFields} from "../../core/helper";

const endpoints = {
    list: 'api/destinations',
    listAll: 'api/destinations/list/all',
    get: 'api/destinations/:code',
    listCountries: 'api/countries',
    create: 'api/destinations',
    update: 'api/destinations/:code',
    delete: 'api/destinations/:code'
}

export const getAllDestinations = (): Promise<AxiosResponse<IAutoCompleteDestinationList[]>> => {
    return ApiService.get(endpoints.listAll);
};
export const getDestinations = (page = 1, pageSize = 10, sortingFields: ISort[], search:string, filters?: IDestinationListFilter): Promise<AxiosResponse<IPaginatedResult<IDestinationList>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(filters && cleanFilters(filters)), ...(search && search!=='' && {search:search})
        }
    });
};
export const getDestination = (code:string): Promise<AxiosResponse<IDestinationDetail>> => ApiService.get(endpoints.get.replace(':code',code));
export const createDestination = (data: IDestinationCreate): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create, data);
export const updateDestination = (code: string, data: IDestinationUpdate): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':code', code), data);
export const deleteDestination = (code: string): Promise<AxiosResponse<void>> => ApiService.delete(endpoints.delete.replace(':code', code));
