import React from 'react';
import { Card, CardContent, Grid, Typography, Box } from '@mui/material';
import FlightIcon from '@mui/icons-material/Flight';
import { IFlightItinerarySegmentInformation } from '../../../legacy-quotation-packages/types';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import dayjs from 'dayjs';

const styles = {
    card: { mt: { xs: 0.5, sm: 1 }, width: '100%' },
    airlineName: {
        fontSize: { xs: '12px', sm: '14px' },
        fontStyle: 'normal',
        fontWeight: 400,
        color: '#7B7B7B'
    },
    dateInfo: {
        fontSize: { xs: '14px', sm: '16px' },
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#7B7B7B',
        textAlign: 'right',
        whiteSpace: { xs: 'normal', sm: 'nowrap' }
    },
    logo: {
        height: { xs: '1rem', sm: '1.2rem' },
        mt: { xs: '8px', sm: '12px' }
    },
    airportCode: {
        fontSize: { xs: '12px', sm: '16px' },
        fontStyle: 'normal',
        fontWeight: 400,
        color: '#7B7B7B',
        whiteSpace: { xs: 'normal', sm: 'nowrap' }
    },
    hour: {
        fontSize: { xs: '16px', sm: '18px' },
        fontStyle: 'normal',
        fontWeight: 700,
        color: '#7B7B7B'
    },
    hourAlightRight: {
        fontSize: { xs: '16px', sm: '18px' },
        fontStyle: 'normal',
        fontWeight: 700,
        color: '#7B7B7B',
        textAlign: 'right',
        whiteSpace: { xs: 'normal', sm: 'nowrap' }
    },
    airportCodeAlightRight: {
        fontSize: { xs: '12px', sm: '16px' },
        fontStyle: 'normal',
        fontWeight: 400,
        color: '#7B7B7B',
        textAlign: 'right',
        whiteSpace: { xs: 'normal', sm: 'nowrap' }
    },
    separatorContainer: {
        position: 'relative',
        width: '100%',
        mt: { xs: '8px', sm: '10px' },
        ml: { xs: '10px', sm: '15px' },
        mr: { xs: '10px', sm: '15px' },
        textAlign: 'center'
    },
    separatorLine: (theme: any) => ({
        height: '2px',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '4px',
        boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.25)'
    }),
    flightIcon: (theme: any) => ({
        position: 'absolute',
        color: theme.palette.primary.main,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(90deg)',
        backgroundColor: 'white',
        borderRadius: '50%',
        p: { xs: '1px', sm: '2px' }
    }),
    days: {
        fontSize: { xs: '16px', sm: '18px' },
        fontStyle: 'normal',
        fontWeight: 400,
        color: '#F51F20',
        pl: { xs: '3px', sm: '5px' },
        textAlign: 'right',
        whiteSpace: { xs: 'normal', sm: 'nowrap' }
    }
};

interface Props {
    segment: IFlightItinerarySegmentInformation;
}

export const FlightItinerarySegmentMobile = (props: Props): JSX.Element => {
    const { segment } = props;
    const departureDate = dayjs(segment.departure.date, 'YYYY-MM-DD');
    const arrivalDate = dayjs(segment.arrival.date, 'YYYY-MM-DD');
    const dayDifference: number = arrivalDate.diff(departureDate, 'days');

    const formatDateToRenderToUser = (dateStr: string, hourStr: string): string => {
        const combinedDate = new Date(`${dateStr}T${hourStr}`);
        return format(combinedDate, 'EEE dd MMM - Y', { locale: es });
    };

    return (
        <Card sx={styles.card}>
            <CardContent>
                <Grid container>
                    <Grid item xs={1}>
                        <Box
                            component="img"
                            src={segment.airline.image}
                            alt={segment.airline.name}
                            sx={styles.logo}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography sx={styles.airlineName} component="p" variant="body2">
                            {segment.airline.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography sx={styles.dateInfo} component="p" variant="body2">
                            {formatDateToRenderToUser(segment.departure.date, segment.departure.hour)}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={3}>
                        <Typography sx={styles.airportCode} component="p" variant="body2">
                            {segment.departure.airport.code}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={styles.separatorContainer}>
                            <Box sx={(theme) => styles.separatorLine(theme)} />
                            <FlightIcon sx={(theme) => styles.flightIcon(theme)} />
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography sx={styles.airportCodeAlightRight} component="p" variant="body2">
                            {segment.arrival.airport.code}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={5}>
                        <Typography sx={styles.airportCode} component="p" variant="body2">
                            {segment.departure.airport.cityName}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={5}>
                        <Typography sx={styles.airportCodeAlightRight} component="p" variant="body2">
                            {segment.arrival.airport.cityName}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={3}>
                        <Typography sx={styles.hour} component="p" variant="body2">
                            {segment.departure.hour.substring(0, 5)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={3}>
                        {dayDifference > 0 && (
                            <Typography sx={styles.days} component="p" variant="body2">
                                {'+'}{dayDifference}
                            </Typography>
                        )}
                        <Typography sx={styles.hourAlightRight} component="p" variant="body2">
                            {segment.arrival.hour.substring(0, 5)}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
