import React from 'react';
import {Autocomplete, Box, Button, Card, CardContent, FormControl, styled, TextField, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {useNavigate} from "react-router-dom";
import {IDetailLegacyQuotationAdditional, ILegacyQuotationAdditional} from "../../types";
import {appRoutes} from "../../../../../../core/const";
import {IconSelect} from "../../../commons/components/IconSelect/IconSelect";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {IDestinationList} from "../../../../../destinations/types";

export interface LegacyQuotationAdditionalFormProps {
    handleSubmitForm: (value: ILegacyQuotationAdditional) => void;
    loading: boolean;
    showReturnToList?:boolean;
    handleClose?: ()=>void;
    value?: IDetailLegacyQuotationAdditional;
    destinations: IDestinationList[];
    destinationCode?:string
}
const DivTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

export const LegacyQuotationAdditionalUpsertForm: React.FC<LegacyQuotationAdditionalFormProps> = ({
                                                                                                        handleSubmitForm,
                                                                                                        loading,
                                                                                                      showReturnToList = true,
                                                                                                      handleClose,
                                                                                                        value,
                                                                                                      destinations,
                                                                                                      destinationCode
                                                                                                    }) => {

    const methods = useForm<ILegacyQuotationAdditional>({
        mode: 'all',
        defaultValues: {name: value?.name || '',iconName: value?.iconName || '', destinationCode:value?.destinationCode||destinationCode||''}
    });

    const navigate = useNavigate();
    const {
        control,
        formState: {errors, isValid},
        getValues,
    } = methods;


    return (
        <>
            <Card sx={{maxWidth: 600, width: '100%'}}>
                <CardContent>
                    <DivTitle>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>{value ? 'Actualizar adicional' : 'Crear nuevo adicional'}</Typography>
                        {handleClose && <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>}
                    </DivTitle>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>

                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        maxLength: {
                                            value: 500,
                                            message: 'El campo excede el largo máximo permitido de: 500 caracteres'
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            label="Nombre"
                                            size={'small'}
                                            type="text"
                                            helperText={errors.name ? errors.name.message : ''}
                                            error={!!errors.name}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <IconSelect control={control} name="iconName" />
                            </Box>

                        <Box display="flex" alignItems="center" gap={2} flex={3} p={1}>
                            <Controller
                                name="destinationCode"
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'}
                                }}
                                control={control}
                                render={({field}) => (
                                    <FormControl sx={{width: '100%'}}>
                                        <Autocomplete
                                            options={destinations}
                                            getOptionLabel={(option: IDestinationList) => `${option.code}-${option.name}, ${option.countryName}`}
                                            value={
                                                destinations.find((option) => option.code === field.value) || null
                                            }
                                            onChange={(event, value) => {
                                                const selectedValues = (value as IDestinationList)?.code || '';
                                                field.onChange(selectedValues);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size={'small'}
                                                    required
                                                    variant="outlined"
                                                    label="Seleccione el destino"
                                                    disabled={destinationCode!=null}
                                                    fullWidth
                                                    helperText={errors.destinationCode ? errors.destinationCode.message : ''}
                                                    error={!!errors.destinationCode}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}
                            />
                        </Box>


                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            {showReturnToList && <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate(`${appRoutes.legacyQuotations.additional.list}`)}
                            >
                                Volver al listado
                            </Button>}
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={() => handleSubmitForm(getValues())}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};