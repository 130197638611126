// tourismIcons.ts
import {
    Attractions,
    AirplaneTicket,
    BeachAccess,
    DirectionsBoat,
    DirectionsBus,
    DirectionsCar,
    DirectionsRailway,
    Flight,
    Hotel,
    LocalAirport,
    LocalBar,
    LocalCafe,
    LocalDining,
    Luggage,
    Park,
    TransferWithinAStation, // Ícono de una persona moviéndose dentro de una estación (representa un traslado interno)
    AirportShuttle,         // Ícono de autobús, útil para shuttles de aeropuerto o transportes
    MultipleStop,           // Ícono de flechas curvadas conectando varios puntos (paradas múltiples)
    ConnectingAirports,     // Ícono de dos aviones conectados (conexión de vuelos)
    SyncAlt,                // Flechas horizontales opuestas, simboliza intercambio o transferencia
    SwapHoriz,              // Flechas horizontales opuestas, similar a SyncAlt
    CompareArrows,
    Policy,
    VerifiedUser,
    Security,
    HealthAndSafety,
    GppGood,
    Wifi,
    LocalParking,
    Pets,
    AcUnit,
    Restaurant,
    Pool,
    HotTub,
    FitnessCenter,
    BusinessCenter,
    SmokeFree,
    Elevator,
    CleaningServices,
    Kitchen,
    // Visa / Documentos
    CardTravel,
    Badge,
    DocumentScanner,
    FactCheck,
    AssignmentInd,

    // City Tour
    LocationCity,
    TravelExplore,
    Tour,
    Museum,
    TempleBuddhist,
    TempleHindu,
    Synagogue,
    Church,
    Mosque,
    Castle,
    DirectionsSubway,

    // Paseos
    DirectionsWalk,
    Hiking,
    NaturePeople,
    Terrain,
    Landscape,
    NordicWalking,
    DirectionsBike,
    Rowing,
    Kayaking,
    Kitesurfing,
    Surfing,
    NoLuggage,
    Accessible,
    ShoppingBag,
    NotAccessible,
    CarRental,
    CheckBox,
    Check,
    Dining,
    Map,
    ConfirmationNumber,
    LocalMall,
    Backpack,
    NoBackpack, PriceCheck
} from "@mui/icons-material";

import { SvgIconProps } from "@mui/material";
import { FC } from "react";

export interface TourismIconItem {
    name: string;
    Icon: FC<SvgIconProps>;
}

export const tourismIcons: TourismIconItem[] = [
    { name: "Attractions", Icon: Attractions },
    { name: "AirplaneTicket", Icon: AirplaneTicket },
    { name: "BeachAccess", Icon: BeachAccess },
    { name: "DirectionsBoat", Icon: DirectionsBoat },
    { name: "DirectionsBus", Icon: DirectionsBus },
    { name: "DirectionsCar", Icon: DirectionsCar },
    { name: "DirectionsRailway", Icon: DirectionsRailway },
    { name: "Map", Icon: Map },
    { name: "ConfirmationNumber", Icon: ConfirmationNumber },
    { name: "Flight", Icon: Flight },
    { name: "Hotel", Icon: Hotel },
    { name: "CarRental", Icon: CarRental },
    { name: "LocalAirport", Icon: LocalAirport },
    { name: "LocalBar", Icon: LocalBar },
    { name: "LocalCafe", Icon: LocalCafe },
    { name: "Dining", Icon: Dining },
    { name: "LocalDining", Icon: LocalDining },
    { name: "Luggage", Icon: Luggage },
    { name: "NoLuggage", Icon: NoLuggage },
    { name: "Accessible", Icon: Accessible },
    { name: "NotAccessible", Icon: NotAccessible },
    { name: "ShoppingBag", Icon: ShoppingBag },
    { name: "LocalMall", Icon: LocalMall },
    { name: "PriceCheck", Icon: PriceCheck },
    { name: "Backpack", Icon: Backpack },
    { name: "NoBackpack", Icon: NoBackpack },
    { name: "Park", Icon: Park },
    { name: "CheckBox", Icon: CheckBox },
    { name: "Check", Icon: Check },
     {
        name: "TransferWithinAStation",
        Icon: TransferWithinAStation
    },
    {
        name: "AirportShuttle",
        Icon: AirportShuttle
    },
    {
        name: "MultipleStop",
        Icon: MultipleStop
    },
    {
        name: "ConnectingAirports",
        Icon: ConnectingAirports
    },
    {
        name: "SyncAlt",
        Icon: SyncAlt
    },
    {
        name: "SwapHoriz",
        Icon: SwapHoriz
    },
    {
        name: "CompareArrows",
        Icon: CompareArrows
    },
    { name: "Policy", Icon: Policy },
    { name: "VerifiedUser", Icon: VerifiedUser },
    { name: "Security", Icon: Security },
    { name: "HealthAndSafety", Icon: HealthAndSafety },
    { name: "GppGood", Icon: GppGood },
    { name: "Wifi", Icon: Wifi },
    { name: "LocalParking", Icon: LocalParking },
    { name: "Pets", Icon: Pets },
    { name: "AcUnit", Icon: AcUnit },
    { name: "Restaurant", Icon: Restaurant },
    { name: "LocalCafe", Icon: LocalCafe },
    { name: "LocalBar", Icon: LocalBar },
    { name: "Pool", Icon: Pool },
    { name: "HotTub", Icon: HotTub },
    { name: "FitnessCenter", Icon: FitnessCenter },
    { name: "BusinessCenter", Icon: BusinessCenter },
    { name: "SmokeFree", Icon: SmokeFree },
    { name: "Elevator", Icon: Elevator },
    { name: "CleaningServices", Icon: CleaningServices },
    { name: "Kitchen", Icon: Kitchen },
    // Visa/Documentos
    { name: "CardTravel", Icon: CardTravel },
    { name: "Badge", Icon: Badge },
    { name: "DocumentScanner", Icon: DocumentScanner },
    { name: "FactCheck", Icon: FactCheck },
    { name: "AssignmentInd", Icon: AssignmentInd },

    // City Tour
    { name: "LocationCity", Icon: LocationCity },
    { name: "TravelExplore", Icon: TravelExplore },
    { name: "Tour", Icon: Tour },
    { name: "Museum", Icon: Museum },
    { name: "TempleBuddhist", Icon: TempleBuddhist },
    { name: "TempleHindu", Icon: TempleHindu },
    { name: "Synagogue", Icon: Synagogue },
    { name: "Church", Icon: Church },
    { name: "Mosque", Icon: Mosque },
    { name: "Castle", Icon: Castle },
    { name: "DirectionsSubway", Icon: DirectionsSubway },

    // Paseos
    { name: "DirectionsWalk", Icon: DirectionsWalk },
    { name: "Hiking", Icon: Hiking },
    { name: "NaturePeople", Icon: NaturePeople },
    { name: "Terrain", Icon: Terrain },
    { name: "Landscape", Icon: Landscape },
    { name: "NordicWalking", Icon: NordicWalking },
    { name: "DirectionsBike", Icon: DirectionsBike },
    { name: "Rowing", Icon: Rowing },
    { name: "Kayaking", Icon: Kayaking },
    { name: "Kitesurfing", Icon: Kitesurfing },
    { name: "Surfing", Icon: Surfing },
];
