import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HotelIcon from '@mui/icons-material/Hotel';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import dayjs from 'dayjs';

interface LegacyQuotationDestinationLineProps {
    name: string;
    nights: number;
    checkIn: string; // formato ISO
    checkOut: string; // formato ISO
}

export const LegacyQuotationDestinationLine: React.FC<LegacyQuotationDestinationLineProps> = ({ name, nights, checkIn,checkOut }) => {
    const checkInDate = dayjs(checkIn);
    const checkOutDate = dayjs(checkOut);

    return (
        <Box
            sx={{
                border: '1px solid #e0e0e0',
                borderRadius: 2,
                p: 1.5,
                minWidth: 220,
                width: '100%',
                backgroundColor: '#fafafa',
                boxShadow: 1,
            }}
        >
            <Stack direction="row" alignItems="center" spacing={1}>
                <LocationOnIcon color="primary" />
                <Typography variant="subtitle1">{name}</Typography>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={1} mt={1}>
                <HotelIcon fontSize="small" />
                <Typography variant="body2">{nights} noches</Typography>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={1}>
                <CalendarTodayIcon fontSize="small" />
                <Typography variant="body2">
                    Check-in: {checkInDate.format('DD/MM/YYYY')}
                </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={1}>
                <EventAvailableIcon fontSize="small" />
                <Typography variant="body2">
                    Check-out: {checkOutDate.format('DD/MM/YYYY')}
                </Typography>
            </Stack>
        </Box>
    );
};
