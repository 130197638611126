import {AxiosResponse} from "axios";
import {cleanFilters, formatSortingFields} from "../../../../core/helper";
import {ApiService} from "../../../../core/services";
import {IPaginatedResult, ISort} from "../../../../core/types";
import {
    IDetailManualHotel,
    IFilterManualHotel,
    IListManualHotel,
    IListManualHotelByDestination,
    IUpsertManualHotel
} from "./types";


const endpoints = {
    list: 'api/manual-hotels',
    listByDestinations: 'api/manual-hotels/list-by-destinations',
    get: 'api/manual-hotels/:id',
    create: 'api/manual-hotels',
    update: 'api/manual-hotels/:id',
    delete: 'api/manual-hotels/:id',
}

export const getManualHotels = (page = 1, pageSize = 10, sortingFields: ISort[], search: string, filters?: IFilterManualHotel): Promise<AxiosResponse<IPaginatedResult<IListManualHotel>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(filters && cleanFilters(filters)), ...(search && search !== '' && {search: search})
        }
    });
};
export const getManualHotelsByDestinations = (destinationCodes: string[]): Promise<AxiosResponse<Record<string, IListManualHotelByDestination[]>>> => ApiService.post(endpoints.listByDestinations, {destinationCodes: destinationCodes});
export const getManualHotel = (id: number): Promise<AxiosResponse<IDetailManualHotel>> => ApiService.get(endpoints.get.replace(':id', id + ''));
export const createManualHotel = (data: IUpsertManualHotel): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create, data);
export const updateManualHotel = (id: number, data: IUpsertManualHotel): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':id', id + ''), data);
export const deleteManualHotel = (id: number): Promise<AxiosResponse<void>> => ApiService.delete(endpoints.delete.replace(':id', id + ''));
