import React from "react";
import {Box, Typography, useTheme} from "@mui/material";
import "react-quill/dist/quill.snow.css";
interface GeneralConditionsPreviewProps {
    htmlContent: string|null;
}

const GeneralConditionsPreview: React.FC<GeneralConditionsPreviewProps> = ({htmlContent}) => {
    const theme = useTheme();
    return (

        <Box
            sx={{
                border: 0,
                pl: "20px",
                mt: 1
            }}
        >
            <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                 sx={{backgroundColor: theme.palette.primary.main, borderRadius: 2}} mb={1} mr={2}>
                <Typography variant="h6" sx={{color: 'white'}}>
                    Condiciones Generales
                </Typography>
            </Box>
            <Box mr={2} ml={2}>
                {htmlContent && <Box sx={{
                    typography: "body2"
                }}
                    dangerouslySetInnerHTML={{__html: htmlContent}}
                />}
                {!htmlContent &&<Typography variant="h5">
                    Condiciones Generales no configuradas
                </Typography>}
            </Box>

        </Box>
    );
};

export default GeneralConditionsPreview;
