import React from 'react';
import {
    Box,
    Card,
    CardContent,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation';
import { ILegacyQuotationFlightTariffOptionPreview } from "../../types";
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';

interface LegacyQuotationFlightTariffOptionsPreviewProps {
    options: ILegacyQuotationFlightTariffOptionPreview[];
}

const LegacyQuotationFlightTariffOptionsPreview: React.FC<LegacyQuotationFlightTariffOptionsPreviewProps> = ({ options }) => {
    const theme = useTheme();
    return (
        <Box sx={{ border: 0, pl: "20px", mt: 1 }}>
            <Box
                display="flex"
                alignItems="center"
                gap={1}
                flex={1}
                p={1}
                sx={{ backgroundColor: theme.palette.primary.main, borderRadius: 2 }}
                mb={1}
                mr={2}
            >
                <Typography variant="h6" sx={{ color: 'white' }}>
                    Opciones de tarifas
                </Typography>
            </Box>
            <Box mr={2}>
                <Stack spacing={2}>
                    {options.map((option, optionIndex) => {
                        return (
                            <Card key={option.optionId} variant="outlined" sx={{ borderRadius: 3 }}>
                                <CardContent>
                                    <Typography
                                        variant="h6"
                                        color="text.secondary"
                                        fontWeight="bold"
                                        gutterBottom
                                    >
                                        Opción #{optionIndex + 1}
                                    </Typography>
                                    {option.items.map((item, itemIndex) => (
                                        <Box key={item.itemId} ml={1} mt={1}>
                                            <Box display="flex" alignItems="center" gap={2}>
                                                {/* Etiqueta en negrita y con ancho fijo */}
                                                <Box sx={{ minWidth: 100, display: 'flex', alignItems: 'center' }}>
                                                    {item.optionItemType === 'GOING' ? (
                                                        <FlightTakeoffIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                                                    ) : item.optionItemType === 'RETURN' ? (
                                                        <FlightLandIcon
                                                            sx={{
                                                                mr: 1,
                                                                color: theme.palette.primary.main,
                                                                transform: 'scaleX(-1)',
                                                                transformOrigin: 'center',
                                                            }}
                                                        />
                                                    ) : null}
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                        {item.optionItemType === 'GOING'
                                                            ? 'Ida'
                                                            : item.optionItemType === 'RETURN'
                                                                ? 'Vuelta'
                                                                : 'Ida'}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                                                    {item.whatIncludeTariffName}
                                                </Typography>
                                            </Box>

                                        </Box>
                                    ))}
                                    {/* Si es vuelo RT, mostramos el subtotal por concepto */}
                                    <Box ml={1} mt={0.5}>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <PersonIcon fontSize="small" sx={{ color: theme.palette.primary.main }} />
                                            <Typography variant="body2">
                                                Precio por Adulto: <strong>USD {option.adultPrice}</strong>
                                            </Typography>
                                        </Box>
                                        {option.minorPrice != null && (
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <ChildCareIcon fontSize="small" sx={{ color: theme.palette.primary.main }} />
                                                <Typography variant="body2">
                                                    Precio por Niño: <strong>USD {option.minorPrice}</strong>
                                                </Typography>
                                            </Box>
                                        )}
                                        {option.infantPrice != null && (
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <BabyChangingStationIcon fontSize="small" sx={{ color: theme.palette.primary.main }} />
                                                <Typography variant="body2">
                                                    Precio por Infante: <strong>USD {option.infantPrice}</strong>
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        );
                    })}
                </Stack>
            </Box>
        </Box>
    );
};

export default LegacyQuotationFlightTariffOptionsPreview;
