import React, {useEffect} from 'react';
import {Autocomplete, Box, Button, Divider, IconButton, InputAdornment, TextField, Typography,} from '@mui/material';
import {AddCircle, Refresh} from '@mui/icons-material';
import {Controller, useFieldArray, useFormContext, useWatch,} from 'react-hook-form';
import {v4 as uuidv4} from 'uuid';

import {
    ILegacyQuotationPackageOptionHotelItemUpsert,
    ILegacyQuotationPackageOptionPriceUpsert,
    ILegacyQuotationPackageOptionUpsert
} from '../../types';
import {IListNomenclator} from '../../../commons/types';
import {IAutoCompleteDestinationList} from "../../../../../destinations/types";
import DeleteIcon from "@mui/icons-material/Delete";
import {IListManualHotelByDestination} from "../../../manual-hotels/types";

interface LegacyQuotationPackageOptionsFormProps {
    name?: string;
    regimens: IListNomenclator[];
    roomTypes: IListNomenclator[];
    hotelsByDestination: Record<string, IListManualHotelByDestination[]>;
    roomTypeDistributions: { roomTypeId: string; hasMinors: boolean }[];
    destinations: IAutoCompleteDestinationList[];
    refreshHotelListByDestinationCode: (destinationCode: string) => Promise<void>
    readonly?: boolean
}

const LegacyQuotationPackageOptionsForm: React.FC<LegacyQuotationPackageOptionsFormProps> = ({
                                                                name = 'options',
                                                                regimens,
                                                                hotelsByDestination,
                                                                roomTypeDistributions,
                                                                roomTypes,
                                                                destinations,
                                                                refreshHotelListByDestinationCode,
                                                                readonly = false
                                                            }) => {
    const {control, setValue} = useFormContext();

    const {fields, append, remove} = useFieldArray({
        control,
        name,
    });

    const destinationCodes = destinations.map(x => x.code);

    const watchOptions = useWatch({
        control,
        name,
    }) as ILegacyQuotationPackageOptionUpsert[];

    const handleAddOption = () => {
        const newOption: ILegacyQuotationPackageOptionUpsert = {
            optionId: uuidv4(),
            orderPosition: fields.length + 1,
            hotelOptions: destinations.map((dest) => ({
                optionHotelItemId: uuidv4(),
                destinationCode: dest.code,
                manualHotelId: 0,
                regimenId: '',
            })),
            optionPricesByRoomTypeDistribution: roomTypeDistributions.map((rtd) => ({
                optionPriceId: uuidv4(),
                roomTypeId: rtd.roomTypeId,
                adultPrice: 0,
                minorPrice: rtd.hasMinors ? 0 : undefined,
            })),
        };

        append(newOption);
    };

    useEffect(() => {
        if (!watchOptions) return;

        const updated = watchOptions.map((option) => {
            const cleanedHotelOptions = option.hotelOptions.filter((h) =>
                destinationCodes.includes(h.destinationCode)
            );
            const toAddByNewDestination: ILegacyQuotationPackageOptionHotelItemUpsert[] = [];

            const destinationCodesInHotelOptions = option.hotelOptions.map(x => x.destinationCode);

            destinationCodes.forEach(destinationCode => {
                if (destinationCodesInHotelOptions.indexOf(destinationCode) === -1) {
                    toAddByNewDestination.push({
                        optionHotelItemId: uuidv4(),
                        destinationCode: destinationCode,
                        manualHotelId: 0,
                        regimenId: '',
                    });
                }

            });

            const cleanedPrices = option.optionPricesByRoomTypeDistribution.map((p) => {
                    const roomTypeDistribution = roomTypeDistributions.find(rtd => rtd.roomTypeId === p.roomTypeId);
                    if (roomTypeDistribution) {
                        const toReturn = {...p};
                        if (!roomTypeDistribution.hasMinors) {
                            delete toReturn.minorPrice;
                            return toReturn;
                        }
                        return toReturn;
                    }
                    return null;
                }
            ).filter(x => x != null);

            const toAddByNewsRoomType: ILegacyQuotationPackageOptionPriceUpsert[] = [];

            const roomTypesIdsInPrices = option.optionPricesByRoomTypeDistribution.map(x => x.roomTypeId);

            roomTypeDistributions.forEach(rtd => {
                if (roomTypesIdsInPrices.indexOf(rtd.roomTypeId) === -1) {
                    toAddByNewsRoomType.push({
                        optionPriceId: uuidv4(),
                        roomTypeId: rtd.roomTypeId,
                        adultPrice: 0,
                        minorPrice: rtd.hasMinors ? 0 : undefined,
                    });
                }

            });

            return {
                ...option,
                hotelOptions: cleanedHotelOptions.concat(toAddByNewDestination),
                optionPricesByRoomTypeDistribution: cleanedPrices.concat(toAddByNewsRoomType),
            };
        });

        setValue(name, updated);
    }, [destinations, roomTypeDistributions]);

    return (
        <Box display="flex" flexDirection="column" gap={3}>
            {fields.map((item, index) => (
                <Box
                    key={item.id}
                    sx={{border: '1px solid #ccc', p: 2, borderRadius: 2}}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">Opción #{index + 1}</Typography>
                        {!readonly && <IconButton onClick={() => remove(index)} color="error">
                            <DeleteIcon/>
                        </IconButton>}
                    </Box>

                    <Divider sx={{my: 2}}/>

                    {/* Hoteles por destino */}
                    <Typography variant="subtitle1">Hoteles por destino</Typography>
                    {destinations.map((dest, destIdx) => {
                        const hotelList = hotelsByDestination[dest.code] || [];

                        return (
                            <Box
                                key={dest.code}
                                display="flex"
                                gap={2}
                                alignItems="center"
                                my={1}
                                sx={{width: '100%'}}
                            >
                                {/* Autocomplete Hotel */}
                                <Controller
                                    name={`${name}.${index}.hotelOptions.${destIdx}.manualHotelId`}
                                    control={control}
                                    rules={{
                                        validate: value =>
                                            value !== 0 && value !== null && value !== undefined || 'El hotel es requerido'
                                    }}
                                    render={({field, fieldState}) => {
                                        const selectedHotel =
                                            hotelList.find((h) => h.id === field.value) || null;
                                        return (
                                            <Autocomplete
                                                options={hotelList}
                                                disabled={readonly}
                                                getOptionLabel={(option) => option.name}
                                                value={selectedHotel}
                                                onChange={(_, newValue) =>
                                                    field.onChange(newValue ? newValue.id : 0)
                                                }
                                                isOptionEqualToValue={(option, val) => option.id === val?.id}
                                                sx={{flex: 2, minWidth: 300, maxWidth: 600}}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={`Hotel en ${dest.name}`}
                                                        size="small"
                                                        error={!!fieldState.error}
                                                        helperText={fieldState.error?.message}
                                                        disabled={readonly}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {params.InputProps.endAdornment}
                                                                    <IconButton disabled={readonly}
                                                                                onClick={() => refreshHotelListByDestinationCode(dest.code)}
                                                                                title="Refrescar opciones" size="small">
                                                                        <Refresh/>
                                                                    </IconButton>
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        );
                                    }}
                                />

                                {/* Autocomplete Régimen */}
                                <Controller
                                    name={`${name}.${index}.hotelOptions.${destIdx}.regimenId`}
                                    control={control}
                                    rules={{required: 'El régimen es requerido'}}
                                    render={({field, fieldState}) => {
                                        const selectedRegimen =
                                            regimens.find((r) => r.id === field.value) || null;
                                        return (
                                            <Autocomplete
                                                options={regimens}
                                                disabled={readonly}
                                                getOptionLabel={(option) => option.name}
                                                value={selectedRegimen}
                                                onChange={(_, newValue) =>
                                                    field.onChange(newValue ? newValue.id : '')
                                                }
                                                isOptionEqualToValue={(option, val) => option.id === val?.id}
                                                sx={{flex: 1, minWidth: 150, maxWidth: 250}}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        disabled={readonly}
                                                        label="Régimen"
                                                        size="small"
                                                        error={!!fieldState.error}
                                                        helperText={fieldState.error?.message}
                                                    />
                                                )}
                                            />
                                        );
                                    }}
                                />
                            </Box>
                        );
                    })}

                    {/* Precios por tipo de habitación */}
                    <Typography variant="subtitle1" sx={{mt: 2}}>
                        Precios por tipo de habitación
                    </Typography>
                    {roomTypeDistributions.map((rtd, rtdIdx) => (
                        <Box
                            key={rtd.roomTypeId}
                            display="flex"
                            gap={2}
                            alignItems="center"
                            my={1}
                            sx={{width: '100%'}}
                        >
                            <Typography sx={{minWidth: 150}}>
                                Tipo Habitación: {roomTypes.find((x) => x.id === rtd.roomTypeId)?.name || ''}
                            </Typography>
                            <Controller
                                name={`${name}.${index}.optionPricesByRoomTypeDistribution.${rtdIdx}.adultPrice`}
                                control={control}
                                rules={{
                                    required: 'Precio requerido',
                                    validate: value =>
                                        value > 0 || 'El precio debe ser mayor a 0'
                                }}
                                render={({field, fieldState}) => (
                                    <TextField
                                        label="Precio por adulto"
                                        type="number"
                                        size="small"
                                        {...field}
                                        disabled={readonly}
                                        onChange={(e) => field.onChange(Number(e.target.value))}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                        sx={{flex: 1, minWidth: 150, maxWidth: 250}}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">USD</InputAdornment>,
                                        }}
                                    />
                                )}
                            />

                            {rtd.hasMinors && (
                                <Controller
                                    name={`${name}.${index}.optionPricesByRoomTypeDistribution.${rtdIdx}.minorPrice`}
                                    control={control}
                                    rules={{
                                        required: 'Precio por menor requerido',
                                        validate: value =>
                                            value >= 0 || 'El precio debe ser mayor o igual a 0'
                                    }}
                                    render={({field, fieldState}) => (
                                        <TextField
                                            label="Precio por menor"
                                            type="number"
                                            size="small"
                                            {...field}
                                            disabled={readonly}
                                            onChange={(e) => field.onChange(Number(e.target.value))}
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                            sx={{flex: 1, minWidth: 150, maxWidth: 250}}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">USD</InputAdornment>,
                                            }}
                                        />
                                    )}
                                />
                            )}
                        </Box>
                    ))}
                </Box>
            ))}

            {destinations.length > 0 && roomTypeDistributions.length > 0 && !readonly && (
                <Button
                    variant="outlined"
                    onClick={handleAddOption}
                    startIcon={<AddCircle/>}
                >
                    Agregar opción
                </Button>
            )}
        </Box>
    );
};

export default LegacyQuotationPackageOptionsForm;
