import {IAppFilterList} from "../../core/types";
import {IFilterCardPortfolio} from "./types";
import dayjs from 'dayjs';

export const getDefaultFilterByDepartureDateInRangeOneWeek = ()=>{
    const today = dayjs();                 // Fecha actual
    const oneWeekLater = today.add(7, 'day');   // Fecha actual + 7 días

// Formatear las fechas a YYYY-MM-DD
    const todayFormatted = today.format('YYYY-MM-DD');
    const oneWeekLaterFormatted = oneWeekLater.format('YYYY-MM-DD');
    const filter:IAppFilterList<IFilterCardPortfolio> = {
        "id": 0,
        "title": "VIAJA HOY + 7 DÍAS",
        "viewId": "cards-portfolio-list",
        "backgroundColor": "#0047BB",
        "textColor": "#ffffff",
        "filterValue": {
        "sorts": [
            {
                "field": "createdAt",
                "order": "desc"
            }
        ],
            "filter": {
            "id": "",
                "fee": "",
                "amount": "",
                "dealId": "",
                "status": "",
                "orderId": "",
                "amountTo": "",
                "cardStamp": "",
                "createdAt": "",
                "amountFrom": "",
                "cardHolder": "",
                "fileNumber": "",
                "sellerName": "",
                "createdAtTo": "",
                "createdAtFrom": "",
                "departureDate": "",
                "preauthorized": "",
                "showFinalized": "false",
                "departureDateTo": oneWeekLaterFormatted,
                "preauthorizedTo": "",
                "toUseInSameIssue": "",
                "departureDateFrom": todayFormatted,
                "lastFourCardDigit": "",
                "preauthorizedFrom": "",
                "hasActiveRejection": "",
                "issueRequirementId": "",
                "totalPreauthorized": "",
                "hasIssueRequirements": "",
                "totalPreauthorizedTo": "",
                "totalPreauthorizedFrom": "",
                "totalPendingAuthorization": "",
                "totalPendingAuthorizationTo": "",
                "preauthorizedWithAirlineCode": "",
                "totalPendingAuthorizationFrom": ""
        },
        "search": ""
    }
    }

    return filter;
}