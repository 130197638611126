import React from "react";
import { Box, Typography } from "@mui/material";
import { IFlightItinerarySegmentInformation } from "../../../legacy-quotation-packages/types";
import useBreakPoints from "../../../../../../core/useBreakPoints";
import { FlightItineraryMobile } from "../FlightItineraryMobile/FlightItineraryMobile";
import { FlightItineraryTable } from "../FlightItineraryTable/FlightItineraryTable";

interface FlightItineraryViewProps {
    segments: IFlightItinerarySegmentInformation[];
}

export const FlightItineraryView: React.FC<FlightItineraryViewProps> = ({ segments }) => {
    const { mediumDown: isMediumDown } = useBreakPoints();

    if (!segments || segments.length === 0) {
        return (
            <Box mt={2}>
                <Typography variant="body1" color="textSecondary">
                    No hay segmentos de itinerario para mostrar.
                </Typography>
            </Box>
        );
    }

    return (
        <>
            {isMediumDown ? (
                <FlightItineraryMobile segments={segments} />
            ) : (
                <FlightItineraryTable segments={segments} />
            )}
        </>
    );
};
