import React, {useEffect, useState} from 'react';
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {getGeneralConditions, upsertGeneralConditions} from "../../services";
import {ILegacyQuotationGeneralCondition, ILegacyQuotationGeneralConditionContent} from "../../types";
import {extractErrorMessage, is404Error} from "../../../../../../core/helper";
import LegacyQuotationGeneralConditionUpsertForm
    from "../../components/LegacyQuotationGeneralConditionUpsertForm/LegacyQuotationGeneralConditionUpsertForm";
import {ShowAlertState} from "../../../../../../core/types";


const LegacyQuotationGeneralConditionUpsertFormContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [item,setItem] = useState<ILegacyQuotationGeneralCondition|null>(null)

    const handleUpsertGeneralsConditions = async (value: ILegacyQuotationGeneralConditionContent) => {
        try {
            setLoading(true);
            await upsertGeneralConditions(value);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Condiciones Generales guardadas satisfactoriamente'
            });
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al  guardar las Condiciones Generales')
            });
            setLoading(false);
        }
    }

    const handleLoadGeneralsConditions = async () => {
        try {
            setLoading(true);
           const result = await getGeneralConditions();
            setLoading(false);
            setItem(result.data);
        } catch (error) {
            if(!is404Error(error)){
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al  cargar las Condiciones Generales')
                });
            }

            setLoading(false);
        }
    }

    useEffect(() => {
        handleLoadGeneralsConditions();
    }, []);


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            <LegacyQuotationGeneralConditionUpsertForm handleSubmitForm={handleUpsertGeneralsConditions}
                                                       loading={loading} data={item}/>
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default LegacyQuotationGeneralConditionUpsertFormContainer;
