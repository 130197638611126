// TourismIconSelect.tsx
import React from "react";
import { Controller, Control } from "react-hook-form";
import { Autocomplete, TextField, Box, Typography } from "@mui/material";
import { tourismIcons, TourismIconItem } from "./IconsHelper";

interface IconSelectProps {
    control: Control<any>;
    name: string; // p.ej "tourismIcon"
}

export const IconSelect: React.FC<IconSelectProps> = ({
                                                                        control,
                                                                        name,
                                                                    }) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Autocomplete
                    options={tourismIcons}
                    fullWidth
                    getOptionLabel={(option: TourismIconItem) => option.name}
                    value={tourismIcons.find((icon) => icon.name === field.value) || null}
                    onChange={(_, newValue) => {
                        // Guardar solo el nombre en RHF
                        field.onChange(newValue?.name || "");
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Selecciona un ícono"
                            size={'small'}
                            variant="outlined"
                        />
                    )}
                    // Mostrar ícono + nombre
                    renderOption={(props, option) => (
                        <Box component="li" {...props} display="flex" alignItems="center">
                            <option.Icon style={{ marginRight: 8 }} />
                            <Typography variant="body1">{option.name}</Typography>
                        </Box>
                    )}
                />
            )}
        />
    );
};
