import React, {useCallback, useEffect, useState} from 'react';
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {deleteWhatInclude, getWhatIncludes} from "../../services";
import {ISort, ShowAlertState} from "../../../../../../core/types";
import {IListLegacyQuotationWhatInclude} from "../../types";
import {extractErrorMessage} from "../../../../../../core/helper";
import LegacyQuotationWhatIncludesList
    from "../../components/LegacyQuotationWhatIncludesList/LegacyQuotationWhatIncludesList";
import {appRoutes} from "../../../../../../core/const";

const LegacyQuotationWhatIncludeListContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(search);
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [order, setOrder] = useState<ISort[]>([]);
    const [items, setItems] = useState<IListLegacyQuotationWhatInclude[]>([])
    const navigate = useNavigate();

    const onAdd = () => navigate(`${appRoutes.legacyQuotations.whatInclude.list}/create`);

    const onEdit = (item: IListLegacyQuotationWhatInclude) => navigate(`${appRoutes.legacyQuotations.whatInclude.list}/${item.id}`);

    const onDelete = async (item: IListLegacyQuotationWhatInclude) => {
        try {
            setLoading(true);
            await deleteWhatInclude(item.id);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Elemento eliminado satisfactoriamente'
            });
            setTimeout(loadWhatIncludes, 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al eliminar el que incluye')
            });
            setLoading(false);
        }
    };


    const loadWhatIncludes = useCallback(async () => {
        try {
            setLoading(true);
            const paginatedResult = (await getWhatIncludes(page + 1, pageSize, order, debouncedSearchTerm)).data;
            setTotalElements(paginatedResult.total);
            setItems(paginatedResult.items);
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de que incluye')
            });
            setLoading(false);
        }
    }, [page, pageSize, order, debouncedSearchTerm]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 500); // Retraso de 500 ms

        // Limpia el timeout si el efecto se vuelve a ejecutar antes de que el timeout finalice
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        loadWhatIncludes();
    }, [loadWhatIncludes]);

    return (<><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            <LegacyQuotationWhatIncludesList title={'Qué Incluye'}
                                             items={items}
                                             onAdd={onAdd}
                                             onEdit={onEdit}
                                             order={order}
                                             setOrder={setOrder}
                                             search={search}
                                             setSearch={setSearch}
                                             page={page}
                                             setPage={setPage}
                                             pageSize={pageSize}
                                             setPageSize={setPageSize}
                                             totalElements={totalElements}
                                             onDelete={onDelete}
            />
            <Snackbar open={alert.show} autoHideDuration={2500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
}

export default LegacyQuotationWhatIncludeListContainer;
