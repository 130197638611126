import React, {useCallback, useEffect, useState} from 'react';
import {IFlightItinerarySegmentInformation, ILegacyQuotationPackageUpsert} from "../../types";
import {useNavigate, useParams} from "react-router-dom";
import {ShowAlertState} from "../../../../../../core/types";
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {extractErrorMessage, is404Error} from "../../../../../../core/helper";
import {appRoutes} from "../../../../../../core/const";

import Box from "@mui/material/Box";
import {
    LegacyQuotationPackageUpsertForm
} from "../../components/LegacyQuotationPackageUpsertForm/LegacyQuotationPackageUpsertForm";
import {IAutocompleteResponseIListLegacyQuotationWhatInclude} from "../../../legacy-quotation-what-includes/types";
import {IListAllLegacyQuotationAdditional} from "../../../legacy-quotations-additionals/types";
import {
    IAutocompleteResponseIListLegacyQuotationExclusiveService
} from "../../../legacy-quotation-exclusive-services/types";
import {IAutoCompleteDestinationList} from "../../../../../destinations/types";
import {getAllWhatIncludesGrouped} from "../../../legacy-quotation-what-includes/services";
import {getAllExclusiveServicesGrouped} from "../../../legacy-quotation-exclusive-services/services";
import {getAllAdditionalsByDestinations} from "../../../legacy-quotations-additionals/services";
import {getAllDestinations} from "../../../../../destinations/services";
import {getDetailLegacyQuotationPackage, parsePNRToFlightItinerary, upsertLegacyQuotationPackage} from "../../services";
import {IListNomenclator, noIncludesResultEmpty, whatIncludeResultEmpty} from "../../../commons/types";
import {getAllHotelRegimens, getAllRoomTypes} from "../../../commons/services";
import {getManualHotelsByDestinations} from "../../../manual-hotels/services";
import {IListManualHotelByDestination} from "../../../manual-hotels/types";
import {IAutocompleteResponseIListLegacyQuotationNoInclude} from "../../../legacy-quotation-no-includes/types";
import {getAllNoIncludesGrouped} from "../../../legacy-quotation-no-includes/services";


const exclusiveServicesResultEmpty: IAutocompleteResponseIListLegacyQuotationExclusiveService = {
    system: [],
    others: []
}

const LegacyQuotationPackageUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [whatIncludeResult, setWhatIncludeResult] = useState<IAutocompleteResponseIListLegacyQuotationWhatInclude>(whatIncludeResultEmpty);
    const [noIncludeResult, setNoIncludeResult] = useState<IAutocompleteResponseIListLegacyQuotationNoInclude>(noIncludesResultEmpty);
    const [exclusiveServicesResult, setExclusiveServicesResult] = useState<IAutocompleteResponseIListLegacyQuotationExclusiveService>(exclusiveServicesResultEmpty);
    const [destinations, setDestinations] = useState<IAutoCompleteDestinationList[]>([]);
    const [roomTypes, setRoomTypes] = useState<IListNomenclator[]>([]);
    const [regimens, setRegimens] = useState<IListNomenclator[]>([]);
    const [quotationPackageDetail, setQuotationPackageDetail] = useState<ILegacyQuotationPackageUpsert | null>(null);
    const [manualHotelsByDestinationsCode, setManualHotelsByDestinationCode] = useState<Record<string, IListManualHotelByDestination[]>>({})
    const [additionalsByDestinationsCode, setAdditionalsByDestinationCode] = useState<Record<string, IListAllLegacyQuotationAdditional[]>>({})


    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();

    const loadQuotationPackage = useCallback(async () => {
        try {
            if (id && !id.startsWith('create')) {
                setLoading(true);
                const result = (await getDetailLegacyQuotationPackage(id)).data;
                const destinationCodes = result.quotationDestinations.map(x => x.destinationCode);
                const resultHotels = await getManualHotelsByDestinations(destinationCodes);
                setManualHotelsByDestinationCode(resultHotels.data);
                setQuotationPackageDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el detalle de la cotización')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate(`${appRoutes.legacyQuotations.quotationPackage.list}`), 2500);
            }
        }
    }, [id, setLoading, setAlert]);

    const refreshHotelsByDestinationCodes = async (destinationCodes: string[]) => {
        try {
            setLoading(true);
            const resultHotels = await getManualHotelsByDestinations(destinationCodes);
            setManualHotelsByDestinationCode(resultHotels.data);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar los hoteleles por destino')
            });

        } finally {
            setLoading(false);
        }
    }

    const refreshAdditionalsByDestinations = async (destinationCodes: string[]) => {
        try {
            setLoading(true);
            const result = await getAllAdditionalsByDestinations(destinationCodes);
            setAdditionalsByDestinationCode(result.data);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar los adicionales por destino')
            });

        } finally {
            setLoading(false);
        }
    }

    const refreshHotelsByDestinationCode = async (destinationCode: string) => {
        if (destinationCode) {

            try {
                setLoading(true);
                const resultHotelsByDestinationCode = await getManualHotelsByDestinations([destinationCode]);
                const toSet = {...manualHotelsByDestinationsCode};
                toSet[destinationCode] = resultHotelsByDestinationCode.data[destinationCode];
                setManualHotelsByDestinationCode(toSet);
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al cargar los hoteleles para el destino con  código ' + destinationCode)
                });

            } finally {
                setLoading(false);
            }
        }


    }


    const refreshAdditionalsByDestinationCode = async (destinationCode: string) => {
        if (destinationCode) {

            try {
                setLoading(true);
                const result = await getAllAdditionalsByDestinations([destinationCode]);
                const toSet = {...additionalsByDestinationsCode};
                toSet[destinationCode] = result.data[destinationCode];
                setAdditionalsByDestinationCode(toSet);
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al cargar los adicionales para el destino con  código ' + destinationCode)
                });

            } finally {
                setLoading(false);
            }
        }


    }

    const loadWhatIncludes = async () => {
        try {
            setLoading(true);
            const result = await getAllWhatIncludesGrouped();
            setWhatIncludeResult(result.data);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el listado de que incluye')
            });
        } finally {
            setLoading(false);
        }
    };

    const loadNoIncludes = async () => {
        try {
            setLoading(true);
            const result = await getAllNoIncludesGrouped();
            setNoIncludeResult(result.data);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el listado de no incluye')
            });
        } finally {
            setLoading(false);
        }
    };

    const loadExclusiveServices = async () => {
        try {
            setLoading(true);
            const result = await getAllExclusiveServicesGrouped();
            setExclusiveServicesResult(result.data);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el listado de servicios exclusivos')
            });
        } finally {
            setLoading(false);
        }
    };

    const loadDestinations = async () => {
        try {
            setLoading(true);
            const result = await getAllDestinations();
            setDestinations(result.data);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar los destinos')
            });
        } finally {
            setLoading(false);
        }
    };

    const loadRoomTypes = async () => {
        try {
            setLoading(true);
            const result = await getAllRoomTypes();
            setRoomTypes(result.data);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el listado de  tipos de habitaciones')
            });
        } finally {
            setLoading(false);
        }
    };

    const loadRegimens = async () => {
        try {
            setLoading(true);
            const result = await getAllHotelRegimens();
            setRegimens(result.data);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el listado de  regimenes')
            });
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        loadQuotationPackage();
    }, [loadQuotationPackage]);

    useEffect(() => {
        loadWhatIncludes();
    }, []);

    useEffect(() => {
        loadNoIncludes();
    }, []);

    useEffect(() => {
        loadExclusiveServices();
    }, []);


    useEffect(() => {
        loadDestinations();
    }, []);

    useEffect(() => {
        loadRoomTypes();
    }, []);

    useEffect(() => {
        loadRegimens();
    }, []);
    useEffect(() => {
        loadQuotationPackage();
    }, [id]);


    const handleUpsertQuotationPackage = async (x: ILegacyQuotationPackageUpsert) => {
        try {
            setLoading(true);
            await upsertLegacyQuotationPackage(x.quotationId, x);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Cotización guardada satisfactoriamente'
            });
            setTimeout(() => window.location.href = appRoutes.legacyQuotations.quotationPackage.upsert.replace(':id', x.quotationId), 1500);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al guardar la cotización')
            });
        } finally {
            setLoading(false);
        }
    }
    const fetchItinerarySegmentsByPNR = async (pnr: string): Promise<IFlightItinerarySegmentInformation[]> => {
        try {
            setLoading(true);
            return ((await parsePNRToFlightItinerary(pnr)).data)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al extraer los datos del PNR')
            });
            return [];
        } finally {
            setLoading(false);
        }
    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {id && id.startsWith('create') && <LegacyQuotationPackageUpsertForm
                fetchItinerarySegmentsByPNR={fetchItinerarySegmentsByPNR}
                destinations={destinations}
                roomTypes={roomTypes}
                regimens={regimens}
                exclusiveServicesContainer={exclusiveServicesResult}
                whatIncludesContainer={whatIncludeResult}
                noIncludesContainer={noIncludeResult}
                loading={loading} id={id}
                manualHotelsByDestinations={manualHotelsByDestinationsCode}
                refreshWhatIncludes={loadWhatIncludes}
                refreshNoIncludes={loadNoIncludes}
                refreshExclusiveServices={loadExclusiveServices}
                refreshHotelListByDestinationCode={refreshHotelsByDestinationCode}
                refreshManualHotels={refreshHotelsByDestinationCodes}
                refreshDestinations={loadDestinations}
                additionalsByDestination={additionalsByDestinationsCode}
                refreshAdditionalsByDestinations={refreshAdditionalsByDestinations}
                refreshAdditionalsListByDestinationCode={refreshAdditionalsByDestinationCode}
                handleSubmitForm={handleUpsertQuotationPackage}/>}

            {id && !id.startsWith('create') && quotationPackageDetail && <LegacyQuotationPackageUpsertForm
                fetchItinerarySegmentsByPNR={fetchItinerarySegmentsByPNR}
                destinations={destinations}
                roomTypes={roomTypes}
                regimens={regimens}
                exclusiveServicesContainer={exclusiveServicesResult}
                whatIncludesContainer={whatIncludeResult}
                noIncludesContainer={noIncludeResult}
                loading={loading}
                manualHotelsByDestinations={manualHotelsByDestinationsCode}
                refreshManualHotels={refreshHotelsByDestinationCodes}
                refreshWhatIncludes={loadWhatIncludes}
                refreshNoIncludes={loadNoIncludes}
                refreshExclusiveServices={loadExclusiveServices}
                refreshHotelListByDestinationCode={refreshHotelsByDestinationCode}
                refreshDestinations={loadDestinations}
                additionalsByDestination={additionalsByDestinationsCode}
                refreshAdditionalsByDestinations={refreshAdditionalsByDestinations}
                refreshAdditionalsListByDestinationCode={refreshAdditionalsByDestinationCode}
                id={id}
                value={quotationPackageDetail}
                linkToClient={quotationPackageDetail.linkToClient||''}
                handleSubmitForm={handleUpsertQuotationPackage}/>}

            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default LegacyQuotationPackageUpsertContainer;
