import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import {Controller, FormProvider, useForm, useWatch} from 'react-hook-form';
import {useNavigate} from "react-router-dom";
import {appRoutes} from "../../../../../../core/const";
import {
    IFlightItinerarySegmentInformation, ILegacyQuotationPackageAdditionalOptionUpsert,
    ILegacyQuotationPackageFormValue,
    ILegacyQuotationPackageOptionUpsert,
    ILegacyQuotationPackageRoomTypeDistributionUpsert,
    ILegacyQuotationPackageUpsert
} from "../../types";
import {IAutoCompleteDestinationList} from "../../../../../destinations/types";
import MultiDestinationForm, {IDestinationFormItem} from "../MultiDestinationForm/MultiDestinationForm";
import {PackageImageSelector, SelectedImage} from "../PackageImageSelector/PackageImageSelector";
import {
    IAutocompleteResponseIListLegacyQuotationWhatInclude,
    IListAllLegacyQuotationWhatInclude
} from "../../../legacy-quotation-what-includes/types";
import {WhatIncludeSelector} from "../../../commons/components/WhatIncludeSelector/WhatIncludeSelector";
import {IListAllLegacyQuotationAdditional} from "../../../legacy-quotations-additionals/types";
import {
    IAutocompleteResponseIListLegacyQuotationExclusiveService,
    IListLegacyQuotationExclusiveService
} from "../../../legacy-quotation-exclusive-services/types";
import {ExclusiveServiceSelector} from "../ExclusiveServiceSelector/ExclusiveServiceSelector";
import RoomTypeDistributionForm from "../RoomTypeDistributionForm/RoomTypeDistributionForm";
import {IListNomenclator} from "../../../commons/types";
import LegacyQuotationPackageOptionsForm from "../LegacyQuotationPackageOptionsForm/LegacyQuotationPackageOptionsForm";
import {
    transformDataToQuotationAdditionalOptionPreview,
    transformDataToQuotationOptionPreview,
    transformWhatIncludeToPreview
} from "../../helper";
import {IListManualHotelByDestination} from "../../../manual-hotels/types";
import {
    IAutocompleteResponseIListLegacyQuotationNoInclude,
    IListAllLegacyQuotationNoInclude
} from "../../../legacy-quotation-no-includes/types";
import {NoIncludeSelector} from "../../../commons/components/NoIncludeSelector/NoIncludeSelector";
import {FlightItineraryView} from "../../../commons/components/FlightItineraryView/FlightItineraryView";
import LegacyQuotationPackageAdditionalOptionsForm
    from "../LegacyQuotationPackageAdditionalOptionsForm/LegacyQuotationPackageAdditionalOptionsForm";

export interface LegacyQuotationPackageReadOnlyFormProps {
    whatIncludesContainer: IAutocompleteResponseIListLegacyQuotationWhatInclude,
    noIncludesContainer: IAutocompleteResponseIListLegacyQuotationNoInclude,
    exclusiveServicesContainer: IAutocompleteResponseIListLegacyQuotationExclusiveService,
    destinations: IAutoCompleteDestinationList[],
    roomTypes: IListNomenclator[],
    regimens: IListNomenclator[],
    manualHotelsByDestinations: Record<string, IListManualHotelByDestination[]>,
    refreshManualHotels: (destinationCodes: string[]) => Promise<void>,
    refreshWhatIncludes: () => Promise<void>,
    refreshNoIncludes: () => Promise<void>,
    refreshExclusiveServices: () => Promise<void>,
    refreshDestinations: () => Promise<void>,
    additionalsByDestination: Record<string, IListAllLegacyQuotationAdditional[]>,
    refreshAdditionalsByDestinations: (destinationCodes: string[]) => Promise<void>,
    loading: boolean;
    id: string;
    value: ILegacyQuotationPackageUpsert,
}

export const LegacyQuotationPackageReadOnlyForm: React.FC<LegacyQuotationPackageReadOnlyFormProps> = ({
                                                                                                          whatIncludesContainer,
                                                                                                          noIncludesContainer,
                                                                                                          exclusiveServicesContainer,
                                                                                                          destinations,
                                                                                                          roomTypes,
                                                                                                          regimens,
                                                                                                          manualHotelsByDestinations,
                                                                                                          refreshManualHotels,
                                                                                                          refreshWhatIncludes,
                                                                                                          refreshNoIncludes,
                                                                                                          refreshExclusiveServices,
                                                                                                          refreshDestinations,
                                                                                                          additionalsByDestination,
                                                                                                          refreshAdditionalsByDestinations,
                                                                                                          loading,
                                                                                                          id,
                                                                                                          value
                                                                                                      }) => {

    const destinationsToForm = value.quotationDestinations.map(x => {
        return {
            quotationDestinationId: x.quotationDestinationId,
            destination: destinations.find(d => d.code === x.destinationCode) || null,
            checkIn: x.checkIn,
            checkOut: x.checkOut,
            nights: x.nights
        } as IDestinationFormItem;
    })

    const methods = useForm<ILegacyQuotationPackageFormValue>({
        mode: 'all',
        defaultValues: {
            title: value.title,
            reference: value.reference || '',
            pnr: value?.flight?.pnr || '',
            withFlightServiceConfig: !value ? true : value?.flight != null,
            destinations: destinationsToForm,
            observations: value?.observations || '',
            roomTypeDistributions: value.roomTypeDistributions,
            options: value.options,

        }
    });

    const {
        setValue,
    } = methods;
    const theme = useTheme();

    // Suppose we have a previously selected image from backend
    // e.g., user saved this package with an image from Paris
    const [persistedImage] = useState<SelectedImage | null>(value.imageSelected != null ? {
        destinationCode: value.imageSelected?.destinationCode,
        imageUrl: value!.imageSelected!.imageUrl
    } : null);

    const channel = new BroadcastChannel("quotation_preview_channel_" + id);


    // We'll store the currently chosen image in local state
    const [chosenImage, setChosenImage] = useState<SelectedImage | null>(value?.imageSelected || null);


    const preselectedWhatIncludesIds = useMemo(() => {
        if (!value) {
            return [];
        } else return value!.whatIncludes
    }, [whatIncludesContainer, value]);


    const preselectedNoIncludesIds = useMemo(() => {
        if (!value) {
            return [];
        } else return value!.noIncludes
    }, [noIncludesContainer, value]);


    const preselectedExclusiveServiceIds = useMemo(() => {
        if (!value) {
            return exclusiveServicesContainer.system.map(x => x.id);
        } else return value!.exclusiveServices
    }, [exclusiveServicesContainer, value]);

    // Estado donde guardamos la lista final para mandar al backend
    const [selectedItemsWhatIncludesParent, setSelectedItemsWhatIncludesParent] = useState<IListAllLegacyQuotationWhatInclude[]>([]);
    const [selectedItemsNoIncludesParent, setSelectedItemsNoIncludesParent] = useState<IListAllLegacyQuotationNoInclude[]>([]);
    const [selectedItemsExclusiveServiceParent, setSelectedItemsExclusiveServiceParent] = useState<IListLegacyQuotationExclusiveService[]>([]);

    const [segments, setSegments] = useState<IFlightItinerarySegmentInformation[]>(value?.flight?.segments || []);


    const handleChangeNoIncludes = useCallback((items: IListAllLegacyQuotationNoInclude[]) => {
        setSelectedItemsNoIncludesParent(items);
        channel.postMessage({noIncludes: items});
    }, []);


    const navigate = useNavigate();
    const {
        control,
        formState: {errors},
        watch
    } = methods;

    // Watch the "destinations" array from the form in real time
    // Each item is { destination: IDestinationList | null, checkIn, checkOut, nights }
    const watchedDestinations = useWatch({
        control,
        name: 'destinations', // The field array name used in MultiDestinationForm
    }) as IDestinationFormItem[];

    // Transform the watched destinations into something for the PackageImageSelector.
    // We'll assume each 'destination' has an optional 'imageUrl' if you want to store that.
    // If there's no imageUrl, the PackageImageSelector can show a placeholder.
    const packageSelectorDestinations = React.useMemo(() => {
        return watchedDestinations
            .filter(item => item.destination !== null)
            .map(item => ({
                code: item.destination!.code,
                name: item.destination!.name,
                countryName: item.destination!.countryName,
                // If you have an imageUrl in IDestinationList:
                imageUrl: destinations.find(x => x.code == item.destination!.code)?.imageUrl,
            }));
    }, [watchedDestinations, destinations]);

    const destinationsSelected: IAutoCompleteDestinationList[] = React.useMemo(() => {
        return watchedDestinations
            .filter(item => item.destination !== null)
            .map(item => item.destination as IAutoCompleteDestinationList);
    }, [watchedDestinations]);

    const watchedDistributions = useWatch({
        control,
        name: 'roomTypeDistributions', // The field array name used in MultiDestinationForm
    }) as ILegacyQuotationPackageRoomTypeDistributionUpsert[];

    const roomTypesIdsWithHasMinorSelected = React.useMemo(() => {
        return watchedDistributions
            .filter(item => item.roomTypeId !== '')
            .map(item => ({roomTypeId: item.roomTypeId, hasMinors: item.hasMinors}));
    }, [watchedDistributions]);

    const watchedOptions = useWatch({
        control,
        name: 'options', // The field array name used in MultiDestinationForm
    }) as ILegacyQuotationPackageOptionUpsert[];

    // Evitamos recrear la función en cada render:
    const handleChangeExclusiveService = useCallback((items: IListLegacyQuotationExclusiveService[]) => {
        // Solo log si cambia
        setSelectedItemsExclusiveServiceParent(items);
        channel.postMessage({exclusiveServices: items});
    }, []);

    // Evitamos recrear la función en cada render:
    const handleChangeWhatIncludes = useCallback((items: IListAllLegacyQuotationWhatInclude[]) => {
        setSelectedItemsWhatIncludesParent(items);
        channel.postMessage({whatIncludes: transformWhatIncludeToPreview(items, watchedDestinations)});
    }, [watchedDestinations]);

    const pnrValue = watch('pnr');

    const withFlightServiceConfigValue = useWatch({control, name: "withFlightServiceConfig"});

    const titleValue = useWatch({control, name: "title"});
    const observationsValue = useWatch({control, name: "observations"});

    useEffect(() => {
        channel.postMessage({quotationTitle: titleValue});
    }, [titleValue]);

    useEffect(() => {
        channel.postMessage({observations: observationsValue});
    }, [observationsValue]);


    useEffect(() => {
        channel.postMessage({options: transformDataToQuotationOptionPreview(watchedDestinations, regimens, roomTypes, manualHotelsByDestinations, watchedOptions)});
    }, [watchedOptions]);

    useEffect(() => {
        if (value && destinations.length > 0 && !id.startsWith('create')) {
            const destinationToSet = value.quotationDestinations.map(x => {
                return {
                    quotationDestinationId: x.quotationDestinationId,
                    destination: destinations.find(d => d.code === x.destinationCode) || null,
                    checkIn: x.checkIn,
                    checkOut: x.checkOut,
                    nights: x.nights
                } as IDestinationFormItem;
            });
            setValue('destinations', destinationToSet);
            setSegments(value?.flight?.segments || []);
            const newOptions = value.options.map(x => {
                return {
                    ...x,
                    optionPricesByRoomTypeDistribution: x.optionPricesByRoomTypeDistribution.map(distribution => {
                        return {
                            ...distribution,
                            adultPrice: Number(distribution.adultPrice),
                            minorPrice: distribution.minorPrice != null ? Number(distribution.minorPrice) : undefined
                        }
                    })
                }
            });

            const newAdditionalOptions = value.additionalOptions.map(x => {
                return {
                    ...x,
                    additionalOptionItems: x.additionalOptionItems.map(additionalOptionItem => {
                        return {
                            ...additionalOptionItem,
                            adultPrice: additionalOptionItem.adultPrice !=null? Number(additionalOptionItem.adultPrice)+'' : '',
                            minorPrice: additionalOptionItem.minorPrice != null ? Number(additionalOptionItem.minorPrice)+'' : ''
                        }
                    })
                }
            });
            setValue('options', newOptions);
            setValue('additionalOptions', newAdditionalOptions);
        }
    }, [destinations, value]);

    useEffect(() => {
        refreshManualHotels(destinationsSelected.map(x => x.code));
    }, [destinationsSelected]);

    useEffect(() => {
        if (!withFlightServiceConfigValue) {
            setSegments([]);
            setValue('pnr', '');
        }
    }, [withFlightServiceConfigValue]);

    useEffect(() => {
        refreshAdditionalsByDestinations(destinationsSelected.map(x => x.code));
    }, [destinationsSelected]);

    const watchedAdditionalOptions = useWatch({
        control,
        name: 'additionalOptions', // The field array name used in MultiDestinationForm
    }) as ILegacyQuotationPackageAdditionalOptionUpsert[];

    const handleQuotationPreview = () => {
        const baseUrl = window.location.origin;
        const newRoute = appRoutes.legacyQuotations.quotationPackage.preview.replace(':id', id || '');
        window.open(`${baseUrl}${newRoute}`, "_blank");
        setTimeout(() => {
            channel.postMessage({
                quotationNumber: value?.quotationNumber || null,
                quotationTitle: titleValue,
                flightItinerarySegments: segments,
                additionalOptions: transformDataToQuotationAdditionalOptionPreview(watchedDestinations, additionalsByDestination, watchedAdditionalOptions),
                noIncludes: selectedItemsNoIncludesParent,
                exclusiveServices: selectedItemsExclusiveServiceParent,
                whatIncludes: transformWhatIncludeToPreview(selectedItemsWhatIncludesParent, watchedDestinations),
                quotationImage: chosenImage?.imageUrl || '',
                observations: observationsValue,
                options: transformDataToQuotationOptionPreview(watchedDestinations, regimens, roomTypes, manualHotelsByDestinations, watchedOptions)
            });
        }, 1000)
    }

    const hasMinors = roomTypesIdsWithHasMinorSelected.some(x => x.hasMinors);


    return (
        <FormProvider {...methods}>
            <Card sx={{maxWidth: '90%', width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}> {`Vista solo lectura de cotización de paquete #${value.quotationNumber}`}</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>

                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Configuración general
                            </Typography>
                        </Box>

                        <Box display="flex" alignItems="center" gap={3}>
                            <Controller
                                name="title"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'},
                                    maxLength: {
                                        value: 500,
                                        message: 'El campo excede el largo máximo permitido de: 500 caracteres'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        sx={{flex: 6}}
                                        required
                                        fullWidth
                                        label="Título del paquete"
                                        size={'small'}
                                        type="text"
                                        helperText={errors.title ? errors.title.message : ''}
                                        error={!!errors.title}
                                        {...field}
                                        disabled={true}
                                    />
                                )}
                            />

                            <Controller
                                name="withFlightServiceConfig"
                                control={control}
                                render={({field}) => (
                                    <FormControlLabel
                                        sx={{flex: 1}} // Hace que el label ocupe más espacio
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                                disabled={true}
                                            />
                                        }
                                        label="Incluir Vuelo"
                                    />
                                )}
                            />
                        </Box>
                        <Box display="flex" alignItems="center" gap={3}>
                            <Controller
                                name="reference"
                                control={control}
                                rules={{
                                    maxLength: {
                                        value: 500,
                                        message: 'El campo excede el largo máximo permitido de: 500 caracteres'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        sx={{flex: 5}}
                                        fullWidth
                                        label="Referencia"
                                        disabled={true}
                                        size={'small'}
                                        type="text"
                                        helperText={errors.reference ? errors.reference.message : ''}
                                        error={!!errors.title}
                                        {...field}
                                    />
                                )}
                            />

                        </Box>

                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Configuración de destino(s)
                            </Typography>
                        </Box>

                        <MultiDestinationForm
                            control={methods.control}
                            availableDestinations={destinations}
                            name="destinations"
                            readonly={true}
                            refreshData={refreshDestinations}
                        />
                        <PackageImageSelector
                            readonly={true}
                            destinations={packageSelectorDestinations}
                            initialSelected={persistedImage}  // Auto-select if matches
                            onChange={(selection) => {
                                setChosenImage(selection);
                                channel.postMessage({quotationImage: selection?.imageUrl || ''});
                            }}
                        />

                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Configuración de distribucción de habitaciones
                            </Typography>
                        </Box>

                        <RoomTypeDistributionForm roomTypes={roomTypes} control={control} readonly={true}/>

                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Qué incluye
                            </Typography>
                        </Box>

                        <WhatIncludeSelector
                            data={whatIncludesContainer}
                            preselectedIds={preselectedWhatIncludesIds}
                            onChange={handleChangeWhatIncludes}
                            refreshData={refreshWhatIncludes}
                            readonly={true}
                        />

                        {withFlightServiceConfigValue && <><Box display="flex" alignItems="center" gap={1} flex={1}
                                                                p={1}
                                                                sx={{
                                                                    backgroundColor: theme.palette.primary.dark,
                                                                    borderRadius: 2
                                                                }} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Configuración de servicio de vuelo
                            </Typography>
                        </Box>

                            <Controller
                                name="pnr"
                                control={control}
                                rules={{required: 'El PNR es obligatorio'}}
                                render={({field, fieldState}) => (
                                    <TextField
                                        {...field}
                                        label="Ingrese el PNR"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        disabled={true}
                                        multiline
                                        rows={12}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                        sx={{mb: 2}}
                                    />
                                )}
                            />

                            {/* Table of segments */}
                            <FlightItineraryView segments={segments}/></>}

                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Opciones de alojamiento
                            </Typography>
                        </Box>

                        <LegacyQuotationPackageOptionsForm regimens={regimens} roomTypes={roomTypes}
                                                           destinations={destinationsSelected}
                                                           roomTypeDistributions={roomTypesIdsWithHasMinorSelected}
                                                           refreshHotelListByDestinationCode={(code: string) => Promise.resolve()}
                                                           readonly={true}
                                                           hotelsByDestination={manualHotelsByDestinations}/>


                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                No incluye
                            </Typography>
                        </Box>

                        <NoIncludeSelector
                            data={noIncludesContainer}
                            preselectedIds={preselectedNoIncludesIds}
                            onChange={handleChangeNoIncludes}
                            refreshData={refreshNoIncludes}
                            readonly={true}
                        />

                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Adicionales
                            </Typography>
                        </Box>

                        <LegacyQuotationPackageAdditionalOptionsForm destinations={destinationsSelected}
                                                                     refreshAdditionalsListByDestinationCode={(destinationCode: string) => Promise.resolve()}
                                                                     additionalsByDestination={additionalsByDestination}
                                                                     newsDestinationsCodes={[]}
                                                                     readonly={true}
                                                                     hasMinors={hasMinors}
                        />


                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Servicios exclusivos
                            </Typography>
                        </Box>

                        <ExclusiveServiceSelector
                            data={exclusiveServicesContainer}
                            preselectedIds={preselectedExclusiveServiceIds}
                            onChange={handleChangeExclusiveService}
                            refreshData={refreshExclusiveServices}
                            readonly={true}
                        />

                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Observaciones
                            </Typography>
                        </Box>

                        <Controller
                            name="observations"
                            control={control}
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    label="Ingrese las observaciones"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    multiline
                                    disabled={true}
                                    rows={10}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    sx={{mb: 2}}
                                />
                            )}
                        />


                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate(`${appRoutes.legacyQuotations.quotationPackage.list}`)}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleQuotationPreview}
                            >
                                Vista previa

                            </Button>

                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </FormProvider>
    )
        ;
};