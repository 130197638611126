import React from "react";
import {Box, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme} from "@mui/material";
import {IconRenderer} from "../../../commons/components/IconRenderer/IconRenderer";
import {IListLegacyQuotationExclusiveService} from "../../../legacy-quotation-exclusive-services/types";

interface ExclusiveServicePreviewProps {
    items: IListLegacyQuotationExclusiveService[];
}

const ExclusiveServicePreview: React.FC<ExclusiveServicePreviewProps> = ({items}) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                border: 0,
                pl: "20px",
                mt: 1
            }}
        >
            <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                 sx={{backgroundColor: theme.palette.primary.main, borderRadius: 2}} mb={1} mr={2}>
                <Typography variant="h6" sx={{color: 'white'}}>
                    Servicios exclusivos
                </Typography>
            </Box>
            <Box mr={2}>
                <List dense>
                    {items.map((item) => (
                        <ListItem
                            key={item.id}
                        >
                            {
                                <ListItemIcon
                                    sx={{
                                        // reduce the default minWidth (56px) to tighten spacing
                                        minWidth: 32,
                                    }}
                                >
                                    <IconRenderer iconName={'Check'}/>
                                </ListItemIcon>
                            }
                            <ListItemText primary={item.name}/>
                        </ListItem>
                    ))}
                </List>
            </Box>

        </Box>
    );
};

export default ExclusiveServicePreview;
