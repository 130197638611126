import React, {useState} from 'react';
import Modal from '@mui/material/Modal';
import {Alert, Backdrop, CircularProgress, Paper, Snackbar, styled, useTheme} from '@mui/material';
import {ILegacyQuotationWhatInclude} from "../../types";
import {createWhatInclude} from "../../services";
import {
    LegacyQuotationWhatIncludeUpsertForm
} from "../LegacyQuotationWhatIncludeUpsertForm/LegacyQuotationWhatIncludeUpsertForm";
import {ShowAlertState} from "../../../../../../core/types";
import {extractErrorMessage} from "../../../../../../core/helper";


type LegacyQuotationWhatIncludeFormModalProps = {
    open: boolean;
    handleClose: (id: string | null) => void;
};

const StyledPaper = styled(Paper)(({theme}) => ({
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}));

const LegacyQuotationWhatIncludeFormModal: React.FC<LegacyQuotationWhatIncludeFormModalProps> = ({
                                                                                                     open,
                                                                                                     handleClose
                                                                                                 }) => {
    const theme = useTheme();

    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);


    const handleUpsertWhatInclude = async (value: ILegacyQuotationWhatInclude) => {
        try {
            if (loading) return;
            setLoading(true);
          const result =  await createWhatInclude(value);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Qué incluye creado satisfactoriamente'
            });
            setTimeout(() => handleClose(result.data.id), 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al crear el que incluye')
            });
            setLoading(false);
        }

    }

    const handleContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    return (
        <>
            <Backdrop sx={{
                zIndex: theme.zIndex.modal + 1,
                color: '#fff',
            }} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Modal open={open}>
                <StyledPaper onClick={handleContentClick}>
                    <LegacyQuotationWhatIncludeUpsertForm loading={loading}
                                                          handleSubmitForm={handleUpsertWhatInclude}
                                                          showReturnToList={false}
                                                          handleClose={()=>handleClose(null)}
                    />
                </StyledPaper>
            </Modal>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={alert.show} autoHideDuration={1500}
                      onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
};

export default LegacyQuotationWhatIncludeFormModal;
