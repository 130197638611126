import {AxiosResponse} from "axios";
import {IPaginatedResult, ISort} from "../../../../core/types";
import {
    IAutocompleteResponseIListLegacyQuotationWhatInclude,
    IDetailLegacyQuotationWhatInclude,
    ILegacyQuotationWhatInclude,
    IListLegacyQuotationWhatInclude
} from "./types";
import {formatSortingFields} from "../../../../core/helper";
import {ApiService} from "../../../../core/services";

const endpoints = {
    list: 'api/legacy-quotation-what-includes',
    listAll: '/api/legacy-quotation-what-includes/list/all',
    get: 'api/legacy-quotation-what-includes/:id',
    create: 'api/legacy-quotation-what-includes',
    update: 'api/legacy-quotation-what-includes/:id',
    delete: 'api/legacy-quotation-what-includes/:id',
}

export const getWhatIncludes = (page = 1, pageSize = 10, sortingFields: ISort[], search: string): Promise<AxiosResponse<IPaginatedResult<IListLegacyQuotationWhatInclude>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(search && search !== '' && {search: search})
        }
    });
};

export const getWhatInclude = (id:string): Promise<AxiosResponse<IDetailLegacyQuotationWhatInclude>> => ApiService.get(endpoints.get.replace(':id',id));
export const createWhatInclude = (data: ILegacyQuotationWhatInclude): Promise<AxiosResponse<{id:string}>> => ApiService.post(endpoints.create, data);
export const updateWhatInclude = (id: string, data: ILegacyQuotationWhatInclude): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':id', id), data);
export const deleteWhatInclude = (id: string): Promise<AxiosResponse<void>> => ApiService.delete(endpoints.delete.replace(':id', id));

export const getAllWhatIncludesGrouped = (): Promise<AxiosResponse<IAutocompleteResponseIListLegacyQuotationWhatInclude>> => ApiService.get(endpoints.listAll);
