import React, {useCallback, useEffect, useState} from 'react';
import {IDetailManualHotel, IUpsertManualHotel} from "../../types";
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {createManualHotel, getManualHotel, updateManualHotel} from "../../services";
import {useNavigate, useParams} from "react-router-dom";
import {extractErrorMessage, is404Error} from "../../../../../../core/helper";
import {appRoutes} from "../../../../../../core/const";
import {ManualHotelUpsertForm} from "../../components/ManualHotelUpsertForm/ManualHotelUpsertForm";
import {ShowAlertState} from "../../../../../../core/types";
import {IDestinationList} from "../../../../../destinations/types";
import {getAllDestinations} from "../../../../../destinations/services";
import {adaptValueFromForm} from "../../helpers";

const ManualHotelUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [manualHotelDetail, setManualHotelDetail] = useState<IDetailManualHotel | null>(null);
    const [destinations, setDestinations] = useState<IDestinationList[]>([]);
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const validNumberId = !isNaN(Number(id))

    const loadDestinations =  async () => {
        try {

            setLoading(true);
            const result = (await getAllDestinations()).data;
            setDestinations(result);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar los destinos')
            });
            setLoading(false);
        }
    }

    const loadManualHotelDetail = useCallback(async () => {
        try {
            if (id && id !== 'create') {
                setLoading(true);
                const result = (await getManualHotel(+id)).data;
                setManualHotelDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el hotel')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate(`${appRoutes.legacyQuotations.manualHotel.list}`), 2500);
            }
        }
    }, [id, setLoading, setManualHotelDetail, setAlert]);


    useEffect(() => {
        loadManualHotelDetail();
    }, [loadManualHotelDetail]);

    useEffect(()=>{
        loadDestinations()
    },[]);

    const handleCreateManualHotel = async (value: IUpsertManualHotel) => {
        try {
            setLoading(true);

            await createManualHotel(adaptValueFromForm(value));
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Hotel creado satisfactoriamente'
            });
            setTimeout(() => navigate(`${appRoutes.legacyQuotations.manualHotel.list}`), 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al crear el hotel')
            });
            setLoading(false);
        }
    }

    const handleUpdateManualHotel = async (value: IUpsertManualHotel) => {
        try {
            setLoading(true);
            await updateManualHotel(Number(id), adaptValueFromForm(value));
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Hotel actualizado satisfactoriamente'
            });
            setTimeout(() => navigate(`${appRoutes.legacyQuotations.manualHotel.list}`), 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al actualizar el hotel')
            });
            setLoading(false);
        }
    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {id && id === 'create' && <ManualHotelUpsertForm destinations={destinations} loading={loading} handleSubmitForm={handleCreateManualHotel}/>}
            {id && id !== 'create' && validNumberId && manualHotelDetail &&
                <ManualHotelUpsertForm destinations={destinations} value={manualHotelDetail} loading={loading} handleSubmitForm={handleUpdateManualHotel}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default ManualHotelUpsertContainer;
