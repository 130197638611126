import React, {useCallback, useEffect, useState} from 'react';
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {createAdditional, getAdditional, updateAdditional} from "../../services";
import {appRoutes} from "../../../../../../core/const";
import {ShowAlertState} from "../../../../../../core/types";
import {IDetailLegacyQuotationAdditional, ILegacyQuotationAdditional} from "../../types";
import {extractErrorMessage, is404Error} from "../../../../../../core/helper";
import {
    LegacyQuotationAdditionalUpsertForm
} from "../../components/LegacyQuotationAdditionalUpsertForm/LegacyQuotationAdditionalUpsertForm";
import {IDestinationList} from "../../../../../destinations/types";
import {getAllDestinations} from "../../../../../destinations/services";

const LegacyQuotationAdditionalUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [destinations, setDestinations] = useState<IDestinationList[]>([]);
    const [whatIncludeDetail, setAdditionalDetail] = useState<IDetailLegacyQuotationAdditional | null>(null);
    const { id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const isCreate = id === 'create'

    const loadAdditionalDetail = useCallback(async () => {
        try {
            if (id && id !== 'create') {
                setLoading(true);
                const result = (await getAdditional(id)).data;
                setAdditionalDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el adicional')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate(`${appRoutes.legacyQuotations.additional.list}`), 2500);
            }
        }
    }, [id, setLoading, setAdditionalDetail, setAlert]);

    const loadDestinations =  async () => {
        try {

            setLoading(true);
            const result = (await getAllDestinations()).data;
            setDestinations(result);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar los destinos')
            });
            setLoading(false);
        }
    }

    useEffect(()=>{
        loadDestinations();
    },[]);


    useEffect(() => {
        loadAdditionalDetail();
    }, [loadAdditionalDetail]);

    const handleUpsertAdditional = async (value: ILegacyQuotationAdditional) => {
        const messageSuccess = isCreate ? 'creado' : 'actualizado';
        const messageError = isCreate ? 'crear' : 'actualizar';
        if (id) {
            try {
                if(loading) return;
                setLoading(true);
                if (isCreate) {
                    await createAdditional(value);
                } else {
                    await updateAdditional(id, value);
                }

                setLoading(false);
                setAlert({
                    show: true,
                    severity: 'success',
                    message: 'Adicional ' + messageSuccess + ' satisfactoriamente'
                });
                setTimeout(() => navigate(`${appRoutes.legacyQuotations.additional.list}`), 1500)
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al ' + messageError + ' el adicional')
                });
                setLoading(false);
            }
        }

    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {id && id === 'create' &&
                <LegacyQuotationAdditionalUpsertForm loading={loading} handleSubmitForm={handleUpsertAdditional} destinations={destinations}/>}
            {id && id !== 'create' && whatIncludeDetail &&
                <LegacyQuotationAdditionalUpsertForm value={whatIncludeDetail} loading={loading} destinations={destinations}
                                                      handleSubmitForm={handleUpsertAdditional}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default LegacyQuotationAdditionalUpsertContainer;
