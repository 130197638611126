import {AxiosResponse} from "axios";
import {IFilterListLegacyQuotationFlight, ILegacyQuotationFlightUpsert, IListLegacyQuotationFlight} from "./types";
import {ApiService} from "../../../../core/services";
import {IPaginatedResult, ISort} from "../../../../core/types";
import {cleanFilters, formatSortingFields} from "../../../../core/helper";

const endpoints = {
    list: 'api/legacy-quotation-flight',
    getDetailToForm: 'api/legacy-quotation-flight/:id',
    copy: 'api/legacy-quotation-flight/:id/copy',
    upsert: 'api/legacy-quotation-flight/:id',
    delete: 'api/legacy-quotation-flight/:id',
    parsePNRToFlightItinerary: 'api/flight-itinerary/parse',
}

export const getLegacyQuotationFlights = (page = 1, pageSize = 10, sortingFields: ISort[], search: string, filters?: IFilterListLegacyQuotationFlight): Promise<AxiosResponse<IPaginatedResult<IListLegacyQuotationFlight>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(filters && cleanFilters(filters)), ...(search && search !== '' && {search: search})
        }
    });
};
export const getDetailLegacyQuotationFlight = (id: string): Promise<AxiosResponse<ILegacyQuotationFlightUpsert>> => ApiService.get(endpoints.getDetailToForm.replace(':id', id));
export const copyLegacyQuotationFlight = (id: string): Promise<AxiosResponse<{
    id: string
}>> => ApiService.post(endpoints.copy.replace(':id', id), {});
export const upsertLegacyQuotationFlight = (id: string, data: ILegacyQuotationFlightUpsert): Promise<AxiosResponse<void>> => ApiService.put(endpoints.upsert.replace(':id', id), data);
export const deleteLegacyQuotationFlight = (id: string): Promise<AxiosResponse<void>> => ApiService.delete(endpoints.delete.replace(':id', id));
