import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Box, Button, Card, CardContent, Typography} from '@mui/material';
import {ILegacyQuotationGeneralConditionContent} from "../../types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {decodeEscapedHtml} from "../../helper";


interface LegacyQuotationGeneralConditionUpsertFormProps {
    handleSubmitForm: (data: ILegacyQuotationGeneralConditionContent) => void;
    data: ILegacyQuotationGeneralConditionContent | null
    loading: boolean
}

const toolbarOptions = [
    ['bold', 'italic', 'underline'],
    ['blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{size: ["small", false, "large", "huge"]}]
];

const LegacyQuotationGeneralConditionUpsertForm = ({
                                                       handleSubmitForm,
                                                       loading,
                                                       data
                                                   }: LegacyQuotationGeneralConditionUpsertFormProps) => {
    const {
        control,
        handleSubmit,
        setValue,
        trigger,
        formState: {isValid, errors},
    } = useForm<ILegacyQuotationGeneralConditionContent>({
        defaultValues: {
            content: data ? decodeEscapedHtml(data.content) : ''
        },
    });
    const onSubmit = async (data: ILegacyQuotationGeneralConditionContent) => {
        handleSubmitForm(data);
    };

    useEffect(() => {
        setValue('content', data?.content || '');
        trigger('content');
    }, [data]);


    return (<Card sx={{width: '100%'}}>
            <CardContent>
                <Typography variant="h5" style={{fontWeight: 'bold'}}
                            p={1}> {data ? 'Actualizar' : 'Configurar'}{' Condiciones Generales'}</Typography>
                <br/>
                <Box
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2}}
                >
                    <Controller
                        name="content"
                        control={control}
                        rules={{required: "El contenido es obligatorio"}}
                        render={({field}) => (
                            <ReactQuill
                                {...field}
                                theme="snow"
                                style={{height: "500px", width: '100%'}}
                                onChange={(content) => field.onChange(content)} // Asegura que React Hook Form reciba los cambios
                                modules={{
                                    toolbar: toolbarOptions
                                }}
                                // 5) Incluir "size" en formats para que Quill lo aplique correctamente
                                formats={["size", "bold", "italic", "font"]}
                            />

                        )}
                    />
                    <Box display="flex" width="100%" justifyContent="flex-end" sx={{mt: 5}}>
                        <Button type="submit" variant="contained" color="primary" disabled={!isValid || loading}>
                            Guardar
                        </Button>
                    </Box>

                </Box>
            </CardContent>
        </Card>
    );
};

export default LegacyQuotationGeneralConditionUpsertForm;
