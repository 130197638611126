import React, {useEffect, useState} from 'react';
import {
    Alert,
    Backdrop,
    Box, Button,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Snackbar,
    useTheme
} from "@mui/material";
import {useParams} from "react-router-dom";
import {IBitrixDealInfoToOrder, ICreateSaleOrder} from "../../types";
import {createSaleOrder, getDealInfoToSaleOrder} from "../../services";
import {ICreateSaleOrderFormValue, SaleOrderCreateForm} from "../../components/SaleOrderCreateForm/SaleOrderCreateForm";
import {ShowAlertState} from "../../../../core/types";
import {extractErrorMessage} from "../../../../core/helper";
import {IListIssueRequirement} from "../../../sale-order-issue-requirements/types";
import {getAllIssueRequirements} from "../../../sale-order-issue-requirements/services";
import dayjs, {Dayjs} from "dayjs";

const SaleOrderUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [paymentLinkURL, setPaymentLinkURL] = useState<string | null>(null); // Guarda la URL generada
    const [issueRequirements, setIssueRequirements]= useState<IListIssueRequirement[]>([])
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [dealIdDetail, setDealIdDetail]  = useState<IBitrixDealInfoToOrder | null>(null);
    const [openConfirmCreateSaleOrder, setOpenConfirmCreateSaleOrder] = useState<boolean>(false);
    const {id} = useParams<{ id: string }>();
    const [saleOrderFormValueToSave, setSaleOrderFormValueToSave] = useState<ICreateSaleOrderFormValue|null>(null)
    const isCreate = id === 'create';
    const handleDealIdDetail = async (dealId:string)=>{
        try {

                setLoading(true);
                const result = (await getDealInfoToSaleOrder(dealId)).data;
                setDealIdDetail(result);
                setLoading(false);

        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el detalle de la negociacón')
            });
            setLoading(false);
            setDealIdDetail(null);
        }
    }

    const loadIssueRequirements = async ()=>{
        try {
            setLoading(true);
            const result = (await getAllIssueRequirements()).data;
            setIssueRequirements([...result]);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar los requerimientos para emisión para seleccionar')
            });
        } finally {
            setLoading(false);
        }
    }

    const handleCopyToClipboard = () => {
        if (paymentLinkURL) {
            navigator.clipboard.writeText(paymentLinkURL);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Link de pago copiado satisfactoriamente'
            });
        }
    };

    const handleCheckDepartureDateBeforeCreateSaleOrder = (value: ICreateSaleOrderFormValue) =>{
        const departureDateValue: Dayjs = dayjs(value.departureDate, 'YYYY-MM-DD');
        const today: Dayjs = dayjs();
        if(departureDateValue.isBefore(today)){
            setSaleOrderFormValueToSave(value);
            setOpenConfirmCreateSaleOrder(true);
        } else {
            handleCreateSaleOrder(value);
        }
    }

    const handleConfirmCreateOrder = ()=>{
        setOpenConfirmCreateSaleOrder(false);
        saleOrderFormValueToSave && handleCreateSaleOrder(saleOrderFormValueToSave);
    }


    const handleCreateSaleOrder = async (value: ICreateSaleOrderFormValue) => {
        const messageSuccess = isCreate ? 'creada' : 'actualizada';
        const messageError = isCreate ? 'crear' : 'actualizar';
        if (id) {
            try {
                if(loading) return;
                setLoading(true);
                if (isCreate) {
                    const valueToSend = {
                        ...value,
                        amount:Number(value.amount),
                        fee:Number(value.fee),
                    } as ICreateSaleOrder
                   const result = await createSaleOrder(valueToSend);
                    setPaymentLinkURL(result.data.linkUrl);


                setLoading(false);
                if(result.data.paymentLinkSend) {
                    setAlert({
                        show: true,
                        severity: 'success',
                        message: 'Orden de venta ' + messageSuccess + ' satisfactoriamente y link de pago enviado'
                    });
                } else {
                    setAlert({
                        show: true,
                        severity: 'warning',
                        message: 'Orden de venta ' + messageSuccess + ' satisfactoriamente pero el link de pago no se puedo enviar a Bitrix.'
                    });
                }

                }
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al ' + messageError + ' la orden de venta')
                });
                setLoading(false);
            }
        }

    }

    useEffect(() => {
        loadIssueRequirements();
    }, []);


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {id && id === 'create' &&<>
                <SaleOrderCreateForm handleSubmitForm={handleCheckDepartureDateBeforeCreateSaleOrder}
                                     loading={loading}
                                     handleCheckDeal={handleDealIdDetail}
                                     dealInfo={dealIdDetail}
                                     paymentLinkURL={paymentLinkURL}
                                     handleCopyLinkURL={handleCopyToClipboard}
                                     issueRequirements={issueRequirements}
                />

                <Dialog open={openConfirmCreateSaleOrder} onClose={()=>setOpenConfirmCreateSaleOrder(false)}>
                    <DialogTitle>Fecha de viaje anterior al día de hoy</DialogTitle>
                    <DialogContent>

                        <DialogContentText>
                            La fecha de viaje es anterior al día de hoy ¿Confirma crear la orden de todas maneras?
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setOpenConfirmCreateSaleOrder(false)}>Cancelar</Button>
                        <Button onClick={()=>handleConfirmCreateOrder()} color="primary">Confirmar</Button>
                    </DialogActions>
                </Dialog>

            </>

            }

            <Snackbar open={alert.show} autoHideDuration={3000} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default SaleOrderUpsertContainer;
