import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getGeneralConditions} from "../../../legacy-quotation-general-conditions/services";
import {extractErrorMessage, is404Error} from "../../../../../../core/helper";
import {ShowAlertState} from "../../../../../../core/types";
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import {ILegacyQuotationFlightPreviewToClient} from "../../types";
import {getPreviewToClientOfLegacyQuotationFlight} from "../../services";
import {LegacyQuotationFlightPreview} from "../../components/LegacyQuotationFlightPreview/LegacyQuotationFlightPreview";

export const LegacyQuotationFlightClientPreviewContainer: React.FC = (props) => {

    const theme = useTheme();
    const {id} = useParams<{ id: string }>();
    const [generalConditions, setGeneralConditions] = useState<string | null>(null);
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [quotationFlightDetail, setQuotationFlightDetail] = useState<ILegacyQuotationFlightPreviewToClient | null>(null);


    const loadQuotationPackage = useCallback(async () => {
        try {
            if (id) {
                setLoading(true);
                const result = (await getPreviewToClientOfLegacyQuotationFlight(id)).data;
                setQuotationFlightDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el detalle de la cotización')
            });
            setLoading(false);
        }
    }, [id, setLoading, setAlert]);

    const handleLoadGeneralsConditions = async () => {
        try {
            setLoading(true);
            const result = await getGeneralConditions();
            setLoading(false);
            setGeneralConditions(result.data.content);
        } catch (error) {
            if (!is404Error(error)) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al  cargar las Condiciones Generales')
                });
            }

            setLoading(false);
        }
    }

    useEffect(() => {
        handleLoadGeneralsConditions();
    }, []);

    useEffect(() => {
        loadQuotationPackage();
    }, [id]);

    return (<>
            <Box sx={{width: '80%', padding: 3, pt: 0}}><Backdrop sx={{
                zIndex: theme.zIndex.modal + 1,
                color: '#fff',

            }} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop></Box>
            {quotationFlightDetail &&
                <LegacyQuotationFlightPreview quotationNumber={quotationFlightDetail.quotationNumber}
                                              quotationTitle={quotationFlightDetail.title}
                                              whatIncludes={quotationFlightDetail.whatIncludes}
                                              noIncludes={quotationFlightDetail.noIncludes}
                                              flightItinerarySegments={quotationFlightDetail.flightItinerarySegments}
                                              options={quotationFlightDetail.options}
                                              generalConditions={generalConditions}
                                              sellerProfile={quotationFlightDetail.sellerProfile}
                                              observations={quotationFlightDetail.observations}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>

        </>
    );
}