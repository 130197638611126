import React from "react";
import {IconButton, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {IIssueRequirementFromSaleOrder} from "../../types";

interface Props {
    issueRequirements: IIssueRequirementFromSaleOrder[];
}

const IssueRequirementsBody: React.FC<Props> = ({issueRequirements}) => {
    return (
        <>
            {issueRequirements.length === 0 ? (
                <em>Sin requerimientos</em>
            ) : (
                issueRequirements.map((req, index) => (
                    <React.Fragment key={index}>
                        {req.issueRequirementName}
                        {req.comment && (
                            <Tooltip title={req.comment}>
                                <IconButton size="small" style={{marginLeft: 4}}>
                                    <InfoIcon fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        )}
                        {index < issueRequirements.length - 1 && ", "}
                    </React.Fragment>
                ))
            )}
        </>
    );
};

export default IssueRequirementsBody;
