import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {reducer as authReducer} from "../modules/auth/slice";



export const rootReducer = combineReducers({
	auth: authReducer,

});

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer
});

export const persistor = persistStore(store);



export type RootState = ReturnType<typeof rootReducer>;
export default store;
