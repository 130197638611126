import React from "react";
import {Box, Typography, useTheme} from "@mui/material";
import {IFlightItinerarySegmentInformation} from "../../../legacy-quotation-packages/types";
import {
    FlightItineraryTable
} from "../FlightItineraryTable/FlightItineraryTable";
import {FlightItineraryView} from "../FlightItineraryView/FlightItineraryView";

interface LegacyQuotationFlightItineraryPreviewProps {
    segments: IFlightItinerarySegmentInformation[];
}

const LegacyQuotationFlightItineraryPreview: React.FC<LegacyQuotationFlightItineraryPreviewProps> = ({segments}) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                border: 0,
                pl: "20px",
                pb: "20px",
                mt: 1
            }}
        >
            <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                 sx={{backgroundColor: theme.palette.primary.main, borderRadius: 2}} mb={1} mr={2}>
                <Typography variant="h6" sx={{color: 'white'}}>
                    Itinerario de vuelo
                </Typography>
            </Box>
            <Box  mr={2}>
            <FlightItineraryView segments={segments}/>
            </Box>

        </Box>
    );
};

export default LegacyQuotationFlightItineraryPreview;
