import React from 'react';
import {Box, Card, CardContent, Stack, Typography, useTheme,} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import {ILegacyQuotationPackageAdditionalOptionPreview} from "../../types";
import {IconRenderer} from "../../../commons/components/IconRenderer/IconRenderer";

interface LegacyQuotationPackageAdditionalOptionsPreviewProps {
    options: ILegacyQuotationPackageAdditionalOptionPreview[];
}

const LegacyQuotationPackageAdditionalOptionsPreview: React.FC<LegacyQuotationPackageAdditionalOptionsPreviewProps> = ({options}) => {
    const theme = useTheme();
    return (
        <Box sx={{border: 0, pl: "20px", mt: 1}}>
            <Box
                display="flex"
                alignItems="center"
                gap={1}
                flex={1}
                p={1}
                sx={{backgroundColor: theme.palette.primary.main, borderRadius: 2}}
                mb={1}
                mr={2}
            >
                <Typography variant="h6" sx={{color: 'white'}}>
                    Adicionales
                </Typography>
            </Box>
            <Box mr={2}>
                <Stack spacing={2}>
                    {options.map((option, optionIndex) => {

                        return (
                            <Card key={option.optionId} variant="outlined" sx={{borderRadius: 3}}>
                                <CardContent>
                                    <Typography
                                        variant="h6"
                                        color="text.secondary"
                                        fontWeight="bold"
                                        gutterBottom
                                    >
                                        Opción #{optionIndex + 1}
                                    </Typography>
                                    {option.additionalOptionItems.map((item, itemIndex) => (
                                        <Box key={item.optionAdditionalItemId} ml={1} mt={1}>
                                            <Box display="flex" alignItems="center" gap={2}>

                                                <IconRenderer iconName={item.additionalIcon}/>
                                                <Typography variant="subtitle1"
                                                            sx={{fontWeight: 'bold'}}>
                                                   {item.additionalName} - {item.destinationName}
                                                </Typography>

                                            </Box>
                                            { ((item.adultPrice != null && item.adultPrice != '')||(item.minorPrice != null && item.minorPrice != '')) && <Box ml={4} mt={0.5}>
                                                {(item.adultPrice != null && item.adultPrice != '') &&<Box display="flex" alignItems="center" gap={1}>
                                                    <PersonIcon fontSize="small"
                                                                sx={{color: theme.palette.primary.main}}/>
                                                    <Typography variant="body2">
                                                        Precio por Adulto: <strong>USD {item.adultPrice}</strong>
                                                    </Typography>
                                                </Box>}
                                                {item.minorPrice != null && item.minorPrice != '' && (
                                                    <Box display="flex" alignItems="center" gap={1}>
                                                        <ChildCareIcon fontSize="small"
                                                                       sx={{color: theme.palette.primary.main}}/>
                                                        <Typography variant="body2">
                                                            Precio por Niño: <strong>USD {item.minorPrice}</strong>
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Box>}
                                        </Box>
                                    ))}
                                </CardContent>
                            </Card>
                        );
                    })}
                </Stack>
            </Box>
        </Box>
    );
};

export default LegacyQuotationPackageAdditionalOptionsPreview;
