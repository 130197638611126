import React, {forwardRef, useImperativeHandle} from 'react';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {Box, IconButton, TextField} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

interface FileNumber {
    fileNumber: string
}

export interface IFormInput {
    fileNumbers: FileNumber[];
}

interface FileNumbersFormProps {
    initialData: string[];
    allowEdit:boolean
}


const FileNumbersForm = forwardRef(({initialData, allowEdit}: FileNumbersFormProps, ref) => {


    const {control, getValues, trigger, formState: {errors, isValid}} = useForm<IFormInput>({
        defaultValues: {
            fileNumbers: initialData.map(x => ({fileNumber: x})) as FileNumber[],
        },
        mode: 'onChange',
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'fileNumbers',
    });

    useImperativeHandle(ref, () => ({
        getFormValues: () => getValues(),
        isValidForm: () => isValid,
        triggerValidation: () => trigger(),
    }));

    return (
        <Box component="fieldset" sx={{border: '1px solid #ccc', borderRadius: '4px', p: 2}}>

            <Box component="legend" sx={{fontWeight: 'bold'}}>
                File(s)
            </Box>

            {fields.map((item, index) => (

                <Box key={item.id} display="flex" alignItems="flex-start" gap={2} pt={1}>
                    <Controller
                        name={`fileNumbers.${index}.fileNumber`}
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Este campo es requerido'
                            },
                            pattern: {value: /^[0-9]*$/, message: "Solo se permiten números"} // Validación para solo números
                        }}
                        render={({field}) => (
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Número de file"
                                required={true}
                                disabled={!allowEdit}
                                size="small"
                                type="text"
                                error={!!errors?.fileNumbers?.[index]?.fileNumber}
                                helperText={errors?.fileNumbers?.[index]?.fileNumber?.message}
                                {...field}
                            />
                        )}
                    />
                    {index + 1 == fields.length && allowEdit &&
                        <IconButton color="primary" title={'Agregar nuevo número de file'}
                                    onClick={() => append({fileNumber: ''})}>
                            <AddIcon/>
                        </IconButton>}

                    {index > 0 && allowEdit &&
                        <IconButton title={'Eliminar número de file'} color="secondary" onClick={() => remove(index)}>
                            <DeleteIcon/>
                        </IconButton>}
                </Box>
            ))}

        </Box>
    );
});

export default FileNumbersForm;
