import React, {useCallback, useEffect, useState} from 'react';
import {
    IFilterListLegacyQuotationFlight,
    IListLegacyQuotationFlight
} from "../../types";
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {copyLegacyQuotationFlight, deleteLegacyQuotationFlight, getLegacyQuotationFlights} from "../../services";
import {useNavigate} from "react-router-dom";
import {appRoutes} from "../../../../../../core/const";
import {ISort, ShowAlertState} from "../../../../../../core/types";
import {extractErrorMessage} from "../../../../../../core/helper";
import LegacyQuotationsFlightList from "../../components/LegacyQuotationsFlightList/LegacyQuotationsFlightList";

export const defaultFilterLegacyQuotationFlightList: IFilterListLegacyQuotationFlight = {
    quotationNumber: '',
    title: '',
    reference: '',
    travelDateFrom: '',
    travelDateTo: '',
    createdAtFrom: '',
    createdAtTo: '',
    createdBy: '',
    onlyMine: '',
}
const LegacyQuotationFlightListContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(search);
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [order, setOrder] = useState<ISort[]>([]);
    const [filters, setFilters] = useState<IFilterListLegacyQuotationFlight>(defaultFilterLegacyQuotationFlightList);
    const [items, setItems] = useState<IListLegacyQuotationFlight[]>([])
    const navigate = useNavigate();

    const getTime = () => new Date().getTime();

    const onAdd = () => navigate(`${appRoutes.legacyQuotations.quotationFlight.list}/create-${getTime()}`);


    const onViewReadOnly = (item: IListLegacyQuotationFlight) => navigate(appRoutes.legacyQuotations.quotationFlight.readonly.replace(':id', item.id));

    const onEdit = (item: IListLegacyQuotationFlight) => navigate(`${appRoutes.legacyQuotations.quotationFlight.list}/${item.id}`);
    const onDelete = async (item: IListLegacyQuotationFlight) => {
        try {
            setLoading(true);
            await deleteLegacyQuotationFlight(item.id);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Elemento eliminado satisfactoriamente'
            });
            setTimeout(loadLegacyQuotationsFlight, 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al eliminar la cotización')
            });
            setLoading(false);
        }
    };

    const onCopy = async (item: IListLegacyQuotationFlight) => {
        try {
            setLoading(true);
            const result = (await copyLegacyQuotationFlight(item.id)).data;
            setAlert({
                show: true,
                severity: 'success',
                message: 'Cotización copiada satisfactoriamente'
            });
            setTimeout(() => navigate(`${appRoutes.legacyQuotations.quotationFlight.list}/${result.id}`), 2500)
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al copiar la cotización de vuelos')
            });
            setLoading(false);
        }
    }

    const loadLegacyQuotationsFlight = useCallback(async () => {
        try {
            setLoading(true);
            const paginatedResult = (await getLegacyQuotationFlights(page + 1, pageSize, order, debouncedSearchTerm, filters)).data;
            setTotalElements(paginatedResult.total);
            setItems(paginatedResult.items);
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de cotizaciones de vuelos')
            });
            setLoading(false);
        }
    }, [page, pageSize, order, debouncedSearchTerm, filters]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 500); // Retraso de 500 ms

        // Limpia el timeout si el efecto se vuelve a ejecutar antes de que el timeout finalice
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        loadLegacyQuotationsFlight();
    }, [loadLegacyQuotationsFlight]);

    return (<><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            <LegacyQuotationsFlightList title={'Cotizaciones de vuelos'}
                                        items={items}
                                        onAdd={onAdd}
                                        onEdit={onEdit}
                                        onDelete={onDelete}
                                        onCopy={onCopy}
                                        onViewReadOnly={onViewReadOnly}
                                        filters={filters}
                                        setFilters={setFilters}
                                        order={order}
                                        setOrder={setOrder}
                                        search={search}
                                        setSearch={setSearch}
                                        page={page}
                                        setPage={setPage}
                                        pageSize={pageSize}
                                        setPageSize={setPageSize}
                                        totalElements={totalElements}
            />
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
}

export default LegacyQuotationFlightListContainer;
