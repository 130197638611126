import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    IconButton,
    Typography,
    useTheme,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Upload } from '@mui/icons-material';
import { IAutoCompleteDestinationList } from '../../../../../destinations/types';

/**
 * The object we pass back to the parent whenever an image is selected.
 */
export type SelectedImage = {
    destinationCode?: string; // present if from a destination
    imageUrl: string;         // required for display
    imageBase64?: string;     // if it's a newly uploaded base64 image
};

/**
 * If a destination doesn't have an imageUrl, we'll show this placeholder.
 */
export const NO_IMAGE_PLACEHOLDER =
    'https://files-testing.travellerviajes.com.uy/destinations/0x1ef4b931f5cb63a6a1224df83d41eafa_2024-07-26_18-07-53.jpg';

/** Union types for the "cards" we display: */

// Destination card
interface DestinationCard {
    code: string;
    url: string;
    label: string;
    isSelected: boolean;
    hasRealImage: boolean;
    isCustom: false;
    isNone?: false;
}

// Custom card (for uploaded image)
interface CustomCard {
    code?: undefined;
    url: string;
    label: string;
    isSelected: boolean;
    hasRealImage: boolean;
    isCustom: true;
    isNone?: false;
}

// "None" card (to select no image)
interface NoneCard {
    code: 'isNone';
    url: string; // can be empty or use a placeholder if desired
    label: string;
    isSelected: boolean;
    hasRealImage: boolean; // set to true so it's clickable
    isCustom: false;
    isNone: true;
}

type CardType = DestinationCard | CustomCard | NoneCard;

/**
 * Props for the PackageImageSelector
 */
interface PackageImageSelectorProps {
    destinations: IAutoCompleteDestinationList[];
    onChange?: (selected: SelectedImage | null) => void;
    initialSelected: SelectedImage | null;
    readonly?: boolean;
    isCreate?: boolean;
}

export const PackageImageSelector: React.FC<PackageImageSelectorProps> = ({
                                                                              destinations,
                                                                              onChange,
                                                                              initialSelected,
                                                                              readonly = false,
                                                                              isCreate = false,
                                                                          }) => {
    const theme = useTheme();

    /**
     * Which image is currently selected (either from destination, custom, or none).
     */
    const [selectedImage, setSelectedImage] = useState<SelectedImage | null>(null);

    /**
     * We store the user-uploaded custom image (base64) separately
     * so we don't lose it if the user selects another image afterward.
     */
    const [customUrl, setCustomUrl] = useState<string>('');

    /**
     * Flag to detectar si el usuario ya interactuó (seleccionó alguna tarjeta manualmente).
     */
    const [hasUserInteracted, setHasUserInteracted] = useState<boolean>(false);

    useEffect(() => {
        onChange?.(selectedImage);
    }, [selectedImage, onChange]);

    /**
     * Si hay un initialSelected, se intenta emparejar con un destino o tratar como custom.
     * Si es null, se deja sin imagen seleccionada.
     */
    useEffect(() => {
        if (initialSelected === null) {
            setSelectedImage(null);
            return;
        }
        if (initialSelected?.destinationCode) {
            // Verificar si el código + imageUrl coincide con algún destino
            const found = destinations.some(
                dest =>
                    dest.code === initialSelected.destinationCode &&
                    dest.imageUrl === initialSelected.imageUrl
            );
            if (found) {
                setSelectedImage(initialSelected);
            }
        } else if (initialSelected) {
            // Tratar como imagen custom
            setSelectedImage({ imageUrl: initialSelected.imageUrl });
            setCustomUrl(initialSelected.imageUrl);
        }
    }, [initialSelected, destinations]);

    /**
     * Si la lista de destinos cambia, se verifica que la imagen seleccionada (si es de destino)
     * siga siendo válida.
     */
    useEffect(() => {
        if (!selectedImage?.destinationCode) return;
        const stillExists = destinations.some(
            dest =>
                dest.code === selectedImage.destinationCode &&
                dest.imageUrl === selectedImage.imageUrl
        );
        if (!stillExists) {
            setSelectedImage(null);
        }
    }, [destinations, selectedImage]);

    /**
     * Auto-selección: Si no hay imagen seleccionada, el usuario no ha interactuado aún
     * y existen destinos con imageUrl, se selecciona el primero (solo en modo create).
     */
    useEffect(() => {
        if (!hasUserInteracted && selectedImage === null && destinations.length > 0 && isCreate) {
            const firstWithImage = destinations.find(dest => !!dest.imageUrl);
            if (firstWithImage) {
                setSelectedImage({
                    destinationCode: firstWithImage.code,
                    imageUrl: firstWithImage.imageUrl || '',
                });
            }
        }
    }, [destinations, selectedImage, hasUserInteracted, isCreate]);

    /**
     * Manejar la carga de una imagen custom en base64.
     * Se almacena en customUrl y se selecciona inmediatamente.
     */
    const handleUploadCustomImage = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.onload = (ev) => {
                if (ev.target?.result) {
                    const base64String = ev.target.result as string;
                    setCustomUrl(base64String); // Guardar la imagen custom
                    setSelectedImage({
                        imageUrl: base64String,
                        imageBase64: base64String,
                    });
                    setHasUserInteracted(true);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    // Construir tarjetas de destino
    const destinationCards: DestinationCard[] = destinations.map((dest) => {
        const hasRealImage = !!dest.imageUrl;
        const url = dest.imageUrl || NO_IMAGE_PLACEHOLDER;
        const isSelected =
            selectedImage?.destinationCode === dest.code &&
            selectedImage?.imageUrl === url;

        return {
            code: dest.code,
            url,
            label: hasRealImage
                ? `Imagen de ${dest.name}, ${dest.countryName}`
                : `Sin imagen configurada - ${dest.name}, ${dest.countryName}`,
            isSelected,
            hasRealImage,
            isCustom: false,
        };
    });

    // Tarjeta custom (imagen subida por el usuario)
    const hasCustom = !!customUrl;
    const isCustomSelected =
        !selectedImage?.destinationCode && selectedImage?.imageUrl === customUrl;
    const customCard: CustomCard = {
        url: customUrl,
        label: 'Imagen personalizada',
        isSelected: !!isCustomSelected,
        hasRealImage: hasCustom,
        isCustom: true,
    };

    // Tarjeta "No usar imagen": siempre visible y clickeable.
    const noneCard: NoneCard = {
        code: 'isNone',
        url: '',
        label: 'No usar imagen',
        isSelected: selectedImage === null,
        hasRealImage: true,
        isCustom: false,
        isNone: true,
    };

    // Combinar todas las tarjetas: destinos + custom + none
    const allCards: CardType[] = [...destinationCards, customCard, noneCard];

    /**
     * Función para seleccionar una imagen de destino.
     */
    const selectDestinationImage = (card: DestinationCard) => {
        // Evitamos seleccionar destino sin imagen real.
        if (!card.hasRealImage) return;
        setSelectedImage({
            destinationCode: card.code,
            imageUrl: card.url,
        });
        setHasUserInteracted(true);
    };

    /**
     * Función para seleccionar la tarjeta custom.
     */
    const selectCustomCard = (card: CustomCard) => {
        if (!card.hasRealImage) return;
        setSelectedImage({
            imageUrl: card.url,
            imageBase64: card.url.startsWith('data:') ? card.url : undefined,
        });
        setHasUserInteracted(true);
    };

    /**
     * Función para seleccionar la tarjeta "No usar imagen".
     */
    const selectNoneCard = () => {
        setSelectedImage(null);
        setHasUserInteracted(true);
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Seleccione una imagen para la cotización
            </Typography>

            <Grid container spacing={2}>
                {allCards.map((card, index) => {
                    const { url, label, isSelected, isCustom } = card;
                    // Para determinar si la tarjeta es clickeable:
                    // - Si es "No usar imagen" (isNone): siempre true.
                    // - Si es custom: depende de que haya una imagen custom.
                    // - Si es de destino: solo si tiene imageUrl real.

                    const isClickable = card.isNone
                        ? true
                        : (isCustom ? hasCustom : card.hasRealImage);
                    const handleClick = () => {
                        if (!isClickable) return;
                        if ('isNone' in card && card.isNone) {
                            selectNoneCard();
                        } else if (isCustom) {
                            selectCustomCard(card as CustomCard);
                        } else {
                            selectDestinationImage(card as DestinationCard);
                        }
                    };

                    return (
                        <Grid item xs={6} sm={4} md={3} key={index}>
                            <Box
                                sx={{
                                    position: 'relative',
                                    border: isSelected ? '2px solid #1976d2' : '1px solid #ccc',
                                    borderRadius: 2,
                                    overflow: 'hidden',
                                    cursor: isClickable ? 'pointer' : 'not-allowed',
                                    height: 160,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '&:hover': isClickable ? { opacity: 0.9 } : {},
                                }}
                                onClick={!readonly ? handleClick : () => {}}
                            >
                                {card.isNone ? (
                                    <Typography variant="body2" sx={{ px: 1, textAlign: 'center' }}>
                                        No usar imagen
                                    </Typography>
                                ) : isCustom && !hasCustom ? (
                                    <Typography variant="body2" sx={{ px: 1 }}>
                                        (No se ha cargado ninguna imagen)
                                    </Typography>
                                ) : (
                                    <img
                                        src={url || NO_IMAGE_PLACEHOLDER}
                                        alt={label}
                                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                                    />
                                )}

                                {isSelected && (
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 8,
                                            color: theme.palette.primary.main,
                                        }}
                                    >
                                        <CheckCircleIcon fontSize="large" />
                                    </IconButton>
                                )}
                            </Box>

                            <Box mt={1} textAlign="center">
                                <Typography variant="body2">{label}</Typography>
                                {isCustom && !readonly && (
                                    <Button
                                        variant="outlined"
                                        component="label"
                                        startIcon={<Upload />}
                                        size="small"
                                        sx={{ mt: 1 }}
                                    >
                                        {hasCustom ? 'Reemplazar imagen' : 'Cargar imagen'}
                                        <input
                                            type="file"
                                            hidden
                                            accept="image/*"
                                            onChange={handleUploadCustomImage}
                                        />
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

export default PackageImageSelector;
