import LegacyQuotationHeaderPreview
    from "../../../commons/components/LegacyQuotationHeaderPreview/LegacyQuotationHeaderPreview";
import backgroundImage from "../../../../../../assets/images/quotation_header_bg.png"; // Importa la imagen de fondo
import logo from "../../../../../../assets/images/logo_new.svg";
import React from "react";
import Box from "@mui/material/Box";
import {IListAllLegacyQuotationWhatInclude} from "../../../legacy-quotation-what-includes/types";
import WhatIncludePreviewPreview from "../../../commons/components/WhatIncludePreview/WhatIncludePreview";
import LegacyQuotationFlightItineraryPreview
    from "../../../commons/components/LegacyQuotationFlightItineraryPreview/LegacyQuotationFlightItineraryPreview";
import SellerFooterPreview from "../../../commons/components/SellerFooterPreview/SellerFooterPreview";
import GeneralConditionsPreview from "../../../commons/components/GeneralConditionsPreview/GeneralConditionsPreview";
import {IFlightItinerarySegmentInformation} from "../../../legacy-quotation-packages/types";
import ObservationsPreview from "../../../commons/components/ObservationsPreview/ObservationsPreview";
import PaymentMediaPreview from "../../../commons/components/PaymentMediaPreview/PaymentMediaPreview";
import {IUserProfile} from "../../../../../users/types";
import CompanyFooterPreview from "../../../commons/components/CompanyFooterPreview/CompanyFooterPreview";
import QuotationTitlePreview from "../../../commons/components/QuotationTitlePreview/QuotationTitlePreview";
import {ILegacyQuotationFlightTariffOptionPreview} from "../../types";
import LegacyQuotationFlightTariffOptionsPreview
    from "../LegacyQuotationFlightTariffOptionsPreview/LegacyQuotationFlightTariffOptionsPreview";
import {containsVisibleText} from "../../../legacy-quotation-packages/helper";
import BaggageInfoCardPreview from "../BaggageInfoCardPreview/BaggageInfoCardPreview";
import {IListAllLegacyQuotationNoInclude} from "../../../legacy-quotation-no-includes/types";
import NoIncludePreview from "../../../commons/components/NoIncludePreview/NoIncludePreview";

export interface LegacyQuotationPackagePreviewProps {
    quotationNumber: number | null;
    quotationTitle: string | null;
    whatIncludes: IListAllLegacyQuotationWhatInclude[];
    noIncludes: IListAllLegacyQuotationNoInclude[];
    flightItinerarySegments: IFlightItinerarySegmentInformation[];
    generalConditions: string | null;
    observations: string | null;
    options: ILegacyQuotationFlightTariffOptionPreview[];
    sellerProfile: IUserProfile;

}

export const LegacyQuotationFlightPreview: React.FC<LegacyQuotationPackagePreviewProps> = ({
                                                                                               quotationNumber,
                                                                                               quotationTitle,
                                                                                               whatIncludes,
                                                                                               noIncludes,
                                                                                               flightItinerarySegments,
                                                                                               generalConditions,
                                                                                               observations,
                                                                                               options,
                                                                                               sellerProfile
                                                                                           }) => {


    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minHeight: "100vh",
                mt: 0,
                pt: 0,
                mb: 2
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "1200px", // Ajusta el ancho máximo
                    // backgroundColor: "white",
                    boxShadow: 7,
                    mt: 2,
                    borderRadius: "8px",
                }}
            >
                <LegacyQuotationHeaderPreview
                    backgroundImage={backgroundImage}
                    logo={logo}
                    quoteNumber={quotationNumber ? quotationNumber + '' : '00000'}
                />
                <QuotationTitlePreview title={quotationTitle} image={null}/>
                <WhatIncludePreviewPreview items={whatIncludes}/>
                <LegacyQuotationFlightItineraryPreview segments={flightItinerarySegments}/>
                <LegacyQuotationFlightTariffOptionsPreview options={options}/>
                <BaggageInfoCardPreview/>
                {noIncludes.length > 0 && <NoIncludePreview items={noIncludes}/>}
                {observations && containsVisibleText(observations || '') &&
                    <ObservationsPreview observations={observations}/>}
                <PaymentMediaPreview/>
                <GeneralConditionsPreview htmlContent={generalConditions}/>
                <SellerFooterPreview seller={sellerProfile}/>
                <CompanyFooterPreview/>

            </Box>
        </Box>
    );
};