import React, {FC, useState} from 'react';
import {IconButton, Tooltip, useTheme} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from "@mui/material/Button";

interface ButtonLinkWithCopyProps {
    url: string;
    text: string;
    disabled: boolean;
    helpText:string;
}

const ButtonLinkWithCopy: FC<ButtonLinkWithCopyProps> = ({url, text, disabled,helpText}) => {
    const [copied, setCopied] = useState(false);
    const theme = useTheme();

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(url);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Error al copiar:', err);
        }
    };

    return (
        <Button variant="outlined"
                sx={{maxHeight:'35px'}}
                disabled={disabled}
                endIcon={ <Tooltip title={copied ? '¡Copiado!' : 'Copiar enlace' + ` (${helpText })`}>
                    <IconButton onClick={handleCopy}>
                        <ContentCopyIcon
                            sx={{ color: copied ? theme.palette.success.light : 'inherit' }}
                        />
                    </IconButton>
                </Tooltip>}
                color="primary"
                onClick={() => handleCopy()}>
            {text}
        </Button>
    );
};

export default ButtonLinkWithCopy;
