import LegacyQuotationHeaderPreview
    from "../../../commons/components/LegacyQuotationHeaderPreview/LegacyQuotationHeaderPreview";
import backgroundImage from "../../../../../../assets/images/quotation_header_bg.png"; // Importa la imagen de fondo
import logo from "../../../../../../assets/images/logo_new.svg";
import React from "react";
import Box from "@mui/material/Box";
import QuotationTitlePreview from "../../../commons/components/QuotationTitlePreview/QuotationTitlePreview";
import {IListAllLegacyQuotationWhatInclude} from "../../../legacy-quotation-what-includes/types";
import WhatIncludePreviewPreview from "../../../commons/components/WhatIncludePreview/WhatIncludePreview";
import LegacyQuotationFlightItineraryPreview
    from "../../../commons/components/LegacyQuotationFlightItineraryPreview/LegacyQuotationFlightItineraryPreview";
import ExclusiveServicePreview from "../ExclusiveServicePreview/ExclusiveServicePreview";
import SellerFooterPreview from "../../../commons/components/SellerFooterPreview/SellerFooterPreview";
import GeneralConditionsPreview from "../../../commons/components/GeneralConditionsPreview/GeneralConditionsPreview";
import {IFlightItinerarySegmentInformation} from "../../../legacy-quotation-packages/types";
import {IListLegacyQuotationExclusiveService} from "../../../legacy-quotation-exclusive-services/types";
import ObservationsPreview from "../../../commons/components/ObservationsPreview/ObservationsPreview";
import LegacyQuotationPackageOptionsPreview
    from "../LegacyQuotationPackageOptionsPreview/LegacyQuotationPackageOptionsPreview";
import {ILegacyQuotationPackageAdditionalOptionPreview, ILegacyQuotationPackageOptionPreview} from "../../types";
import PaymentMediaPreview from "../../../commons/components/PaymentMediaPreview/PaymentMediaPreview";
import {IUserProfile} from "../../../../../users/types";
import CompanyFooterPreview from "../../../commons/components/CompanyFooterPreview/CompanyFooterPreview";
import {containsVisibleText} from "../../../legacy-quotation-packages/helper";
import {IListAllLegacyQuotationNoInclude} from "../../../legacy-quotation-no-includes/types";
import NoIncludePreview from "../../../commons/components/NoIncludePreview/NoIncludePreview";
import LegacyQuotationPackageAdditionalOptionsPreview
    from "../LegacyQuotationPackageAdditionalOptionsPreview/LegacyQuotationPackageAdditionalOptionsPreview";

export interface LegacyQuotationPackagePreviewProps {
    quotationNumber: number | null;
    quotationTitle: string | null;
    quotationImage: string | null;
    whatIncludes: IListAllLegacyQuotationWhatInclude[];
    noIncludes: IListAllLegacyQuotationNoInclude[];
    flightItinerarySegments: IFlightItinerarySegmentInformation[];
    options: ILegacyQuotationPackageOptionPreview[];
    additionalOptions: ILegacyQuotationPackageAdditionalOptionPreview[],
    exclusiveServices: IListLegacyQuotationExclusiveService[];
    generalConditions: string | null;
    observations: string | null;
    sellerProfile: IUserProfile;

}

export const LegacyQuotationPackagePreview: React.FC<LegacyQuotationPackagePreviewProps> = ({
                                                                                                quotationNumber,
                                                                                                quotationTitle,
                                                                                                quotationImage,
                                                                                                whatIncludes,
                                                                                                noIncludes,
                                                                                                flightItinerarySegments,
                                                                                                options,
                                                                                                additionalOptions,
                                                                                                exclusiveServices,
                                                                                                generalConditions,
                                                                                                observations,
                                                                                                sellerProfile
                                                                                            }) => {


    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                //  justifyContent: "center",
                minHeight: "100vh",
                mt: 0,
                pt: 0,
                mb: 2
                // padding: "20px",
                //    backgroundColor: "#f5f5f5", // Color de fondo opcional
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "1200px", // Ajusta el ancho máximo
                    // backgroundColor: "white",
                    boxShadow: 7,
                    mt: 2,
                    borderRadius: "8px",
                }}
            >
                <LegacyQuotationHeaderPreview
                    backgroundImage={backgroundImage}
                    logo={logo}
                    quoteNumber={quotationNumber ? quotationNumber + '' : '00000'}
                />

                <QuotationTitlePreview title={quotationTitle} image={quotationImage}/>
                <WhatIncludePreviewPreview items={whatIncludes}/>
                {flightItinerarySegments && flightItinerarySegments.length > 0 &&
                    <LegacyQuotationFlightItineraryPreview segments={flightItinerarySegments}/>}
                <LegacyQuotationPackageOptionsPreview options={options}/>
                {additionalOptions.length > 0 &&
                    <LegacyQuotationPackageAdditionalOptionsPreview options={additionalOptions}/>}
                {noIncludes.length > 0 && <NoIncludePreview items={noIncludes}/>}
                <ExclusiveServicePreview items={exclusiveServices}/>
                {observations && containsVisibleText(observations || '') &&
                    <ObservationsPreview observations={observations}/>}
                <PaymentMediaPreview/>
                <GeneralConditionsPreview htmlContent={generalConditions}/>
                <SellerFooterPreview seller={sellerProfile}/>
                <CompanyFooterPreview/>

            </Box>
        </Box>
    );
};