import {AxiosResponse} from "axios";
import {IPaginatedResult, ISort} from "../../../../core/types";
import {formatSortingFields} from "../../../../core/helper";
import {ApiService} from "../../../../core/services";
import {
    IDetailLegacyQuotationAdditional,
    ILegacyQuotationAdditional,
    IListAllLegacyQuotationAdditional,
    IListLegacyQuotationAdditional
} from "./types";

const endpoints = {
    list: 'api/legacy-quotation-additionals',
    listByDestinations: '/api/legacy-quotation-additionals/list-by-destinations',
    get: 'api/legacy-quotation-additionals/:id',
    create: 'api/legacy-quotation-additionals',
    update: 'api/legacy-quotation-additionals/:id',
    delete: 'api/legacy-quotation-additionals/:id',
}

export const getAdditionals = (page = 1, pageSize = 10, sortingFields: ISort[], search: string): Promise<AxiosResponse<IPaginatedResult<IListLegacyQuotationAdditional>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(search && search !== '' && {search: search})
        }
    });
};

export const getAdditional = (id: string): Promise<AxiosResponse<IDetailLegacyQuotationAdditional>> => ApiService.get(endpoints.get.replace(':id', id));
export const createAdditional = (data: ILegacyQuotationAdditional): Promise<AxiosResponse<{
    id: string
}>> => ApiService.post(endpoints.create, data);
export const updateAdditional = (id: string, data: ILegacyQuotationAdditional): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':id', id), data);
export const deleteAdditional = (id: string): Promise<AxiosResponse<void>> => ApiService.delete(endpoints.delete.replace(':id', id));

export const getAllAdditionalsByDestinations = (destinationCodes: string[]): Promise<AxiosResponse<Record<string, IListAllLegacyQuotationAdditional[]>>> => ApiService.post(endpoints.listByDestinations, {destinationCodes: destinationCodes});

