import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';

import {
    Autocomplete,
    AvatarGroup,
    Box,
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Popover,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import {FilterList as FilterListIcon, Search as SearchIcon} from '@mui/icons-material';
import {IAppFilterList, ISort} from "../../../../core/types";
import {CardPortfolioStatus, IFilterCardPortfolio, IListCardPortfolio} from "../../types";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {DatePicker} from "@mui/x-date-pickers";
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';
import CustomFilterList from "../../../../core/components/CustomFilterList/CustomFilterList";
import Avatar from "@mui/material/Avatar";

import 'dayjs/locale/es';
import {IDetailAirline} from "../../../airlines/types";
import CardPortfolioStatusBadge, {statusDetails} from "../CardPortfolioStatusBadge/CardPortfolioStatusBadge";
import ScrollableTableContainer from "../../../../core/components/ScrollableTableContainer/ScrollableTableContainer";
import EmptyTable from "../../../../core/components/EmptyTable/EmptyTable";
import IssueRequirementsCell
    from "../../../sale-order-issue-requirements/components/issue-requirements-cell/IssueRequirementsCell";
import {IListIssueRequirement} from "../../../sale-order-issue-requirements/types";
// Configurar dayjs para usar el plugin relativeTime
dayjs.extend(relativeTime);
dayjs.locale('es');

interface CardPortfolioListProps {
    title: string;
    search: string;
    setSearch: (search: string) => void;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    filters: IFilterCardPortfolio;
    setFilters: (filter: IFilterCardPortfolio) => void;
    order: ISort[],
    setOrder: Dispatch<SetStateAction<ISort[]>>;
    items: IListCardPortfolio[];
    totalElements: number;
    onDetail: (id: number) => void;
    customFilters: IAppFilterList<IFilterCardPortfolio>[];
    activeCustomFilterId: number | null;
    onApplyCustomFilter: (filter: IAppFilterList<IFilterCardPortfolio> | null) => void;
    onDeleteCustomFilter: (id: number) => void;
    showButtonToSaveCustomFilter: boolean;
    onClickSaveCustomFilter: () => void;
    airlines: IDetailAirline[],
    issueRequirements:IListIssueRequirement[]
}

const CardsPortfolioList: React.FC<CardPortfolioListProps> = ({
                                                                  title,
                                                                  search,
                                                                  setSearch,
                                                                  page,
                                                                  setPage,
                                                                  pageSize,
                                                                  totalElements,
                                                                  setPageSize,
                                                                  filters,
                                                                  setFilters,
                                                                  order,
                                                                  setOrder,
                                                                  items,
                                                                  onDetail,
                                                                  customFilters,
                                                                  onApplyCustomFilter,
                                                                  onDeleteCustomFilter,
                                                                  activeCustomFilterId,
                                                                  showButtonToSaveCustomFilter,
                                                                  onClickSaveCustomFilter,
                                                                  airlines,
                                                                  issueRequirements
                                                              }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [internalFilters, setInternalFilters] = useState<IFilterCardPortfolio>(filters);


    const [departureDate, setDepartureDate] = useState<Dayjs | null>(filters.departureDate?dayjs(filters.departureDate):null);
    const [departureDateFrom, setDepartureDateFrom] = useState<Dayjs | null>(filters.departureDateFrom?dayjs(filters.departureDateFrom):null);
    const [departureDateTo, setDepartureDateTo] = useState<Dayjs | null>(filters.departureDateTo?dayjs(filters.departureDateTo):null);

    const [createdAt, setCreatedAt] = useState<Dayjs | null>(filters.createdAt?dayjs(filters.createdAt):null);
    const [createdAtFrom, setCreatedAtFrom] = useState<Dayjs | null>(filters.createdAtFrom?dayjs(filters.createdAtFrom):null);
    const [createdAtTo, setCreatedAtTo] = useState<Dayjs | null>(filters.createdAtTo?dayjs(filters.createdAtTo):null);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleFilterChange = (field: keyof typeof filters) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalFilters((prev) => ({...prev, [field]: event.target.value}));
    };

    const handleFilterChangeValue = (field: keyof typeof filters, value: string | number) => {
        setInternalFilters((prev) => ({...prev, [field]: value}));
    };

    useEffect(() => {
        setInternalFilters(filters);
    }, [filters]);

    const handleChangeToUseSameIssue = (event: SelectChangeEvent<unknown>) => {
        if (event.target.value !== undefined) {
            setInternalFilters((prev) => ({...prev, toUseInSameIssue: event.target.value == 'true'}));
        } else {
            setInternalFilters((prev) => ({...prev, toUseInSameIssue: undefined}));
        }

    };

    const handleChangeHasRejectActive = (event: SelectChangeEvent<unknown>) => {
        if (event.target.value !== undefined) {
            setInternalFilters((prev) => ({...prev, hasActiveRejection: event.target.value == 'true'}));
        } else {
            setInternalFilters((prev) => ({...prev, hasActiveRejection: undefined}));
        }

    };

    const handleChangeHasRequirements = (event: SelectChangeEvent<unknown>) => {
        if (event.target.value !== undefined) {
            setInternalFilters((prev) => ({...prev, hasIssueRequirements: event.target.value == 'true'}));
        } else {
            setInternalFilters((prev) => ({...prev, hasIssueRequirements: undefined}));
        }

    };

    const handleChangeShowFinalized = (event: SelectChangeEvent<unknown>) => {
        if (event.target.value !== undefined) {
            setInternalFilters((prev) => ({...prev, showFinalized: event.target.value == 'true'}));
        } else {
            setInternalFilters((prev) => ({...prev, showFinalized: undefined}));
        }

    };

    const handleChangeStatus = (event: SelectChangeEvent<unknown>) => {
        if (event.target.value !== undefined) {
            setInternalFilters((prev) => ({...prev, status: event.target.value as string }));
        } else {
            setInternalFilters((prev) => ({...prev, status: undefined}));
        }

    };

    const handleApplyFilter = () => {
        setFilters(internalFilters);
        handleCloseMenu();
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property: keyof IListCardPortfolio) => {
        const exist = order.find(o => o.field === property)
        const isAsc = order.find(o => o.field === property)?.order === 'asc';
        if (!exist) {
            setOrder(prevOrder => [
                ...prevOrder.filter(o => o.field !== property),
                {field: property, order: isAsc ? 'desc' : 'asc'},
            ]);
        } else {
            if (exist.order === 'asc') {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                    {field: property, order: 'desc'},
                ]);
            } else {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                ]);
            }
        }

    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCancelFilters = () => {
        setInternalFilters(filters);
        handleCloseMenu();
    };

    return (
        <Box sx={{width: '100%', padding: 3}}>
            <Typography variant="h4" component="div" sx={{mb: 2}}>
                {title}
            </Typography>
            <Toolbar sx={{display: 'flex', justifyContent: 'flex-end', mb: 2}} style={{paddingLeft: 0}}>

                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <TextField
                        variant="outlined"
                        placeholder="Buscar"
                        size="small"
                        value={search}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        sx={{mr: 2, width: 300}}
                    />
                    <Button variant="outlined" startIcon={<FilterListIcon/>} onClick={handleOpenMenu} sx={{mr:2}}>
                        Filtrar
                    </Button>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        disableRestoreFocus
                    >
                        <Box sx={{p: 2, display: 'flex', flexDirection: 'column', gap: 1, minWidth:430}}>
                            <TextField
                                label="ID igual a"
                                size='small'
                                value={internalFilters.id}
                                onChange={handleFilterChange('id')}
                                fullWidth
                            />
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="status-select-label">Estado</InputLabel>
                                <Select
                                    labelId="status-select-label"
                                    id="status-select"
                                    size='small'
                                    value={internalFilters.status}
                                    label="Estado"
                                    onChange={handleChangeStatus}
                                    renderValue={(selected) => {
                                        if(selected) {
                                            const detail = statusDetails[selected as CardPortfolioStatus];

                                            return (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        backgroundColor: detail.color,
                                                        color: '#fff',
                                                        padding: '4px 8px',
                                                        borderRadius: '8px',
                                                    }}
                                                >
                                                    <Typography variant="body1">{detail.label}</Typography>
                                                </Box>
                                            );
                                        } else {
                                            return (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        color: '#fff',
                                                        padding: '4px 8px',
                                                        borderRadius: '8px',
                                                    }}
                                                >
                                                    <Typography variant="body1"></Typography>
                                                </Box>
                                            );
                                        }

                                    }}
                                >
                                    <MenuItem value={undefined} style={{minHeight: '24px'}}>
                                        <em></em>
                                    </MenuItem>
                                    {Object.values(CardPortfolioStatus).map((status) => {
                                        const detail = statusDetails[status];
                                        return (
                                            <MenuItem key={status} value={status}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        backgroundColor: detail.color,
                                                        color: '#fff',
                                                        width: '100%',
                                                        padding: '8px 16px',
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    {detail.label}
                                                </Box>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel id="has-requirements-label">Tiene requerimientos</InputLabel>
                                <Select
                                    size='small'
                                    labelId="has-requirements-select-label"
                                    id="has-requirements-select"
                                    value={internalFilters.hasIssueRequirements}
                                    label="Tiene requerimientos"
                                    onChange={handleChangeHasRequirements}
                                >
                                    <MenuItem value={undefined} style={{minHeight: '24px'}}>
                                        <em></em>
                                    </MenuItem>
                                    <MenuItem value={'true'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="Sí"/>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value={'false'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="No"/>
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl sx={{width: '100%'}}>
                                <Autocomplete
                                    options={issueRequirements}
                                    getOptionLabel={(option: IListIssueRequirement) => `${option.name}`}
                                    value={issueRequirements.find((option) => option.id === internalFilters.issueRequirementId) || null}
                                    onChange={(event, value) => {
                                        const selectedValue = (value as IListIssueRequirement)?.id || '';
                                        handleFilterChangeValue('issueRequirementId', selectedValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            label="Requerimiento igual a"
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormControl>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.amountFrom}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('amountFrom', value);
                                }}
                                label="Monto mayor o igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />

                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.amountTo}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('amountTo', value);
                                }}
                                label="Monto menor o igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.amount}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('amount', value);
                                }}
                                label="Monto igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />

                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.totalPendingAuthorizationFrom}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('totalPendingAuthorizationFrom', value);
                                }}
                                label="Total pendiente de autorizar mayor o igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />

                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.totalPendingAuthorizationTo}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('totalPendingAuthorizationTo', value);
                                }}
                                label="Total pendiente de autorizar menor o igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.totalPendingAuthorization}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('totalPendingAuthorization', value);
                                }}
                                label="Total pendiente de autorizar  igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />


                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.totalPreauthorizedFrom}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('totalPreauthorizedFrom', value);
                                }}
                                label="Total preautorizado mayor o igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />

                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.totalPreauthorizedTo}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('totalPreauthorizedTo', value);
                                }}
                                label="Total preautorizado menor o igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.totalPreauthorized}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('totalPreauthorized', value);
                                }}
                                label="Total preautorizado igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />



                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.preauthorizedFrom}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('preauthorizedFrom', value);
                                }}
                                label="Monto preautorizado mayor o igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />

                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.preauthorizedTo}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('preauthorizedTo', value);
                                }}
                                label="Monto preautorizado menor o igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.preauthorized}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('preauthorized', value);
                                }}
                                label="Monto preautorizado igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />

                            <FormControl sx={{width: '100%'}}>
                                <Autocomplete
                                    options={airlines}
                                    getOptionLabel={(option: IDetailAirline) => `${option.code} - ${option.name}`}
                                    value={airlines.find((option) => option.code === internalFilters.preauthorizedWithAirlineCode) || null}
                                    onChange={(event, value) => {
                                        const selectedValue = (value as IDetailAirline)?.code || '';
                                        handleFilterChangeValue('preauthorizedWithAirlineCode', selectedValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            label="Compañía aérea de la preautorización igual a"
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormControl>

                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Cantidad de cuotas incluye"
                                value={internalFilters.fee}
                                placeholder="Cantidad de cuotas incluye"
                                size="small"
                                type="text"
                                onChange={(e) => {
                                    //const value = e.target.value.replace(/\D/g, ''); // Elimina todo lo que no sea un número
                                    handleFilterChangeValue('fee', e.target.value);
                                }}
                            />
                            <FormHelperText>Ejemplo: 6,10,12</FormHelperText>
                            <TextField
                                label="Número de la negociación igual a"
                                size='small'
                                value={internalFilters.dealId}
                                onChange={handleFilterChange('dealId')}
                                fullWidth
                            />
                            <TextField
                                label="Número de file igual a"
                                size='small'
                                value={internalFilters.fileNumber}
                                onChange={handleFilterChange('fileNumber')}
                                fullWidth
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column'}}>
                                    <DatePicker
                                        label="Fecha de viaje igual a"
                                        format="YYYY-MM-DD"
                                        value={departureDate? dayjs(departureDate) : null}
                                        onChange={(newValue) => {
                                            setDepartureDate(newValue);
                                            handleFilterChangeValue('departureDate', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                </Box>
                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column'}}>
                                    <DatePicker
                                        label="Fecha de viaje desde"
                                        format="YYYY-MM-DD"
                                        value={departureDateFrom ? dayjs(departureDateFrom) : null}
                                        onChange={(newValue) => {
                                            setDepartureDateFrom(newValue);
                                            handleFilterChangeValue('departureDateFrom', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                    <DatePicker
                                        label="Fecha de viaje hasta"
                                        format="YYYY-MM-DD"
                                        value={departureDateTo ? dayjs(departureDateTo) : null}
                                        onChange={(newValue) => {
                                            setDepartureDateTo(newValue);
                                            handleFilterChangeValue('departureDateTo', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        minDate={departureDateFrom ? dayjs(departureDateFrom) : undefined}  // No permite seleccionar una fecha menor a la de inicio
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                </Box>


                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column'}}>
                                    <DatePicker
                                        label="Fecha de entrada en cartera igual a"
                                        format="YYYY-MM-DD"
                                        value={createdAt? dayjs(createdAt) : null}
                                        onChange={(newValue) => {
                                            setCreatedAt(newValue);
                                            handleFilterChangeValue('createdAt', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                </Box>

                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column'}}>
                                    <DatePicker
                                        label="Fecha de entrada en cartera desde"
                                        format="YYYY-MM-DD"
                                        value={createdAtFrom ? dayjs(createdAtFrom) : null}
                                        onChange={(newValue) => {
                                            setCreatedAtFrom(newValue);
                                            handleFilterChangeValue('createdAtFrom', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                    <DatePicker
                                        label="Fecha de entrada en cartera hasta"
                                        format="YYYY-MM-DD"
                                        value={createdAtTo ? dayjs(createdAtTo) : null}
                                        onChange={(newValue) => {
                                            setCreatedAtTo(newValue);
                                            handleFilterChangeValue('createdAtTo', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        minDate={createdAtFrom ? dayjs(createdAtFrom) : undefined}  // No permite seleccionar una fecha menor a la de inicio
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                </Box>
                            </LocalizationProvider>
                            <FormControl fullWidth>
                                <InputLabel id="to-use-same-issue-label">Para usar en la misma emisión</InputLabel>
                                <Select
                                    size='small'
                                    labelId="to-use-same-issue-select-label"
                                    id="o-use-same-issue-select-label"
                                    value={internalFilters.toUseInSameIssue}
                                    label="Para usar en la misma emisión"
                                    onChange={handleChangeToUseSameIssue}
                                >
                                    <MenuItem value={undefined} style={{minHeight: '24px'}}>
                                        <em></em>
                                    </MenuItem>
                                    <MenuItem value={'true'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="Sí"/>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value={'false'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="No"/>
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel id="reject-active-label">Tiene rechazo activo (Sin resolver)</InputLabel>
                                <Select
                                    size='small'
                                    labelId="reject-active-select-label"
                                    id="reject-active-select"
                                    value={internalFilters.hasActiveRejection}
                                    label="Tiene rechazo activo (Sin resolver)"
                                    onChange={handleChangeHasRejectActive}
                                >
                                    <MenuItem value={undefined} style={{minHeight: '24px'}}>
                                        <em></em>
                                    </MenuItem>
                                    <MenuItem value={'true'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="Sí"/>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value={'false'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="No"/>
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                label="Titular contiene"
                                size='small'
                                value={internalFilters.cardHolder}
                                onChange={handleFilterChange('cardHolder')}
                                fullWidth
                            />
                            <TextField
                                label="Sello contiene"
                                size='small'
                                value={internalFilters.cardStamp}
                                onChange={handleFilterChange('cardStamp')}
                                fullWidth
                            />
                            <TextField
                                label="Últimos 4 digitos igual a "
                                size='small'
                                type={"number"}
                                value={internalFilters.lastFourCardDigit}
                                onChange={handleFilterChange('lastFourCardDigit')}
                                fullWidth
                            />
                            <TextField
                                label="Vendedor contiene"
                                size='small'
                                value={internalFilters.sellerName}
                                onChange={handleFilterChange('sellerName')}
                                fullWidth
                            />



                            <FormControl fullWidth>
                                <InputLabel id="show-finalized-label">Mostrar fnalizadas</InputLabel>
                                <Select
                                    size='small'
                                    labelId="show-finalized-select-label"
                                    id="show-finalized-select"
                                    value={internalFilters.showFinalized}
                                    label="Mostrar fnalizadas"
                                    onChange={handleChangeShowFinalized}
                                >
                                    <MenuItem value={undefined} style={{minHeight: '24px'}}>
                                        <em></em>
                                    </MenuItem>
                                    <MenuItem value={'false'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="No"/>
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                                <Button variant="contained" onClick={handleApplyFilter} sx={{mr: 2}}>
                                    Aplicar Filtros
                                </Button>
                                <Button variant="outlined" onClick={handleCancelFilters}>
                                    Cancelar
                                </Button>
                            </Box>
                        </Box>
                    </Popover>
                    {showButtonToSaveCustomFilter &&
                        <Button variant="outlined" startIcon={<SaveIcon/>} onClick={onClickSaveCustomFilter}
                                title={'Guardar filtro aplicado'}>
                            Guardar filtro aplicado
                        </Button>}
                </Box>
            </Toolbar>
            <CustomFilterList filters={customFilters} onApplyCustomFilter={onApplyCustomFilter}
                              onDeleteCustomFilter={onDeleteCustomFilter} activeFilterId={activeCustomFilterId}/>
            <Typography variant="body2" sx={{mb: 1}}>
                Mostrando {items.length} resultados de {totalElements}
            </Typography>

            <TableContainer component="main" sx={{width: '100%'}}>
                <ScrollableTableContainer>
                <Table sx={{width: '100%'}} >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                ID
                            </TableCell>

                            <TableCell>Acciones</TableCell>

                            <TableCell>
                                Estado
                            </TableCell>

                            <TableCell>
                                Requerimientos
                            </TableCell>


                            <TableCell sx={{width: '10%'}}>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'amount')}
                                    direction={order.find(o => o.field === 'amount')?.order || 'asc'}
                                    onClick={() => handleRequestSort('amount')}
                                >
                                    Monto
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'fee')}
                                    direction={order.find(o => o.field === 'fee')?.order || 'asc'}
                                    onClick={() => handleRequestSort('fee')}
                                >
                                    Cuotas
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'dealId')}
                                    direction={order.find(o => o.field === 'dealId')?.order || 'asc'}
                                    onClick={() => handleRequestSort('dealId')}
                                >
                                    Negociación
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'fileNumber')}
                                    direction={order.find(o => o.field === 'fileNumber')?.order || 'asc'}
                                    onClick={() => handleRequestSort('fileNumber')}
                                >
                                    File
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'totalPendingAuthorization')}
                                    direction={order.find(o => o.field === 'totalPendingAuthorization')?.order || 'asc'}
                                    onClick={() => handleRequestSort('totalPendingAuthorization')}
                                >
                                    Total pendiente de autorización
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'totalPreauthorized')}
                                    direction={order.find(o => o.field === 'totalPreauthorized')?.order || 'asc'}
                                    onClick={() => handleRequestSort('totalPreauthorized')}
                                >
                                    Total preautorizado
                                </TableSortLabel>
                            </TableCell>


                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'departureDate')}
                                    direction={order.find(o => o.field === 'departureDate')?.order || 'asc'}
                                    onClick={() => handleRequestSort('departureDate')}
                                >
                                    Fecha de viaje
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'toUseInSameIssue')}
                                    direction={order.find(o => o.field === 'toUseInSameIssue')?.order || 'asc'}
                                    onClick={() => handleRequestSort('toUseInSameIssue')}
                                >
                                    Usar en la misma emisión
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'cardHolder')}
                                    direction={order.find(o => o.field === 'cardHolder')?.order || 'asc'}
                                    onClick={() => handleRequestSort('cardHolder')}
                                >
                                    Titular
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'cardStamp')}
                                    direction={order.find(o => o.field === 'cardStamp')?.order || 'asc'}
                                    onClick={() => handleRequestSort('cardStamp')}
                                >
                                    Sello de la tarjeta
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'lastFourCardDigit')}
                                    direction={order.find(o => o.field === 'lastFourCardDigit')?.order || 'asc'}
                                    onClick={() => handleRequestSort('lastFourCardDigit')}
                                >
                                    Últimos 4 digitos
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'sellerName')}
                                    direction={order.find(o => o.field === 'sellerName')?.order || 'asc'}
                                    onClick={() => handleRequestSort('sellerName')}
                                >
                                    Vendedor
                                </TableSortLabel>
                            </TableCell>



                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'createdAt')}
                                    direction={order.find(o => o.field === 'createdAt')?.order || 'asc'}
                                    onClick={() => handleRequestSort('createdAt')}
                                >
                                    Fecha de entrada en cartera
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>Usuario usando la tarjeta</TableCell>
                            <TableCell>
                                ID de orden de venta
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.id}</TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center" justifyContent="flex-start">
                                        <IconButton title={'Ver detalle'} onClick={() => onDetail(item.id)}>
                                            <VisibilityIcon/>
                                        </IconButton>
                                    </Box>
                                </TableCell>

                                <TableCell><CardPortfolioStatusBadge status={item.status}/></TableCell>
                                <IssueRequirementsCell issueRequirements={item.issueRequirements} />
                                <TableCell>{item.amount} USD</TableCell>
                                <TableCell>{item.fee}</TableCell>
                                <TableCell> <a title={'Abrir en Bitrix'}
                                               href={`https://traveller.bitrix24.es/crm/deal/details/${item.dealId}/`}
                                               target="_blank"
                                               rel="noopener noreferrer"
                                >
                                    {item.dealId}
                                </a></TableCell>
                                <TableCell>{item.fileNumber}</TableCell>
                                <TableCell>{item.totalPendingAuthorization} USD</TableCell>
                                <TableCell>{item.totalPreauthorized} USD</TableCell>
                                <TableCell>{item.departureDate}</TableCell>
                                <TableCell>{item.toUseInSameIssue ? 'Si' : 'No'}</TableCell>
                                <TableCell>{item.cardHolder}</TableCell>
                                <TableCell>{item.cardStamp}</TableCell>
                                <TableCell>{item.lastFourCardDigit}</TableCell>
                                <TableCell>{item.sellerName}</TableCell>
                                <TableCell>{item.createdAt}</TableCell>

                                <TableCell>

                                    <AvatarGroup max={1}>
                                        {item.views &&
                                            item.views.map((view) => {
                                                const initial = view.name ? view.name.charAt(0).toUpperCase() : '?';
                                                const connectedSince = dayjs(view.connectedSince).fromNow(); // Ejemplo: 'hace 5 minutos'

                                                return (
                                                    <Tooltip
                                                        key={view.userId}
                                                        title={`${view.name} (usando la tarjeta ${connectedSince})`}
                                                        arrow
                                                        enterDelay={500}
                                                    >
                                                        <Avatar>
                                                            {initial}
                                                        </Avatar>
                                                    </Tooltip>
                                                );
                                            })}
                                    </AvatarGroup>
                                </TableCell>
                                <TableCell>{item.orderId}</TableCell>
                            </TableRow>
                        ))}
                        {items.length == 0 && <EmptyTable colSpan={18} />}
                    </TableBody>
                </Table>
      </ScrollableTableContainer>
            </TableContainer>
            <TablePagination
                labelRowsPerPage={'Filas por página'}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalElements}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
};

export default CardsPortfolioList;
