import React, {useEffect, useState} from 'react';
import {IListAllLegacyQuotationWhatInclude} from "../../../legacy-quotation-what-includes/types";
import {IFlightItinerarySegmentInformation} from "../../../legacy-quotation-packages/types";
import {IListLegacyQuotationAdditional} from "../../../legacy-quotations-additionals/types";
import {useParams} from "react-router-dom";
import {getGeneralConditions} from "../../../legacy-quotation-general-conditions/services";
import {extractErrorMessage, is404Error} from "../../../../../../core/helper";
import {ShowAlertState} from "../../../../../../core/types";
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import {CookieService} from "../../../../../auth/services/CookieService";
import {LegacyQuotationFlightPreview} from "../../components/LegacyQuotationFlightPreview/LegacyQuotationFlightPreview";
import {ILegacyQuotationFlightTariffOptionPreview} from "../../types";
import {IListAllLegacyQuotationNoInclude} from "../../../legacy-quotation-no-includes/types";

export const LegacyQuotationFlightSellerPreviewContainer: React.FC = (props) => {

    const theme = useTheme();
    const {id} = useParams<{ id: string }>();
    const [quotationNumber, setQuotationNumber] = useState<number | null>(null);
    const [quotationTitle, setQuotationTitle] = useState<string | null>(null);
    const [whatIncludes, setWhatIncludes] = useState<IListAllLegacyQuotationWhatInclude[]>([]);
    const [noIncludes, setNoIncludes] = useState<IListAllLegacyQuotationNoInclude[]>([]);
    const [flightItinerarySegments, setFlightItinerarySegments] = useState<IFlightItinerarySegmentInformation[]>([]);
    const [options, setOptions] = useState<ILegacyQuotationFlightTariffOptionPreview[]>([]);
    const [generalConditions, setGeneralConditions] = useState<string | null>(null);
    const [observations, setObservations] = useState<string | null>(null);
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);

    const handleLoadGeneralsConditions = async () => {
        try {
            setLoading(true);
            const result = await getGeneralConditions();
            setLoading(false);
            setGeneralConditions(result.data.content);
        } catch (error) {
            if (!is404Error(error)) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al  cargar las Condiciones Generales')
                });
            }

            setLoading(false);
        }
    }

    useEffect(() => {
        handleLoadGeneralsConditions();
    }, []);


    useEffect(() => {
        const channel = new BroadcastChannel("quotation_preview_channel_" + (id || ''));

        const handleMessage = (event: MessageEvent) => {
            if (event.data.quotationTitle || event.data.quotationTitle == '') {
                setQuotationTitle(event.data.quotationTitle)
            }

            if (Array.isArray(event.data.whatIncludes)) {
                setWhatIncludes(event.data.whatIncludes)
            }

            if (Array.isArray(event.data.noIncludes)) {
                setNoIncludes(event.data.noIncludes)
            }
            if (Array.isArray(event.data.flightItinerarySegments)) {
                setFlightItinerarySegments(event.data.flightItinerarySegments)
            }
            if (Array.isArray(event.data.options)) {
                setOptions(event.data.options)
            }
            if (event.data.observations || event.data.observations == '') {
                setObservations(event.data.observations)
            }

            if (event.data.quotationNumber) {
                setQuotationNumber(event.data.quotationNumber);
            }
        };

        channel.addEventListener("message", handleMessage);
        return () => {
            channel.removeEventListener("message", handleMessage);
            channel.close();
        };
    }, []);

    return (<>
            <Box sx={{width: '80%', padding: 3, pt: 0}}><Backdrop sx={{
                zIndex: theme.zIndex.modal + 1,
                color: '#fff',

            }} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop></Box>
            <LegacyQuotationFlightPreview quotationNumber={quotationNumber} quotationTitle={quotationTitle}
                                          whatIncludes={whatIncludes}
                                          noIncludes={noIncludes}
                                          flightItinerarySegments={flightItinerarySegments}
                                          options={options}
                                          sellerProfile={CookieService.getProfileData()}
                                          generalConditions={generalConditions} observations={observations}/>
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>

        </>
    );
}