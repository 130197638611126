import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import {Controller, FormProvider, useForm, useWatch} from 'react-hook-form';
import {useNavigate} from "react-router-dom";
import {appRoutes} from "../../../../../../core/const";
import {
    ILegacyQuotationFlightFormValue,
    ILegacyQuotationFlightTariffOptionUpsert,
    ILegacyQuotationFlightUpsert
} from "../../types";
import {
    IAutocompleteResponseIListLegacyQuotationWhatInclude,
    IListAllLegacyQuotationWhatInclude
} from "../../../legacy-quotation-what-includes/types";
import {WhatIncludeSelector} from "../../../commons/components/WhatIncludeSelector/WhatIncludeSelector";
import {AddCircle} from "@mui/icons-material";
import {
    IFlightItinerarySegmentInformation,
    ILegacyQuotationPackageFlightUpsert
} from "../../../legacy-quotation-packages/types";
import LegacyQuotationFlightTariffOptionsForm
    from "../LegacyQuotationFlightTariffOptionsForm/LegacyQuotationFlightTariffOptionsForm";
import ReactQuill from "react-quill";
import {toolbarOptionsForObservationsInQuotation} from "../../../legacy-quotation-packages/helper";
import {flightTypeRTId, flightTypesWithName} from "../../constants";
import {
    IListAllLegacyQuotationFlightWhatIncludeTariffWithCategory
} from "../../../legacy-quotation-flight-what-include-tariff/types";
import {convertOptionsFromFormToOptionsToPreview} from "../../helpers";
import {NoIncludeSelector} from "../../../commons/components/NoIncludeSelector/NoIncludeSelector";
import {
    IAutocompleteResponseIListLegacyQuotationNoInclude,
    IListAllLegacyQuotationNoInclude
} from "../../../legacy-quotation-no-includes/types";
import {FlightItineraryView} from "../../../commons/components/FlightItineraryView/FlightItineraryView";
import ButtonLinkWithCopy from "../../../commons/components/ButtonLinkWithCopy/ButtonLinkWithCopy";

export interface LegacyQuotationFlightUpsertFormProps {
    whatIncludesContainer: IAutocompleteResponseIListLegacyQuotationWhatInclude,
    noIncludesContainer: IAutocompleteResponseIListLegacyQuotationNoInclude,
    whatIncludesForTariffWithCategory: IListAllLegacyQuotationFlightWhatIncludeTariffWithCategory[],
    handleSubmitForm: (value: ILegacyQuotationFlightUpsert) => void;
    fetchItinerarySegmentsByPNR: (pnr: string) => Promise<IFlightItinerarySegmentInformation[]>,
    refreshWhatIncludes: () => Promise<void>,
    refreshNoIncludes: () => Promise<void>,
    refreshWhatIncludesForTariff: () => Promise<void>,
    loading: boolean;
    id: string;
    value?: ILegacyQuotationFlightUpsert,
    linkToClient?:string
}

export const LegacyQuotationFlightUpsertForm: React.FC<LegacyQuotationFlightUpsertFormProps> = ({
                                                                                                    whatIncludesContainer,
                                                                                                    noIncludesContainer,
                                                                                                    whatIncludesForTariffWithCategory,
                                                                                                    handleSubmitForm,
                                                                                                    fetchItinerarySegmentsByPNR,
                                                                                                    refreshWhatIncludes,
                                                                                                    refreshNoIncludes,
                                                                                                    refreshWhatIncludesForTariff,
                                                                                                    loading,
                                                                                                    id,
                                                                                                    value,
                                                                                                    linkToClient
                                                                                                }) => {

    const methods = useForm<ILegacyQuotationFlightFormValue>({
        mode: 'all',
        defaultValues: {
            title: value?.title || '',
            reference: value?.reference || '',
            pnr: value?.flight.pnr || '',
            hasMinors: value?.hasMinors || false,
            hasInfants: value?.hasMinors || false,
            flightType: value?.flightType || flightTypeRTId,
            observations: value?.observations || '',
            options: value ? value.options : [],

        }
    });

    const {
        setValue,
    } = methods;
    const theme = useTheme();


    const channel = new BroadcastChannel("quotation_preview_channel_" + id);


    const preselectedWhatIncludesIds = useMemo(() => {
        if (!value) {
            return [];
        } else return value!.whatIncludes
    }, [whatIncludesContainer, value]);

    const preselecteNoIncludesIds = useMemo(() => {
        if (!value) {
            return [];
        } else return value!.noIncludes
    }, [noIncludesContainer, value]);


    // Estado donde guardamos la lista final para mandar al backend
    const [selectedItemsWhatIncludesParent, setSelectedItemsWhatIncludesParent] = useState<IListAllLegacyQuotationWhatInclude[]>([]);

    // Estado donde guardamos la lista final para mandar al backend
    const [selectedItemsNoIncludesParent, setSelectedItemsNoIncludesParent] = useState<IListAllLegacyQuotationNoInclude[]>([]);

    const [segments, setSegments] = useState<IFlightItinerarySegmentInformation[]>(value?.flight.segments || []);


    // Evitamos recrear la función en cada render:
    const handleChangeNoIncludes = useCallback((items: IListAllLegacyQuotationNoInclude[]) => {
        console.log('handleChangeNoIncludes', items)
        setSelectedItemsNoIncludesParent(items);
        channel.postMessage({noIncludes: items});
    }, []);


    const navigate = useNavigate();
    const {
        control,
        formState: {errors, isValid},
        getValues,
        watch
    } = methods;


    const watchedOptions = useWatch({
        control,
        name: 'options'
    }) as ILegacyQuotationFlightTariffOptionUpsert[];


    // Evitamos recrear la función en cada render:
    const handleChangeWhatIncludes = useCallback((items: IListAllLegacyQuotationWhatInclude[]) => {
        setSelectedItemsWhatIncludesParent(items);
        channel.postMessage({whatIncludes: items});
    }, []);

    const pnrValue = watch('pnr');

    const titleValue = useWatch({control, name: "title"});
    const hasMinorsValue = useWatch({control, name: "hasMinors"});
    const hasInfantsValue = useWatch({control, name: "hasInfants"});
    const flightTypeValue = useWatch({control, name: "flightType"});
    const observationsValue = useWatch({control, name: "observations"});

    useEffect(() => {
        channel.postMessage({quotationTitle: titleValue});
    }, [titleValue]);

    useEffect(() => {
        channel.postMessage({observations: observationsValue});
    }, [observationsValue]);


    useEffect(() => {
        channel.postMessage({options: convertOptionsFromFormToOptionsToPreview(watchedOptions, whatIncludesForTariffWithCategory)});
    }, [watchedOptions, whatIncludesForTariffWithCategory]);

    const onGenerateItinerary = async () => {
        const result = await fetchItinerarySegmentsByPNR(pnrValue);
        setSegments(result);
        channel.postMessage({flightItinerarySegments: result});
    };

    useEffect(() => {
        if (value && !id.startsWith('create')) {
            setSegments(value.flight.segments);
            const newOptions = value.options.map(x => {
                return {
                    ...x,
                    adultPrice: Number(x.adultPrice),
                    minorPrice: x.minorPrice != null ? Number(x.minorPrice) : undefined,
                    infantPrice: x.infantPrice != null ? Number(x.infantPrice) : undefined,
                    items: x.items

                }
            });
            setValue('options', newOptions);
        }
    }, [value]);


    const handleQuotationPreview = () => {
        const baseUrl = window.location.origin;
        const newRoute = appRoutes.legacyQuotations.quotationFlight.preview.replace(':id', id || '');
        window.open(`${baseUrl}${newRoute}`, "_blank");
        setTimeout(() => {
            channel.postMessage({
                quotationNumber: value?.quotationNumber || null,
                quotationTitle: titleValue,
                flightItinerarySegments: segments,
                noIncludes: selectedItemsNoIncludesParent,
                whatIncludes: selectedItemsWhatIncludesParent,
                observations: observationsValue,
                options: convertOptionsFromFormToOptionsToPreview(watchedOptions, whatIncludesForTariffWithCategory)
            });
        }, 1000)
    }

    const isValidAllValues = isValid && segments.length > 0 &&
        selectedItemsWhatIncludesParent.length > 0 && watchedOptions.length > 0;


    const handleSendForm = (formValue: ILegacyQuotationFlightFormValue) => {
        if (loading) return;
        let valueToSend: ILegacyQuotationFlightUpsert = {} as ILegacyQuotationFlightUpsert;
        if (!value) {
            valueToSend = {
                quotationId: uuidv4(),
                title: formValue.title,
                reference: formValue.reference ? formValue.reference : undefined,
                hasMinors: formValue.hasMinors,
                hasInfants: formValue.hasInfants,
                flightType: formValue.flightType,
                whatIncludes: selectedItemsWhatIncludesParent.map(x => x.id),
                noIncludes: selectedItemsNoIncludesParent.map(x => x.id),
                observations: formValue.observations ? formValue.observations : null,

                flight: {
                    quotationFlightId: uuidv4(),
                    pnr: formValue.pnr,
                    segments: segments
                } as ILegacyQuotationPackageFlightUpsert,
                options: formValue.options.map((x, index) => ({...x, orderPosition: index}))

            }
        } else {
            valueToSend = {
                quotationId: value!.quotationId,
                observations: formValue.observations ? formValue.observations : null,
                reference: formValue.reference ? formValue.reference : undefined,
                title: formValue.title,
                hasMinors: formValue.hasMinors,
                hasInfants: formValue.hasInfants,
                flightType: formValue.flightType,
                whatIncludes: selectedItemsWhatIncludesParent.map(x => x.id),
                noIncludes: selectedItemsNoIncludesParent.map(x => x.id),
                flight: {
                    quotationFlightId: value!.flight.quotationFlightId,
                    pnr: formValue.pnr,
                    segments: segments
                } as ILegacyQuotationPackageFlightUpsert,

                options: formValue.options.map((x, index) => ({...x, orderPosition: index}))

            }
        }


        handleSubmitForm(valueToSend);
    }

    return (
        <FormProvider {...methods}>
            <Card sx={{maxWidth: '90%', width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>{value ? `Actualizar cotización de vuelo #${value.quotationNumber}` : 'Crear nueva cotización de vuelo'}</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>

                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Configuración general *
                            </Typography>
                        </Box>

                        <Box display="flex" alignItems="center" gap={2}>
                            <Controller
                                name="title"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'},
                                    maxLength: {
                                        value: 255,
                                        message: 'El campo excede el largo máximo permitido de: 500 caracteres',
                                    },
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        {...field}
                                        label="Título"
                                        size="small"
                                        type="text"
                                        error={!!errors.title}
                                        helperText={errors.title ? errors.title.message : ''}
                                        // Se asigna flex para que ocupe el espacio disponible
                                        sx={{flex: 1}}
                                    />
                                )}
                            />

                        </Box>

                        <Box display="flex" alignItems="center" gap={2}>
                            <Controller
                                name="hasMinors"
                                control={control}
                                render={({field}) => (
                                    <FormControlLabel
                                        sx={{flex: 1}} // Hace que el label ocupe más espacio
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                            />
                                        }
                                        label="Incluye niños (CHD)"
                                    />
                                )}
                            />

                            <Controller
                                name="hasInfants"
                                control={control}
                                render={({field}) => (
                                    <FormControlLabel
                                        sx={{flex: 1}} // Igual para el otro checkbox
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                            />
                                        }
                                        label="Incluye infantes (INF)"
                                    />
                                )}
                            />

                            <Controller
                                name="flightType"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'},
                                }}
                                render={({field}) => (
                                    <FormControl sx={{flex: 2}} error={!!errors.flightType}>
                                        <InputLabel id="flight-type-select-label">Tipo de vuelo*</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="flight-type-select-label"
                                            id="flight-type"
                                            required
                                            value={field.value || []}
                                            onChange={(event) => field.onChange(event.target.value)}
                                            renderValue={(selected) =>
                                                flightTypesWithName.find((x) => x.id === selected)?.name || ''
                                            }
                                            label="Tipo de vuelo*"
                                        >
                                            {flightTypesWithName.map((flightType) => (
                                                <MenuItem key={flightType.id} value={flightType.id}>
                                                    {flightType.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.flightType && (
                                            <FormHelperText>{errors.flightType.message}</FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                        </Box>

                        <Box display="flex" alignItems="center" gap={3}>
                            <Controller
                                name="reference"
                                control={control}
                                rules={{
                                    maxLength: {
                                        value: 500,
                                        message: 'El campo excede el largo máximo permitido de: 500 caracteres'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        sx={{flex: 5}}
                                        fullWidth
                                        label="Referencia"
                                        size={'small'}
                                        type="text"
                                        helperText={errors.reference ? errors.reference.message : ''}
                                        error={!!errors.title}
                                        {...field}
                                    />
                                )}
                            />

                        </Box>


                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Qué incluye *
                            </Typography>
                        </Box>

                        <WhatIncludeSelector
                            data={whatIncludesContainer}
                            preselectedIds={preselectedWhatIncludesIds}
                            onChange={handleChangeWhatIncludes}
                            refreshData={refreshWhatIncludes}
                        />

                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Configuración de servicio de vuelo *
                            </Typography>
                        </Box>

                        <Controller
                            name="pnr"
                            control={control}
                            rules={{required: 'El PNR es obligatorio'}}
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    label="Ingrese el PNR"
                                    variant="outlined"
                                    size="small"
                                    required
                                    fullWidth
                                    multiline
                                    rows={12}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    sx={{mb: 2}}
                                />
                            )}
                        />

                        {/* Button: Generar Itinerario */}
                        <Button
                            variant="outlined"
                            startIcon={<AddCircle/>}
                            disabled={pnrValue.length == 0}
                            onClick={onGenerateItinerary}
                        >
                            Generar itinerario
                        </Button>

                        {/* Table of segments */}
                        <FlightItineraryView segments={segments}/>

                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Opciones de tarifas *
                            </Typography>
                        </Box>
                        <LegacyQuotationFlightTariffOptionsForm hasMinors={hasMinorsValue} hasInfants={hasInfantsValue}
                                                                flightType={flightTypeValue}
                                                                whatIncludesForTariffWithCategory={whatIncludesForTariffWithCategory}
                                                                refreshWhatIncludesForTariff={refreshWhatIncludesForTariff}
                        />

                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                No incluye
                            </Typography>
                        </Box>

                        <NoIncludeSelector
                            data={noIncludesContainer}
                            preselectedIds={preselecteNoIncludesIds}
                            onChange={handleChangeNoIncludes}
                            refreshData={refreshNoIncludes}
                        />


                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Observaciones
                            </Typography>
                        </Box>
                        <Box>
                            <Controller
                                name="observations"
                                control={control}
                                render={({field}) => (
                                    <ReactQuill
                                        {...field}
                                        theme="snow"
                                        style={{height: "300px", width: '100%', marginBottom: '30px'}}
                                        onChange={(content) => field.onChange(content)} // Asegura que React Hook Form reciba los cambios
                                        modules={{
                                            toolbar: toolbarOptionsForObservationsInQuotation
                                        }}
                                    />

                                )}
                            />
                        </Box>


                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2} mt={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate(`${appRoutes.legacyQuotations.quotationFlight.list}`)}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleQuotationPreview}
                            >
                                Vista previa

                            </Button>

                            <ButtonLinkWithCopy
                            disabled={!isValidAllValues || !value || !linkToClient}
                            helpText={'Recuerde guardar la cotizacón antes de copiar el enlace para enviarlo al cliente'}
                            url={linkToClient||''}
                            text={'Copiar url para el cliente'}
                            />

                            <Button
                                variant="contained"
                                disabled={!isValidAllValues || loading}
                                color="primary"
                                onClick={() => handleSendForm(getValues())}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </FormProvider>
    )
        ;
};