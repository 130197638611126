import React, {useCallback, useEffect, useState} from 'react';
import {ILegacyQuotationFlightUpsert} from "../../types";
import {useNavigate, useParams} from "react-router-dom";
import {ShowAlertState} from "../../../../../../core/types";
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {extractErrorMessage, is404Error} from "../../../../../../core/helper";
import {appRoutes} from "../../../../../../core/const";

import Box from "@mui/material/Box";
import {IAutocompleteResponseIListLegacyQuotationWhatInclude} from "../../../legacy-quotation-what-includes/types";
import {getAllWhatIncludesGrouped} from "../../../legacy-quotation-what-includes/services";
import {getDetailLegacyQuotationFlight} from "../../services";
import {
    LegacyQuotationFlightReadOnlyForm
} from "../../components/LegacyQuotationFlightReadOnlyForm/LegacyQuotationFlightReadOnlyForm";
import {noIncludesResultEmpty, whatIncludeResultEmpty} from "../../../commons/types";
import {getAllWhatIncludeTariffGrouped} from "../../../legacy-quotation-flight-what-include-tariff/services";
import {
    convertAllWhatIncludeTariffGroupedToItemsWithCategory
} from "../../../legacy-quotation-flight-what-include-tariff/helpers";
import {
    IListAllLegacyQuotationFlightWhatIncludeTariffWithCategory
} from "../../../legacy-quotation-flight-what-include-tariff/types";
import {getAllNoIncludesGrouped} from "../../../legacy-quotation-no-includes/services";
import {IAutocompleteResponseIListLegacyQuotationNoInclude} from "../../../legacy-quotation-no-includes/types";


const LegacyQuotationFlightReadOnlyContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [whatIncludeResult, setWhatIncludeResult] = useState<IAutocompleteResponseIListLegacyQuotationWhatInclude>(whatIncludeResultEmpty);
    const [noIncludeResult, setNoIncludeResult] = useState<IAutocompleteResponseIListLegacyQuotationNoInclude>(noIncludesResultEmpty);
    const [whatIncludeForTariffWithCategoryResult, setWhatIncludeForTariffWithCategoryResult] = useState<IListAllLegacyQuotationFlightWhatIncludeTariffWithCategory[]>([]);
    const [quotationFlightDetail, setQuotationFlightDetail] = useState<ILegacyQuotationFlightUpsert | null>(null);


    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();

    const loadQuotationFlight = useCallback(async () => {
        try {
            if (id && !id.startsWith('create')) {
                setLoading(true);
                const result = (await getDetailLegacyQuotationFlight(id)).data;
                setQuotationFlightDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el detalle de la cotización')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate(`${appRoutes.legacyQuotations.quotationFlight.list}`), 2500);
            }
        }
    }, [id, setLoading, setAlert]);


    const loadWhatIncludes = async () => {
        try {
            setLoading(true);
            const result = await getAllWhatIncludesGrouped();
            const finalResult = {
                ...result.data,
                system: result.data.system.filter(x => x.isTemplateDestinationNight == null)
            };
            //para solo vuelos no dice x noches en el destino porque no hace falta
            setWhatIncludeResult(finalResult);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el listado de que incluye')
            });
        } finally {
            setLoading(false);
        }
    };

    const loadWhatIncludesForTariff = async () => {
        try {
            setLoading(true);
            const result = await getAllWhatIncludeTariffGrouped();
            const finalResult = convertAllWhatIncludeTariffGroupedToItemsWithCategory(result.data);
            setWhatIncludeForTariffWithCategoryResult(finalResult);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el listado de que incluye en las tarifas de vuelos')
            });
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        loadWhatIncludes();
    }, []);

    useEffect(() => {
        loadWhatIncludesForTariff();
    }, []);

    useEffect(() => {
        loadNoIncludes();
    }, []);


    useEffect(() => {
        loadQuotationFlight();
    }, [id]);

    const loadNoIncludes = async () => {
        try {
            setLoading(true);
            const result = await getAllNoIncludesGrouped();
            setNoIncludeResult(result.data);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el listado de no incluye')
            });
        } finally {
            setLoading(false);
        }
    };


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>


            {id && !id.startsWith('create') && quotationFlightDetail && <LegacyQuotationFlightReadOnlyForm
                whatIncludesContainer={whatIncludeResult}
                noIncludesContainer={noIncludeResult}
                whatIncludesForTariffWithCategory={whatIncludeForTariffWithCategoryResult}
                id={id}
                value={quotationFlightDetail}/>}

            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default LegacyQuotationFlightReadOnlyContainer;
