import {AxiosResponse} from "axios";
import {ILegacyQuotationGeneralCondition, ILegacyQuotationGeneralConditionContent} from "./types";
import {ApiService} from "../../../../core/services";

const endpoints = {
    get: 'api/legacy-quotation-general-conditions',
    upsert: 'api/legacy-quotation-general-conditions',
}


export const getGeneralConditions = (): Promise<AxiosResponse<ILegacyQuotationGeneralCondition>> => ApiService.get(endpoints.get);
export const upsertGeneralConditions = (data:ILegacyQuotationGeneralConditionContent): Promise<AxiosResponse<void>> => ApiService.put(endpoints.upsert,data);
