import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Card, CardContent, Typography} from '@mui/material';
import {useForm} from 'react-hook-form';
import {IContactInfoToEmail, IListContactConfigurationToSendEmail} from "../../types";
import ContactsForm from "../ContactsForm/ContactsForm";

export interface ContactEmailConfigurationUpsertFormProps {
    handleSubmitForm: (value: IUpsertContactEmailConfigurationFormValue) => void;
    loading: boolean;
    value: IListContactConfigurationToSendEmail,
}

export interface IUpsertContactEmailConfigurationFormValue {
    administrationContacts: IContactInfoToEmail[];
    headContacts: IContactInfoToEmail[];
    issueContacts: IContactInfoToEmail[];
}

export const ContactEmailConfigurationUpsertForm: React.FC<ContactEmailConfigurationUpsertFormProps> = ({
                                                                                                            handleSubmitForm,
                                                                                                            loading,
                                                                                                            value
                                                                                                        }) => {
    const formRefAdministration = useRef<any>(null);
    const formRefHead = useRef<any>(null);
    const formRefIssue = useRef<any>(null);
    const [isAdministrationContactFormValid, setIsAdministrationContactFormValid] = useState(false);
    const [isHeadContactFormValid, setIsHeadContactFormValid] = useState(false);
    const [isIssueContactFormValid, setIsIssueContactFormValid] = useState(false);

    useEffect(() => {
        if (formRefAdministration.current) {
            formRefAdministration.current.triggerValidation();
            setIsAdministrationContactFormValid(formRefAdministration.current.isValidForm());
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (formRefAdministration.current) {
                setIsAdministrationContactFormValid(formRefAdministration.current.isValidForm());
            }
        }, 500);
        return () => clearInterval(interval);
    }, []);


    const getAdministrationContactsValue = () => {
        if (formRefAdministration.current) {
            const formValues = formRefAdministration.current.getFormValues();
            return formValues.Contacts;
        }
        return [];
    };


    useEffect(() => {
        if (formRefHead.current) {
            formRefHead.current.triggerValidation();
            setIsHeadContactFormValid(formRefHead.current.isValidForm());
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (formRefHead.current) {
                setIsHeadContactFormValid(formRefHead.current.isValidForm());
            }
        }, 500);
        return () => clearInterval(interval);
    }, []);
    const getHeadContactsValue = () => {
        if (formRefHead.current) {
            const formValues = formRefHead.current.getFormValues();
            return formValues.Contacts;
        }
        return [];
    };


    useEffect(() => {
        if (formRefIssue.current) {
            formRefIssue.current.triggerValidation();
            setIsIssueContactFormValid(formRefIssue.current.isValidForm());
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (formRefIssue.current) {
                setIsIssueContactFormValid(formRefIssue.current.isValidForm());
            }
        }, 500);
        return () => clearInterval(interval);
    }, []);
    const getIssueContactsValue = () => {
        if (formRefIssue.current) {
            const formValues = formRefIssue.current.getFormValues();
            return formValues.Contacts;
        }
        return [];
    };


    const methods = useForm<IUpsertContactEmailConfigurationFormValue>({
        mode: 'all',
        defaultValues: value
    });

    const {
        formState: {isValid},
    } = methods;

    return (
        <>
            <Card sx={{width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>Configuración de contactos para envío de emails</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>


                        <ContactsForm ref={formRefAdministration} initialData={value.administrationContacts}
                                      title={'Contactos de administración'}/>

                        <ContactsForm ref={formRefIssue} initialData={value.issueContacts}
                                      title={'Contactos de emisiones'}/>

                        <ContactsForm ref={formRefHead} initialData={value.headContacts}
                                      title={'Contactos del líder de la empresa'}/>


                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="contained"
                                disabled={!isValid || !isAdministrationContactFormValid || !isHeadContactFormValid || !isIssueContactFormValid || loading}
                                color="primary"
                                onClick={() => handleSubmitForm({
                                    administrationContacts: getAdministrationContactsValue(),
                                    headContacts: getHeadContactsValue(),
                                    issueContacts:getIssueContactsValue()
                                })}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};