import React from "react";
import {Box, Typography, useTheme, useMediaQuery, IconButton} from "@mui/material";
import { IUserProfile } from "../../../../../users/types";
import logo from "../../../../../../assets/images/logo_new.svg";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

interface SellerFooterPreviewProps {
    seller: IUserProfile;
}

const SellerFooterPreview: React.FC<SellerFooterPreviewProps> = ({ seller }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "20px",
                paddingBottom:0,
                background: theme.palette.primary.main,
                color: "white",
                position: "relative",
                textAlign: isMobile ? "center" : "inherit",
            }}
        >
            {/* Logo de la empresa */}
            <Box
                component="img"
                src={logo}
                alt="Company Logo"
                sx={{
                    height: "60px",
                    width: "auto",
                    marginX: isMobile ? "0" : "20px",
                    marginBottom: isMobile ? "10px" : "0",
                }}
            />

            {/* Sección del vendedor */}
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: isMobile ? "column" : "row", gap: "16px" }}>
                {/*<Box
                    component="img"
                    src={seller.image}
                    alt={seller.name}
                    sx={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                        border: "3px solid white",
                    }}
                />*/}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        // borderLeft: isMobile ? "none" : "3px solid white",
                        borderLeft: isMobile ? "none" : "none",
                        paddingLeft: isMobile ? "0" : "16px",
                        alignItems: isMobile ? "center" : "flex-start",
                    }}
                >
                    <Typography variant="h6" fontWeight="bold">
                        {seller.name}
                    </Typography>
                    <Typography variant="body2">{seller.jobTitle}</Typography>
                </Box>
            </Box>

            {/* Información de contacto */}
            <Box sx={{ textAlign: isMobile ? "center" : "right", marginTop: isMobile ? "10px" : "0" }}>

                <IconButton
                    href={`https://wa.me/${seller.phoneContact}`}
                    target="_blank"
                    title={'Enviar Whatsapp'}
                    rel="noopener noreferrer"
                    sx={{ color: "white" }}
                >
                    <WhatsAppIcon sx={{fontSize:"20px",mr:'5px'}} />
                    <Typography variant="body1">
                         {seller.phoneContact}
                    </Typography>
                </IconButton>
                <Typography variant="body1">
                    ✉️{" "}
                    <a
                        title={'Enviar email'}
                        href={`mailto:${seller.email}`}
                        style={{ color: "white", textDecoration: "none" }}
                    >
                        {seller.email}
                    </a>
                </Typography>
            </Box>
        </Box>
    );
};

export default SellerFooterPreview;
