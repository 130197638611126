import React, {useCallback, useEffect, useState} from 'react';
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {createNoInclude, getNoInclude, updateNoInclude} from "../../services";
import {appRoutes} from "../../../../../../core/const";
import {ShowAlertState} from "../../../../../../core/types";
import {IDetailLegacyQuotationNoInclude, ILegacyQuotationNoInclude} from "../../types";
import {extractErrorMessage, is404Error} from "../../../../../../core/helper";
import {
    LegacyQuotationNoIncludeUpsertForm
} from "../../components/LegacyQuotationNoIncludeUpsertForm/LegacyQuotationNoIncludeUpsertForm";

const LegacyQuotationNoIncludeUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [noIncludeDetail, setNoIncludeDetail] = useState<IDetailLegacyQuotationNoInclude | null>(null);
    const { id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const isCreate = id === 'create'

    const loadNoIncludeDetail = useCallback(async () => {
        try {
            if (id && id !== 'create') {
                setLoading(true);
                const result = (await getNoInclude(id)).data;
                setNoIncludeDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el que incluye')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate(`${appRoutes.legacyQuotations.noInclude.list}`), 2500);
            }
        }
    }, [id, setLoading, setNoIncludeDetail, setAlert]);


    useEffect(() => {
        loadNoIncludeDetail();
    }, [loadNoIncludeDetail]);

    const handleUpsertNoInclude = async (value: ILegacyQuotationNoInclude) => {
        const messageSuccess = isCreate ? 'creado' : 'actualizado';
        const messageError = isCreate ? 'crear' : 'actualizar';
        if (id) {
            try {
                setLoading(true);
                if (isCreate) {
                    await createNoInclude(value);
                } else {
                    await updateNoInclude(id, value);
                }

                setLoading(false);
                setAlert({
                    show: true,
                    severity: 'success',
                    message: 'No incluye ' + messageSuccess + ' satisfactoriamente'
                });
                setTimeout(() => navigate(`${appRoutes.legacyQuotations.noInclude.list}`), 1500)
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al ' + messageError + ' el no incluye')
                });
                setLoading(false);
            }
        }

    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {id && id === 'create' &&
                <LegacyQuotationNoIncludeUpsertForm loading={loading} handleSubmitForm={handleUpsertNoInclude}/>}
            {id && id !== 'create' && noIncludeDetail &&
                <LegacyQuotationNoIncludeUpsertForm value={noIncludeDetail} loading={loading}
                                                      handleSubmitForm={handleUpsertNoInclude}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default LegacyQuotationNoIncludeUpsertContainer;
