import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import {Alert, Backdrop, CircularProgress, Paper, Snackbar, styled, Typography, useTheme} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {extractErrorMessage} from "../../../../core/helper";
import {ShowAlertState} from "../../../../core/types";
import {IUserProfile} from "../../types";
import {getProfile, updateProfile} from "../../services";
import UserProfileForm from "../UserProfileForm/UserProfileForm";


type UserProfileFormModalModalProps = {
    open: boolean;
    handleClose: (data: IUserProfile | null) => void;
};

const StyledPaper = styled(Paper)(({theme}) => ({
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}));

const CloseButton = styled(IconButton)(({theme}) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
}));

const DivTitle = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
}));

const UserProfileFormModal: React.FC<UserProfileFormModalModalProps> = ({open, handleClose}) => {
    const theme = useTheme();

    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [userProfile, setUserProfile] = useState<IUserProfile|null>(null);

    const loadUserProfile = async()=>{
        try {
            setLoading(true);
            const profile = await getProfile();
            setUserProfile(profile.data)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar los datos  del perfil')
            });
            handleClose(null);
        } finally {
            setLoading(false);
        }


    }


    const onSubmit = async (data:IUserProfile) => {
        try {
            if(loading) return;
            setLoading(true);
          const result =  await updateProfile(data);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Perfil de usuario actualizado satisfactoriamente'
            });
           setTimeout(()=> handleClose(result.data),1500);

        } catch (error: any) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al actualizar el perfil')
            });
            setLoading(false);
        }
    };

    const handleContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

        useEffect(() => {
            loadUserProfile();
        }, []);

    return (
        <>
            <Backdrop sx={{
                zIndex: theme.zIndex.modal + 1,
                color: '#fff',
            }} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Modal open={open}>
                <StyledPaper onClick={handleContentClick}>
                    <DivTitle>
                        <Typography variant="h5">Actualizar perfil</Typography>
                        <CloseButton aria-label="close" onClick={()=>handleClose(null)}>
                            <CloseIcon/>
                        </CloseButton>
                    </DivTitle>

                    {userProfile && <UserProfileForm onSubmit={onSubmit} initialData={userProfile}/>}


                </StyledPaper>
            </Modal>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={alert.show} autoHideDuration={1500}
                      onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
};

export default UserProfileFormModal;
