import React, { useState, useEffect, ChangeEvent } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import {
    Box,
    Button,
    Grid,
    TextField,
    Avatar,
    Typography, FormHelperText,
} from '@mui/material';
import {IUserProfile} from "../../types";
import {emailValidation} from "../../../../core/const";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

interface UserProfileFormProps {
    initialData?: IUserProfile;
    onSubmit: (data: IUserProfile) => void;
}

const UserProfileForm: React.FC<UserProfileFormProps> = ({ initialData, onSubmit }) => {
    const {
        control,
        handleSubmit,
        formState: { errors,isValid },
    } = useForm<IUserProfile>({
        mode: 'all',
        defaultValues: {
            name: initialData?.name || '',
            phoneContact: initialData?.phoneContact || '',
            jobTitle: initialData?.jobTitle || '',
            email: initialData?.email || '',
        },
    });

    // Estado para la imagen de perfil: file subido (si se desea) y preview.
    const [profileImageFile, setProfileImageFile] = useState<File | undefined>(undefined);
    const [imagePreview, setImagePreview] = useState<string | undefined>(
        initialData?.profileImageUrl
    );

    // Actualizar preview si initialData trae profileImageUrl.
    useEffect(() => {
        if (initialData?.profileImageUrl) {
            setImagePreview(initialData.profileImageUrl);
        }
    }, [initialData]);

    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setProfileImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Image = reader.result as string;
                setImagePreview(base64Image);
            };
            reader.readAsDataURL(file);
        }
    };

    const onFormSubmit: SubmitHandler<IUserProfile> = (data) => {
        // Si imagePreview existe y es base64 (comienza con "data:"), se envía en profileImageUrl; de lo contrario, se omite.
        const formData: IUserProfile = {
            ...data,
            ...(imagePreview && imagePreview.startsWith('data:') ? { profileImageUrl: imagePreview.split(',')[1] } : {}),
        };
        onSubmit(formData);
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onFormSubmit)} noValidate sx={{ mt: 2 }}>
            <Grid container spacing={2}>
                {/* Campo Nombre */}
                <Grid item xs={12}>
                    <Controller
                        name="name"
                        control={control}
                        rules={{
                            required: 'El nombre es requerido',
                            maxLength: { value: 255, message: 'Máximo 255 caracteres' },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                size={"small"}
                                label="Nombre"
                                required={true}
                                fullWidth
                                error={!!errors.name}
                                helperText={errors.name ? errors.name.message : ''}
                            />
                        )}
                    />
                </Grid>
                {/* Campo Mail (deshabilitado) */}
                <Grid item xs={12}>
                    <Controller
                        name="email"
                        control={control}
                        rules={{ required: 'El mail es requerido' ,
                            pattern: emailValidation,
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}

                                required={true}
                                label="Correo electrónico"
                                size={"small"}
                                fullWidth
                                disabled
                                error={!!errors.email}
                                helperText={errors.email ? errors.email.message : ''}
                            />
                        )}
                    />
                </Grid>
                {/* Campo Teléfono de contacto (requerido) */}
                <Grid item xs={12}>
                    <Controller
                        name="phoneContact"
                        control={control}
                        rules={{ required: 'El teléfono de contacto es requerido' }}
                        render={({ field }) => (
                            <>
                                <PhoneInput
                                    {...field}
                                    country={'uy'}
                                    enableSearch={true}
                                    inputProps={{
                                        required: true,
                                    }}
                                    specialLabel="Teléfono de contacto*"
                                    inputStyle={{
                                        width: '100%',
                                    }}
                                    onChange={(value) => field.onChange(value)}
                                />
                                {errors.phoneContact && <FormHelperText error>{errors.phoneContact.message}</FormHelperText>}
                            </>
                        )}
                    />
                </Grid>
                {/* Campo Job Title */}
                <Grid item xs={12}>
                    <Controller
                        name="jobTitle"
                        control={control}
                        rules={{ required: 'El cargo es requerido' }}
                        render={({ field }) => (
                            <TextField
                                {...field}

                                required={true}
                                size={"small"}
                                label="Cargo"
                                fullWidth
                                error={!!errors.jobTitle}
                                helperText={errors.jobTitle ? errors.jobTitle.message : ''}
                            />
                        )}
                    />
                </Grid>
                {/* Imagen de perfil */}
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Imagen de perfil</Typography>
                    <Box display="flex" alignItems="center" gap={2} mt={1}>
                        <Avatar
                            src={imagePreview || undefined}
                            sx={{ width: 80, height: 80 }}
                        />
                        <Button variant="outlined" component="label">
                            Subir imagen
                            <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={handleImageChange}
                            />
                        </Button>
                    </Box>
                </Grid>

                <Box display="flex" width="100%" justifyContent="flex-end" pt={2}>
                    <Button type="submit" variant="contained" color="primary" disabled={!isValid}>
                        Guardar
                    </Button>
                </Box>

            </Grid>
        </Box>
    );
};

export default UserProfileForm;
