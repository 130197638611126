// TourismIconRenderer.tsx
import React from "react";
import { tourismIcons } from "../IconSelect/IconsHelper";
import {useTheme} from "@mui/material";
import {List} from "@mui/icons-material";

interface IconRendererProps {
    iconName?: string; // ej: "Hotel"
}

export const IconRenderer: React.FC<IconRendererProps> = ({
                                                                            iconName
                                                                        }) => {
    const theme= useTheme()
    const found = tourismIcons.find((item) => item.name === iconName);
    const IconComponent = found?.Icon||List;
    return <IconComponent sx={{'color': theme.palette.primary.main}} />;
};
