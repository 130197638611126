import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import {Controller, FormProvider, useForm, useWatch} from 'react-hook-form';
import {useNavigate} from "react-router-dom";
import {appRoutes} from "../../../../../../core/const";
import {
    IFlightItinerarySegmentInformation,
    ILegacyQuotationDestinationUpsert,
    ILegacyQuotationPackageAdditionalOptionUpsert,
    ILegacyQuotationPackageFlightUpsert,
    ILegacyQuotationPackageFormValue,
    ILegacyQuotationPackageImageUpsert,
    ILegacyQuotationPackageOptionUpsert,
    ILegacyQuotationPackageRoomTypeDistributionUpsert,
    ILegacyQuotationPackageUpsert
} from "../../types";
import {IAutoCompleteDestinationList} from "../../../../../destinations/types";
import MultiDestinationForm, {IDestinationFormItem} from "../MultiDestinationForm/MultiDestinationForm";
import {PackageImageSelector, SelectedImage} from "../PackageImageSelector/PackageImageSelector";
import {
    IAutocompleteResponseIListLegacyQuotationWhatInclude,
    IListAllLegacyQuotationWhatInclude
} from "../../../legacy-quotation-what-includes/types";
import {WhatIncludeSelector} from "../../../commons/components/WhatIncludeSelector/WhatIncludeSelector";
import {IListAllLegacyQuotationAdditional} from "../../../legacy-quotations-additionals/types";
import {AddCircle} from "@mui/icons-material";
import {
    IAutocompleteResponseIListLegacyQuotationExclusiveService,
    IListLegacyQuotationExclusiveService
} from "../../../legacy-quotation-exclusive-services/types";
import {ExclusiveServiceSelector} from "../ExclusiveServiceSelector/ExclusiveServiceSelector";
import RoomTypeDistributionForm from "../RoomTypeDistributionForm/RoomTypeDistributionForm";
import {IListNomenclator} from "../../../commons/types";
import LegacyQuotationPackageOptionsForm from "../LegacyQuotationPackageOptionsForm/LegacyQuotationPackageOptionsForm";
import {
    adaptAdditionalOptionToSend,
    toolbarOptionsForObservationsInQuotation,
    transformDataToQuotationAdditionalOptionPreview,
    transformDataToQuotationOptionPreview,
    transformWhatIncludeToPreview
} from "../../helper";
import {IListManualHotelByDestination} from "../../../manual-hotels/types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {NoIncludeSelector} from "../../../commons/components/NoIncludeSelector/NoIncludeSelector";
import {
    IAutocompleteResponseIListLegacyQuotationNoInclude,
    IListAllLegacyQuotationNoInclude
} from "../../../legacy-quotation-no-includes/types";
import {FlightItineraryView} from "../../../commons/components/FlightItineraryView/FlightItineraryView";
import LegacyQuotationPackageAdditionalOptionsForm
    from "../LegacyQuotationPackageAdditionalOptionsForm/LegacyQuotationPackageAdditionalOptionsForm";
import ButtonLinkWithCopy from "../../../commons/components/ButtonLinkWithCopy/ButtonLinkWithCopy";

export interface LegacyQuotationPackageUpsertFormProps {
    whatIncludesContainer: IAutocompleteResponseIListLegacyQuotationWhatInclude,
    noIncludesContainer: IAutocompleteResponseIListLegacyQuotationNoInclude,
    exclusiveServicesContainer: IAutocompleteResponseIListLegacyQuotationExclusiveService,
    destinations: IAutoCompleteDestinationList[],
    roomTypes: IListNomenclator[],
    regimens: IListNomenclator[],
    handleSubmitForm: (value: ILegacyQuotationPackageUpsert) => void;
    fetchItinerarySegmentsByPNR: (pnr: string) => Promise<IFlightItinerarySegmentInformation[]>,
    manualHotelsByDestinations: Record<string, IListManualHotelByDestination[]>,
    refreshManualHotels: (destinationCodes: string[]) => Promise<void>,
    refreshNoIncludes: () => Promise<void>,
    refreshWhatIncludes: () => Promise<void>,
    refreshExclusiveServices: () => Promise<void>,
    refreshHotelListByDestinationCode: (destinationCode: string) => Promise<void>,
    refreshDestinations: () => Promise<void>,
    additionalsByDestination: Record<string, IListAllLegacyQuotationAdditional[]>,
    refreshAdditionalsListByDestinationCode: (destinationCode: string) => Promise<void>,
    refreshAdditionalsByDestinations: (destinationCodes: string[]) => Promise<void>,
    loading: boolean;
    id: string;
    value?: ILegacyQuotationPackageUpsert,
    linkToClient?:string
}

export const LegacyQuotationPackageUpsertForm: React.FC<LegacyQuotationPackageUpsertFormProps> = ({
                                                                                                      whatIncludesContainer,
                                                                                                      noIncludesContainer,
                                                                                                      exclusiveServicesContainer,
                                                                                                      destinations,
                                                                                                      roomTypes,
                                                                                                      regimens,
                                                                                                      handleSubmitForm,
                                                                                                      fetchItinerarySegmentsByPNR,
                                                                                                      manualHotelsByDestinations,
                                                                                                      refreshManualHotels,
                                                                                                      refreshWhatIncludes,
                                                                                                      refreshNoIncludes,
                                                                                                      refreshExclusiveServices,
                                                                                                      refreshHotelListByDestinationCode,
                                                                                                      refreshDestinations,
                                                                                                      additionalsByDestination,
                                                                                                      refreshAdditionalsByDestinations,
                                                                                                      refreshAdditionalsListByDestinationCode,
                                                                                                      loading,
                                                                                                      id,
                                                                                                      value,
                                                                                                      linkToClient
                                                                                                  }) => {


    const destinationsToForm = !value ? [
        {
            quotationDestinationId: uuidv4(),
            destination: null,
            checkIn: null,
            checkOut: null,
            nights: 0,
        }

    ] : value.quotationDestinations.map(x => {
        return {
            quotationDestinationId: x.quotationDestinationId,
            destination: destinations.find(d => d.code === x.destinationCode) || null,
            checkIn: x.checkIn,
            checkOut: x.checkOut,
            nights: x.nights
        } as IDestinationFormItem;
    })

    const methods = useForm<ILegacyQuotationPackageFormValue>({
        mode: 'all',
        defaultValues: {
            title: value?.title || '',
            reference: value?.reference || '',
            destinations: destinationsToForm,
            pnr: value?.flight?.pnr || '',
            withFlightServiceConfig: !value ? true : value?.flight != null,
            observations: value?.observations || '',
            roomTypeDistributions: value ? value.roomTypeDistributions : [],
            options: value ? value.options : [],

        }
    });

    const {
        setValue,
    } = methods;
    const theme = useTheme();

    // Suppose we have a previously selected image from backend
    // e.g., user saved this package with an image from Paris
    const [persistedImage] = useState<SelectedImage | null>(!value ? null : value.imageSelected != null ? {
        destinationCode: value.imageSelected?.destinationCode,
        imageUrl: value!.imageSelected!.imageUrl
    } : null);

    const channel = new BroadcastChannel("quotation_preview_channel_" + id);


    // We'll store the currently chosen image in local state
    const [chosenImage, setChosenImage] = useState<SelectedImage | null>(value?.imageSelected || null);


    const preselectedWhatIncludesIds = useMemo(() => {
        if (!value) {
            return [];
        } else return value!.whatIncludes
    }, [whatIncludesContainer, value]);

    const preselecteNoIncludesIds = useMemo(() => {
        if (!value) {
            return [];
        } else return value!.noIncludes
    }, [noIncludesContainer, value]);


    const preselectedExclusiveServiceIds = useMemo(() => {
        if (!value) {
            return exclusiveServicesContainer.system.map(x => x.id);
        } else return value!.exclusiveServices
    }, [exclusiveServicesContainer, value]);

    // Estado donde guardamos la lista final para mandar al backend
    const [selectedItemsWhatIncludesParent, setSelectedItemsWhatIncludesParent] = useState<IListAllLegacyQuotationWhatInclude[]>([]);
    const [selectedItemsExclusiveServiceParent, setSelectedItemsExclusiveServiceParent] = useState<IListLegacyQuotationExclusiveService[]>([]);

    const [segments, setSegments] = useState<IFlightItinerarySegmentInformation[]>(value?.flight?.segments || []);

    // Estado donde guardamos la lista final para mandar al backend
    const [selectedItemsNoIncludesParent, setSelectedItemsNoIncludesParent] = useState<IListAllLegacyQuotationNoInclude[]>([]);

    // Evitamos recrear la función en cada render:
    const handleChangeNoIncludes = useCallback((items: IListAllLegacyQuotationNoInclude[]) => {
        setSelectedItemsNoIncludesParent(items);
        channel.postMessage({noIncludes: items});
    }, []);


    const navigate = useNavigate();
    const {
        control,
        formState: {errors, isValid},
        getValues,
        watch
    } = methods;

    // Watch the "destinations" array from the form in real time
    // Each item is { destination: IDestinationList | null, checkIn, checkOut, nights }
    const watchedDestinations = useWatch({
        control,
        name: 'destinations', // The field array name used in MultiDestinationForm
    }) as IDestinationFormItem[];

    // Transform the watched destinations into something for the PackageImageSelector.
    // We'll assume each 'destination' has an optional 'imageUrl' if you want to store that.
    // If there's no imageUrl, the PackageImageSelector can show a placeholder.
    const packageSelectorDestinations = React.useMemo(() => {
        return watchedDestinations
            .filter(item => item.destination !== null)
            .map(item => ({
                code: item.destination!.code,
                name: item.destination!.name,
                countryName: item.destination!.countryName,
                // If you have an imageUrl in IDestinationList:
                imageUrl: destinations.find(x => x.code == item.destination!.code)?.imageUrl,
            }));
    }, [watchedDestinations, destinations]);

    const destinationsSelected: IAutoCompleteDestinationList[] = React.useMemo(() => {
        return watchedDestinations
            .filter(item => item.destination !== null)
            .map(item => item.destination as IAutoCompleteDestinationList);
    }, [watchedDestinations]);

    //este valor es porque si yo elimine una linea de adicional por destino no quiero que me la vuelva a recrear a menos que sea un destino agregardo nuevo
    const isNewDestination = !value ? destinationsSelected.map(x => x.code) :
        destinationsSelected.filter(x => !value.quotationDestinations.some(y => y.destinationCode == x.code)).map(x => x.code);

    const watchedDistributions = useWatch({
        control,
        name: 'roomTypeDistributions', // The field array name used in MultiDestinationForm
    }) as ILegacyQuotationPackageRoomTypeDistributionUpsert[];

    const roomTypesIdsWithHasMinorSelected = React.useMemo(() => {
        return watchedDistributions
            .filter(item => item.roomTypeId !== '')
            .map(item => ({roomTypeId: item.roomTypeId, hasMinors: item.hasMinors}));
    }, [watchedDistributions]);

    const hasMinors = roomTypesIdsWithHasMinorSelected.some(x => x.hasMinors);

    const watchedOptions = useWatch({
        control,
        name: 'options', // The field array name used in MultiDestinationForm
    }) as ILegacyQuotationPackageOptionUpsert[];

    const watchedAdditionalOptions = useWatch({
        control,
        name: 'additionalOptions', // The field array name used in MultiDestinationForm
    }) as ILegacyQuotationPackageAdditionalOptionUpsert[];

    // Evitamos recrear la función en cada render:
    const handleChangeExclusiveService = useCallback((items: IListLegacyQuotationExclusiveService[]) => {
        // Solo log si cambia
        setSelectedItemsExclusiveServiceParent(items);
        channel.postMessage({exclusiveServices: items});
    }, []);

    // Evitamos recrear la función en cada render:
    const handleChangeWhatIncludes = useCallback((items: IListAllLegacyQuotationWhatInclude[]) => {
        setSelectedItemsWhatIncludesParent(items);
        channel.postMessage({whatIncludes: transformWhatIncludeToPreview(items, watchedDestinations)});
    }, [watchedDestinations]);

    const pnrValue = watch('pnr');


    const titleValue = useWatch({control, name: "title"});
    const withFlightServiceConfigValue = useWatch({control, name: "withFlightServiceConfig"});
    const observationsValue = useWatch({control, name: "observations"});

    useEffect(() => {
        channel.postMessage({quotationTitle: titleValue});
    }, [titleValue]);

    useEffect(() => {
        channel.postMessage({observations: observationsValue});
    }, [observationsValue]);


    useEffect(() => {
        channel.postMessage({options: transformDataToQuotationOptionPreview(watchedDestinations, regimens, roomTypes, manualHotelsByDestinations, watchedOptions)});
    }, [watchedOptions]);

    useEffect(() => {
        if (Array.isArray(watchedAdditionalOptions)) {
            channel.postMessage({additionalOptions: transformDataToQuotationAdditionalOptionPreview(watchedDestinations, additionalsByDestination, watchedAdditionalOptions)});
        }
    }, [watchedAdditionalOptions]);

    const onGenerateItinerary = async () => {
        const result = await fetchItinerarySegmentsByPNR(pnrValue);
        setSegments(result);
        channel.postMessage({flightItinerarySegments: result});
    };

    useEffect(() => {
        if (value && destinations.length > 0 && !id.startsWith('create')) {
            const destinationToSet = value.quotationDestinations.map(x => {
                return {
                    quotationDestinationId: x.quotationDestinationId,
                    destination: destinations.find(d => d.code === x.destinationCode) || null,
                    checkIn: x.checkIn,
                    checkOut: x.checkOut,
                    nights: x.nights
                } as IDestinationFormItem;
            });
            setValue('destinations', destinationToSet);
            setSegments(value.flight?.segments || []);
            const newOptions = value.options.map(x => {
                return {
                    ...x,
                    optionPricesByRoomTypeDistribution: x.optionPricesByRoomTypeDistribution.map(distribution => {
                        return {
                            ...distribution,
                            adultPrice: Number(distribution.adultPrice),
                            minorPrice: distribution.minorPrice != null ? Number(distribution.minorPrice) : undefined
                        }
                    })
                }
            });
            setValue('options', newOptions);

            const newAdditionalOptions = value.additionalOptions.map(x => {
                return {
                    ...x,
                    additionalOptionItems: x.additionalOptionItems.map(additionalOptionItem => {
                        return {
                            ...additionalOptionItem,
                            adultPrice: additionalOptionItem.adultPrice !=null? Number(additionalOptionItem.adultPrice)+'' : '',
                            minorPrice: additionalOptionItem.minorPrice != null ? Number(additionalOptionItem.minorPrice)+'' : ''
                        }
                    })
                }
            });
            setValue('options', newOptions);
            setValue('additionalOptions', newAdditionalOptions);
        }
    }, [destinations, value]);

    useEffect(() => {
        refreshManualHotels(destinationsSelected.map(x => x.code));
    }, [destinationsSelected]);

    useEffect(() => {
        refreshAdditionalsByDestinations(destinationsSelected.map(x => x.code));
    }, [destinationsSelected]);

    useEffect(() => {
        if (!withFlightServiceConfigValue) {
            setSegments([]);
            setValue('pnr', '');
        }
    }, [withFlightServiceConfigValue]);

    const handleQuotationPreview = () => {
        const baseUrl = window.location.origin;
        const newRoute = appRoutes.legacyQuotations.quotationPackage.preview.replace(':id', id || '');
        window.open(`${baseUrl}${newRoute}`, "_blank");
        setTimeout(() => {
            channel.postMessage({
                quotationNumber: value?.quotationNumber || null,
                quotationTitle: titleValue,
                flightItinerarySegments: segments,
                additionalOptions: transformDataToQuotationAdditionalOptionPreview(watchedDestinations, additionalsByDestination, watchedAdditionalOptions),
                exclusiveServices: selectedItemsExclusiveServiceParent,
                whatIncludes: transformWhatIncludeToPreview(selectedItemsWhatIncludesParent, watchedDestinations),
                noIncludes: selectedItemsNoIncludesParent,
                quotationImage: chosenImage?.imageUrl || '',
                observations: observationsValue,
                options: transformDataToQuotationOptionPreview(watchedDestinations, regimens, roomTypes, manualHotelsByDestinations, watchedOptions)
            });
        }, 1000)
    }
    const isValidFlight = (withFlightServiceConfigValue && segments.length > 0 && pnrValue != '') || (!withFlightServiceConfigValue && segments.length == 0 && pnrValue == '');

    const isValidAllValues = isValid && isValidFlight &&
        selectedItemsWhatIncludesParent.length > 0 &&
        selectedItemsExclusiveServiceParent.length > 0 && packageSelectorDestinations.length > 0
        && roomTypesIdsWithHasMinorSelected.length > 0 && watchedOptions.length > 0;

    const handleSendForm = (formValue: ILegacyQuotationPackageFormValue) => {
        if (loading) return;
        let valueToSend: ILegacyQuotationPackageUpsert = {} as ILegacyQuotationPackageUpsert;
        if (!value) {
            valueToSend = {
                quotationId: uuidv4(),
                title: formValue.title,
                reference: formValue.reference ? formValue.reference : undefined,
                whatIncludes: selectedItemsWhatIncludesParent.map(x => x.id),
                noIncludes: selectedItemsNoIncludesParent.map(x => x.id),
                exclusiveServices: selectedItemsExclusiveServiceParent.map(x => x.id),
                observations: formValue.observations ? formValue.observations : null,
                quotationDestinations: formValue.destinations.map(x => {
                    return {
                        quotationDestinationId: x.quotationDestinationId,
                        destinationCode: x.destination?.code || 'XX',
                        checkIn: (x.checkIn || 'T').split('T')[0],
                        checkOut: (x.checkOut || 'T').split('T')[0],
                        nights: Number(x.nights)
                    } as ILegacyQuotationDestinationUpsert
                }),
                imageSelected: chosenImage != null ? {
                    destinationCode: chosenImage?.destinationCode,
                    imageBase64: chosenImage?.imageBase64 ? chosenImage?.imageBase64.split(',')[1] : null,
                    imageUrl: '' //aqui no enviamos nada porque no hace falta al backend
                } as ILegacyQuotationPackageImageUpsert : null,
                flight: formValue.pnr && segments.length > 0 ? {
                    quotationFlightId: uuidv4(),
                    pnr: formValue.pnr,
                    segments: segments
                } as ILegacyQuotationPackageFlightUpsert : undefined,
                roomTypeDistributions: formValue.roomTypeDistributions.map((x, index) => ({
                    ...x,
                    orderPosition: index
                })),
                options: formValue.options.map((x, index) => ({...x, orderPosition: index})),
                additionalOptions: adaptAdditionalOptionToSend(formValue.additionalOptions)


            }
        } else {
            valueToSend = {
                quotationId: value!.quotationId,
                observations: formValue.observations ? formValue.observations : null,
                title: formValue.title,
                reference: formValue.reference ? formValue.reference : undefined,
                whatIncludes: selectedItemsWhatIncludesParent.map(x => x.id),
                noIncludes: selectedItemsNoIncludesParent.map(x => x.id),
                exclusiveServices: selectedItemsExclusiveServiceParent.map(x => x.id),
                quotationDestinations: formValue.destinations.map(x => {
                    return {
                        quotationDestinationId: x.quotationDestinationId,
                        destinationCode: x.destination?.code || 'XX',
                        checkIn: (x.checkIn || 'T').split('T')[0],
                        checkOut: (x.checkOut || 'T').split('T')[0],
                        nights: Number(x.nights)
                    } as ILegacyQuotationDestinationUpsert
                }),
                imageSelected: chosenImage != null ? {
                    destinationCode: chosenImage?.destinationCode,
                    imageBase64: chosenImage?.imageBase64 ? chosenImage?.imageBase64.split(',')[1] : null,
                    imageUrl: ''  //aqui no enviamos nada porque no hace falta al backend
                } as ILegacyQuotationPackageImageUpsert : null,
                flight: formValue.pnr && segments.length > 0 ? {
                    quotationFlightId: value!.flight?.quotationFlightId || uuidv4(),
                    pnr: formValue.pnr,
                    segments: segments
                } as ILegacyQuotationPackageFlightUpsert : undefined,
                roomTypeDistributions: formValue.roomTypeDistributions.map((x, index) => ({
                    ...x,
                    orderPosition: index
                })),
                options: formValue.options.map((x, index) => ({...x, orderPosition: index})),
                additionalOptions: adaptAdditionalOptionToSend(formValue.additionalOptions)

            }
        }


        handleSubmitForm(valueToSend);
    }

    return (
        <FormProvider {...methods}>
            <Card sx={{maxWidth: '90%', width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>{value ? `Actualizar cotización de paquete #${value.quotationNumber}` : 'Crear nueva cotización de paquete'}</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>

                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Configuración general *
                            </Typography>
                        </Box>

                        <Box display="flex" alignItems="center" gap={3}>
                            <Controller
                                name="title"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'},
                                    maxLength: {
                                        value: 255,
                                        message: 'El campo excede el largo máximo permitido de: 255 caracteres'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        sx={{flex: 5}}
                                        required
                                        fullWidth
                                        label="Título del paquete"
                                        size={'small'}
                                        type="text"
                                        helperText={errors.title ? errors.title.message : ''}
                                        error={!!errors.title}
                                        {...field}
                                    />
                                )}
                            />

                            <Controller
                                name="withFlightServiceConfig"
                                control={control}
                                render={({field}) => (
                                    <FormControlLabel
                                        sx={{flex: 1}} // Hace que el label ocupe más espacio
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                            />
                                        }
                                        label="Incluir Vuelo"
                                    />
                                )}
                            />
                        </Box>

                        <Box display="flex" alignItems="center" gap={3}>
                            <Controller
                                name="reference"
                                control={control}
                                rules={{
                                    maxLength: {
                                        value: 500,
                                        message: 'El campo excede el largo máximo permitido de: 500 caracteres'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        sx={{flex: 5}}
                                        fullWidth
                                        label="Referencia"
                                        size={'small'}
                                        type="text"
                                        helperText={errors.reference ? errors.reference.message : ''}
                                        error={!!errors.title}
                                        {...field}
                                    />
                                )}
                            />

                        </Box>

                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Configuración de destino(s) *
                            </Typography>
                        </Box>

                        <MultiDestinationForm
                            control={methods.control}
                            availableDestinations={destinations}
                            name="destinations"
                            refreshData={refreshDestinations}
                        />
                        <PackageImageSelector
                            destinations={packageSelectorDestinations}
                            isCreate={!value}
                            initialSelected={persistedImage}  // Auto-select if matches
                            onChange={(selection) => {
                                setChosenImage(selection);
                                channel.postMessage({quotationImage: selection?.imageUrl || ''});
                            }}
                        />

                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Configuración de distribucción de habitaciones *
                            </Typography>
                        </Box>

                        <RoomTypeDistributionForm roomTypes={roomTypes} control={control}/>

                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Qué incluye *
                            </Typography>
                        </Box>

                        <WhatIncludeSelector
                            data={whatIncludesContainer}
                            preselectedIds={preselectedWhatIncludesIds}
                            onChange={handleChangeWhatIncludes}
                            refreshData={refreshWhatIncludes}
                        />

                        {withFlightServiceConfigValue && <><Box display="flex" alignItems="center" gap={1} flex={1}
                                                                p={1}
                                                                sx={{
                                                                    backgroundColor: theme.palette.primary.dark,
                                                                    borderRadius: 2
                                                                }} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Configuración de servicio de vuelo *
                            </Typography>
                        </Box>

                            <Controller
                                name="pnr"
                                control={control}
                                rules={{required: 'El PNR es obligatorio'}}
                                render={({field, fieldState}) => (
                                    <TextField
                                        {...field}
                                        label="Ingrese el PNR"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        rows={12}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                        sx={{mb: 2}}
                                    />
                                )}
                            />

                            {/* Button: Generar Itinerario */}
                            <Button
                                variant="outlined"
                                startIcon={<AddCircle/>}
                                disabled={pnrValue.length == 0}
                                onClick={onGenerateItinerary}
                            >
                                Generar itinerario
                            </Button>

                            {/* Table of segments */}
                            <FlightItineraryView segments={segments}/></>}

                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Opciones de alojamiento *
                            </Typography>
                        </Box>

                        <LegacyQuotationPackageOptionsForm regimens={regimens} roomTypes={roomTypes}
                                                           destinations={destinationsSelected}
                                                           roomTypeDistributions={roomTypesIdsWithHasMinorSelected}
                                                           refreshHotelListByDestinationCode={refreshHotelListByDestinationCode}
                                                           hotelsByDestination={manualHotelsByDestinations}/>


                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                No incluye
                            </Typography>
                        </Box>

                        <NoIncludeSelector
                            data={noIncludesContainer}
                            preselectedIds={preselecteNoIncludesIds}
                            onChange={handleChangeNoIncludes}
                            refreshData={refreshNoIncludes}
                        />


                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            gap={1}
                            flex={1}
                            p={1}
                            sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}}
                            mb={2}
                        >
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Adicionales
                            </Typography>
                        </Box>
                        <LegacyQuotationPackageAdditionalOptionsForm destinations={destinationsSelected}
                                                                     refreshAdditionalsListByDestinationCode={refreshAdditionalsListByDestinationCode}
                                                                     additionalsByDestination={additionalsByDestination}
                                                                     newsDestinationsCodes={isNewDestination}
                                                                     hasMinors={hasMinors}
                        />


                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Servicios exclusivos *
                            </Typography>
                        </Box>

                        <ExclusiveServiceSelector
                            data={exclusiveServicesContainer}
                            preselectedIds={preselectedExclusiveServiceIds}
                            onChange={handleChangeExclusiveService}
                            refreshData={refreshExclusiveServices}
                        />

                        <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                             sx={{backgroundColor: theme.palette.primary.dark, borderRadius: 2}} mb={2}>
                            <Typography variant="h6" sx={{color: 'white'}}>
                                Observaciones
                            </Typography>
                        </Box>
                        <Box>
                            <Controller
                                name="observations"
                                control={control}
                                render={({field}) => (
                                    <ReactQuill
                                        {...field}
                                        theme="snow"
                                        style={{height: "300px", width: '100%', marginBottom: '30px'}}
                                        onChange={(content) => field.onChange(content)} // Asegura que React Hook Form reciba los cambios
                                        modules={{
                                            toolbar: toolbarOptionsForObservationsInQuotation
                                        }}
                                    />

                                )}
                            />
                        </Box>


                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2} mt={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate(`${appRoutes.legacyQuotations.quotationPackage.list}`)}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleQuotationPreview}
                            >
                                Vista previa

                            </Button>

                            <ButtonLinkWithCopy
                                disabled={!isValidAllValues || !value || !linkToClient}
                                helpText={'Recuerde guardar la cotizacón antes de copiar el enlace para enviarlo al cliente'}
                                url={linkToClient||''}
                                text={'Copiar url para el cliente'}
                            />

                            <Button
                                variant="contained"
                                disabled={!isValidAllValues || loading}
                                color="primary"
                                onClick={() => handleSendForm(getValues())}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </FormProvider>
    )
        ;
};