import React from "react";
import { Box, Typography } from "@mui/material";

interface QuotationTitlePreviewProps {
    title: string|null;
    image: string|null;
}

const QuotationTitlePreview: React.FC<QuotationTitlePreviewProps> = ({ title, image }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                padding: "20px",
            }}
        >
            {title &&<Typography variant="h5" fontWeight="bold" gutterBottom>
                {title}
            </Typography>}
            {!title &&<Typography variant="h5" fontWeight="bold" gutterBottom>
              Sin título de la cotización configurado
            </Typography>}
            {image && (
                <Box
                    component="img"
                    src={image}
                    alt="Quotation Image"
                    sx={{
                        maxWidth: "100%",
                        height: "auto",
                        borderRadius: "8px",
                        marginTop: "10px",
                        boxShadow: "0px 7px 10px rgba(0, 71, 187, 0.5)"
                    }}
                />
            )}
        </Box>
    );
};

export default QuotationTitlePreview;