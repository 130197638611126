import React from "react";
import {Box, Typography, useTheme} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import {containsVisibleText} from "../../../legacy-quotation-packages/helper";
interface ObservationsPreviewProps {
    observations: string|null;
}

const ObservationsPreview: React.FC<ObservationsPreviewProps> = ({observations}) => {
    const theme = useTheme();
    return (

        <Box
            sx={{
                border: 0,
                pl: "20px",
                mt: 1
            }}
        >
            <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                 sx={{backgroundColor: theme.palette.primary.main, borderRadius: 2}} mb={1} mr={2}>
                <Typography variant="h6" sx={{color: 'white'}}>
                  Observaciones
                </Typography>
            </Box>
            <Box mr={2} ml={2}>
                {observations && <Box sx={{
                    typography: "body2",lineHeight:'1'
                }}
                                     dangerouslySetInnerHTML={{__html: observations||''}}
                />}
            </Box>

        </Box>
    );
};

export default ObservationsPreview;
