import {ApiService} from "../../core/services";
import {AxiosResponse} from "axios";

import {IPaginatedResult, ISort} from "../../core/types";
import {cleanFilters, formatSortingFields} from "../../core/helper";
import {IDocumentInformationResult, IFilterListReceipts, IListReceipts} from "./types";

const endpoints = {
    list: 'api/authorizations-receipts',
    update: 'api/authorizations-receipts/:authorizationId/receipts-made',
    clearDocumentInformation: '/api/authorizations-receipts/:cardPortfolioId/clear-information/document',
}

export const getReceipts = (page = 1, pageSize = 10, sortingFields: ISort[], search: string, filters?: IFilterListReceipts): Promise<AxiosResponse<IPaginatedResult<IListReceipts>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(filters && cleanFilters(filters)), ...(search && search !== '' && {search: search})
        }
    });
};

export const madeReceipt = (authorizationId:number):Promise<AxiosResponse<void>> =>{
    return ApiService.put(endpoints.update.replace(':authorizationId',authorizationId+''))
}

export const getDocumentInformation = (cardPortfolioId:number):Promise<AxiosResponse<IDocumentInformationResult>> =>{
    return ApiService.get(endpoints.clearDocumentInformation.replace(':cardPortfolioId',cardPortfolioId+''))
}