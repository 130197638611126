import React, {useMemo, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {Autocomplete, Box, Button, Checkbox, FormControlLabel, IconButton, TextField} from '@mui/material';
import {AddCircle} from '@mui/icons-material';
import {Control, Controller, useFieldArray, useFormContext, useWatch,} from 'react-hook-form';

import {IListNomenclator} from "../../../commons/types";
import {ILegacyQuotationPackageRoomTypeDistributionUpsert} from "../../types";
import DeleteIcon from "@mui/icons-material/Delete";

interface RoomTypeDistributionFormProps {
    control: Control<any>;
    roomTypes: IListNomenclator[];
    name?: string;
    readonly?:boolean
}

const RoomTypeDistributionForm: React.FC<RoomTypeDistributionFormProps> = ({
                                                                               control,
                                                                               roomTypes,
                                                                               name = 'roomTypeDistributions',
                                                                               readonly= false
                                                                           }) => {
    const {setValue} = useFormContext();

    const {fields, append, remove} = useFieldArray<{
        [key: string]: ILegacyQuotationPackageRoomTypeDistributionUpsert[]
    }>({
        control,
        name,
    });

    const watchRows = useWatch({
        control,
        name,
    }) as ILegacyQuotationPackageRoomTypeDistributionUpsert[];

    const [selectedRoomType, setSelectedRoomType] = useState<IListNomenclator | null>(null);

    const availableRoomTypes = useMemo(() => {
        const usedIds = watchRows?.map(row => row.roomTypeId) || [];
        return roomTypes.filter(rt => !usedIds.includes(rt.id));
    }, [roomTypes, watchRows]);

    const handleAdd = () => {
        if (!selectedRoomType) return;

        append({
            distributionId: uuidv4(),
            roomTypeId: selectedRoomType.id,
            hasMinors: false,
            orderPosition:0 // cuando se envia se calcula
        });

        setSelectedRoomType(null);
    };

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            {/* Selector para agregar nueva línea */}
            <Box display="flex" alignItems="center" gap={2}>
                <Autocomplete
                    sx={{width: 300}}
                    options={availableRoomTypes}
                    disabled={readonly}
                    getOptionLabel={(option) => option.name}
                    value={selectedRoomType}
                    onChange={(_, newValue) => setSelectedRoomType(newValue)}
                    isOptionEqualToValue={(option, val) => option.id === val?.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            disabled={readonly}
                            label="Tipo de habitación"
                            variant="outlined"
                            size="small"
                        />
                    )}
                />

                {!readonly&&<Button
                    variant="outlined"
                    onClick={handleAdd}
                    disabled={!selectedRoomType}
                    startIcon={<AddCircle/>}
                >
                    Agregar
                </Button>}
            </Box>

            {/* Listado de líneas agregadas */}
            {fields.map((item, index) => {
                const typedItem = item as ILegacyQuotationPackageRoomTypeDistributionUpsert;
                const roomTypeName =
                    roomTypes.find(rt => rt.id === typedItem.roomTypeId)?.name || typedItem.roomTypeId;

                return (
                    <Box
                        key={item.id}
                        display="flex"
                        alignItems="center"
                        gap={2}
                        sx={{p: 2, border: '1px solid #ccc', borderRadius: 2}}
                        flexWrap="wrap"
                    >
                        <TextField
                            label="Tipo de habitación"
                            value={roomTypeName}
                            variant="outlined"
                            size="small"
                            disabled={readonly}
                            sx={{width: 300}}
                            InputProps={{readOnly: true}}
                        />

                        <Controller
                            name={`${name}.${index}.hasMinors`}
                            control={control}
                            render={({field}) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={readonly}
                                            checked={field.value}
                                            onChange={(e) => field.onChange(e.target.checked)}
                                        />
                                    }
                                    label="Incluye menores"
                                />
                            )}
                        />

                        {!readonly  &&<IconButton color="error" onClick={() => remove(index)}>
                            <DeleteIcon/>
                        </IconButton>}
                    </Box>
                );
            })}
        </Box>
    );
};

export default RoomTypeDistributionForm;
