import React, {useEffect, useState} from 'react';
import {Autocomplete, Box, Button, Divider, IconButton, InputAdornment, TextField, Typography,} from '@mui/material';
import {Add, AddCircle, Refresh} from '@mui/icons-material';
import {Controller, useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import {v4 as uuidv4} from 'uuid';

import {
    ILegacyQuotationPackageAdditionalOptionUpsert,
    ILegacyQuotationPackageOptionAdditionalItemUpsert,
} from '../../types';
import {IAutoCompleteDestinationList} from "../../../../../destinations/types";
import DeleteIcon from "@mui/icons-material/Delete";
import {IListAllLegacyQuotationAdditional} from "../../../legacy-quotations-additionals/types";
import LegacyQuotationAdditionalsFormModal
    from "../../../legacy-quotations-additionals/components/LegacyQuotationAdditionalsFormModal/LegacyQuotationAdditionalsFormModal";

interface LegacyQuotationPackageAdditionalOptionsFormProps {
    name?: string;
    hasMinors: boolean;
    newsDestinationsCodes: string[];
    additionalsByDestination: Record<string, IListAllLegacyQuotationAdditional[]>;
    destinations: IAutoCompleteDestinationList[];
    refreshAdditionalsListByDestinationCode: (destinationCode: string) => Promise<void>;
    readonly?: boolean;
}

const LegacyQuotationPackageAdditionalOptionsForm: React.FC<LegacyQuotationPackageAdditionalOptionsFormProps> = ({
                                                                                                                     name = 'additionalOptions',
                                                                                                                     hasMinors,
                                                                                                                     newsDestinationsCodes,
                                                                                                                     additionalsByDestination,
                                                                                                                     destinations,
                                                                                                                     refreshAdditionalsListByDestinationCode,
                                                                                                                     readonly = false,
                                                                                                                 }) => {

    const groupByFn = (option: IListAllLegacyQuotationAdditional) => option.createdCategory||'';
    const {control, setValue,trigger} = useFormContext();
    const [destinationCodeToCreateAdditional, setDestinationCodeToCreateAdditional] = useState<string | undefined>(undefined);
    const [preselectIdOfLastCreate, setPreselectIdOfLastCreate] = useState<string | null>(null);
    const [fieldNameToSet, setFieldNameToSet] =useState<string|null>(null);
    const {fields, append, remove} = useFieldArray({
        control,
        name,
    });

    const handleCloseModal = (idCreated: string | null) => {
        setDestinationCodeToCreateAdditional(undefined);
        if (idCreated) {
            setPreselectIdOfLastCreate(idCreated);
            destinationCodeToCreateAdditional && refreshAdditionalsListByDestinationCode(destinationCodeToCreateAdditional);
            setDestinationCodeToCreateAdditional(undefined);
        }
    }

    // Obtenemos los códigos de destino de la lista de destinos disponibles
    const destinationCodes = destinations.map(x => x.code);

    const watchOptions = useWatch({
        control,
        name,
    }) as ILegacyQuotationPackageAdditionalOptionUpsert[];

    useEffect(() => {
        if(preselectIdOfLastCreate && fieldNameToSet) {
            setValue(fieldNameToSet,preselectIdOfLastCreate);
            trigger(fieldNameToSet);
            setPreselectIdOfLastCreate(null)
            setFieldNameToSet(null)
        }
    }, [additionalsByDestination]);

    const handleAddOption = () => {
        const newOption: ILegacyQuotationPackageAdditionalOptionUpsert = {
            optionId: uuidv4(),
            orderPosition: fields.length + 1,
            additionalOptionItems: destinations.map((dest) => ({
                optionAdditionalItemId: uuidv4(),
                destinationCode: dest.code,
                additionalId: '',
                adultPrice: '',
                minorPrice: hasMinors ? '' : undefined,
            })),
        };

        append(newOption);
    };

    const handleAddNewAdditional = (destinationCode:string, fieldName:string)=> {
        setFieldNameToSet(fieldName);
        setDestinationCodeToCreateAdditional(destinationCode);
    }

    // Permite eliminar una línea de adicionales dentro de una opción, si hay más de una
    const handleRemoveAdditionalOption = (optionIndex: number, lineIndex: number) => {
        const currentOption = watchOptions?.[optionIndex];
        if (!currentOption) return;
        const currentAdditionalOptions = currentOption.additionalOptionItems;
        if (currentAdditionalOptions.length <= 1) return; // No se elimina si es la única línea
        const updated = currentAdditionalOptions.filter((_, idx) => idx !== lineIndex);
        setValue(`${name}.${optionIndex}.additionalOptionItems`, updated);
    };

    useEffect(() => {
        if (!watchOptions) return;

        const updated = watchOptions.map((option) => {
            // Se filtran las líneas existentes que correspondan a destinos válidos
            const cleanedAdditionalOptions = option.additionalOptionItems.filter((h) =>
                destinationCodes.includes(h.destinationCode)
            );
            const toAddByNewDestination: ILegacyQuotationPackageOptionAdditionalItemUpsert[] = [];

            const destinationCodesInAdditionalOptions = option.additionalOptionItems.map(x => x.destinationCode);

            destinationCodes.forEach(destinationCode => {
                if (destinationCodesInAdditionalOptions.indexOf(destinationCode) === -1 && newsDestinationsCodes.some(x => x == destinationCode)) {
                    toAddByNewDestination.push({
                        optionAdditionalItemId: uuidv4(),
                        destinationCode: destinationCode,
                        additionalId: '',
                        adultPrice: '',
                        minorPrice: hasMinors ? '' : undefined,
                    });
                }
            });

            const cleanedPricesAdditionalOptions = cleanedAdditionalOptions.map((p) => {
                const toReturn = {...p};
                if (!hasMinors) {
                    delete toReturn.minorPrice;
                    return toReturn;
                }
                return toReturn;
            });

            return {
                ...option,
                additionalOptionItems: cleanedPricesAdditionalOptions.concat(toAddByNewDestination),
            };
        });

        setValue(name, updated);
    }, [destinations]);

    return (
        <Box display="flex" flexDirection="column" gap={3}>
            {fields.map((item, index) => (
                <Box key={item.id} sx={{border: '1px solid #ccc', p: 2, borderRadius: 2}}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">Opción #{index + 1}</Typography>
                        {!readonly && (
                            <IconButton onClick={() => remove(index)} color="error">
                                <DeleteIcon/>
                            </IconButton>
                        )}
                    </Box>

                    <Divider sx={{my: 2}}/>

                    {/* Adicionales por destino */}
                    <Typography variant="subtitle1">Adicionales por destino</Typography>
                    {/* Se recorre el array real de adicionales de la opción */}
                    {watchOptions &&
                        watchOptions[index]?.additionalOptionItems.map((additionalOptionItem, destIdx) => {
                            // Se busca el destino correspondiente para obtener su nombre
                            const destination = destinations.find(dest => dest.code === additionalOptionItem.destinationCode);
                            const additionalsListInDestination = additionalsByDestination[additionalOptionItem.destinationCode] || [];

                            return (
                                <Box
                                    key={additionalOptionItem.optionAdditionalItemId}
                                    display="flex"
                                    gap={2}
                                    alignItems="center"
                                    my={1}
                                    sx={{width: '100%'}}
                                >
                                    {/* Campo para seleccionar el adicional, con mayor ancho */}
                                    <Controller
                                        name={`${name}.${index}.additionalOptionItems.${destIdx}.additionalId`}
                                        control={control}
                                        rules={{
                                            validate: value =>
                                                value !== 0 && value !== null && value !== undefined || 'El adicional es requerido',
                                        }}
                                        render={({field, fieldState}) => {
                                            const selectedAdditional =
                                                additionalsListInDestination.find((h) => h.id === field.value) || null;
                                            return (
                                                <Autocomplete
                                                    options={additionalsListInDestination}
                                                    groupBy={groupByFn}
                                                    disabled={readonly}
                                                    getOptionLabel={(option) => option.name}
                                                    value={selectedAdditional}
                                                    onChange={(_, newValue) =>
                                                        field.onChange(newValue ? newValue.id : 0)
                                                    }
                                                    isOptionEqualToValue={(option, val) => option.id === val?.id}
                                                    sx={{flex: 5, minWidth: 450, maxWidth: 800}}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={`Adicional en ${destination?.name || additionalOptionItem.destinationCode}`}
                                                            size="small"
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error?.message}
                                                            disabled={readonly}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {params.InputProps.endAdornment}
                                                                        <IconButton
                                                                            disabled={readonly}
                                                                            onClick={() => refreshAdditionalsListByDestinationCode(additionalOptionItem.destinationCode)}
                                                                            title="Refrescar opciones"
                                                                            size="small"
                                                                        >
                                                                            <Refresh/>
                                                                        </IconButton>
                                                                        <IconButton
                                                                            onClick={() => handleAddNewAdditional(destination?.code||'',`${name}.${index}.additionalOptionItems.${destIdx}.additionalId`)}
                                                                            title="Crear uno nuevo" size="small"
                                                                            disabled={readonly}>
                                                                            <Add/>
                                                                        </IconButton>
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            );
                                        }}
                                    />

                                    {/* Campo para precio por adulto */}
                                    <Controller
                                        name={`${name}.${index}.additionalOptionItems.${destIdx}.adultPrice`}
                                        control={control}
                                        rules={{
                                            validate: (value) => {
                                                // Si el campo está vacío o es nulo/undefined, se acepta como válido
                                                if (!value) {
                                                    return true;
                                                }
                                                // Si tiene valor, validamos que sea mayor a 0
                                                return value > 0 || 'El precio debe ser mayor a 0';
                                            },
                                        }}
                                        render={({field, fieldState}) => (
                                            <TextField
                                                label="Precio por adulto"
                                                type="text"
                                                size="small"
                                                {...field}
                                                disabled={readonly}
                                                onChange={(e) => {
                                                    let value = e.target.value;

                                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                                    const parts = value.split('.');

                                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                                    if (parts.length > 2) {
                                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                                    } else if (parts[1]?.length > 2) {
                                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                                    }

                                                    field.onChange(value);
                                                }}
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                                sx={{width: '20%'}}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">USD</InputAdornment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />

                                    {hasMinors && (
                                        <Controller
                                            name={`${name}.${index}.additionalOptionItems.${destIdx}.minorPrice`}
                                            control={control}
                                            rules={{
                                                validate: (value) => {
                                                    // Si el campo está vacío o es nulo/undefined, se acepta como válido
                                                    if (!value) {
                                                        return true;
                                                    }
                                                    // Si tiene valor, validamos que sea mayor a 0
                                                    return value >= 0 || 'El precio debe ser mayor o igual a 0';
                                                },
                                            }}
                                            render={({field, fieldState}) => (
                                                <TextField
                                                    label="Precio por menor"
                                                    type="text"
                                                    size="small"
                                                    {...field}
                                                    disabled={readonly}
                                                    onChange={(e) => {
                                                        let value = e.target.value;

                                                        // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                                        value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                                        const parts = value.split('.');

                                                        // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                                        if (parts.length > 2) {
                                                            value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                                        } else if (parts[1]?.length > 2) {
                                                            value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                                        }

                                                        field.onChange(value);
                                                    }}
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                    sx={{width: '20%'}}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">USD</InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}

                                    {/* Botón para eliminar la línea, si hay más de una */}
                                    {!readonly && watchOptions[index].additionalOptionItems.length > 1 && (
                                        <IconButton onClick={() => handleRemoveAdditionalOption(index, destIdx)}
                                                    color="error">
                                            <DeleteIcon/>
                                        </IconButton>
                                    )}
                                </Box>
                            );
                        })}
                </Box>
            ))}

            {destinations.length > 0 && !readonly && (
                <Button variant="outlined" onClick={handleAddOption} startIcon={<AddCircle/>}>
                    Agregar opción
                </Button>
            )}
            {destinationCodeToCreateAdditional &&
                <LegacyQuotationAdditionalsFormModal open={true} handleClose={handleCloseModal}
                                                     destinationCode={destinationCodeToCreateAdditional}/>}
        </Box>
    );
};

export default LegacyQuotationPackageAdditionalOptionsForm;
