import React from "react";
import {Box, IconButton, Typography, useMediaQuery, useTheme} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import leftImageUrl from "../../../../../../assets/images/lufthansa.png";
import rightImageUrl from "../../../../../../assets/images/iata.png";

const CompanyFooterPreview: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // Información de la compañía
    const companyWebsite = "https://www.travellerviajes.com.uy";
    const companyAddress = "Río Branco 1369, Montevideo, Uruguay";
    const phone = "+59829093222";

    // URL para Google Maps con la dirección (se puede ajustar según lo requerido)
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(companyAddress)}`;

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "20px",
                paddingTop:'5px',
                background: theme.palette.primary.main,
                color: "white",
                position: "relative",
                textAlign: isMobile ? "center" : "inherit",
            }}
        >
            {/* Imagen Izquierda */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: isMobile ? 2 : 0,
                }}
            >
                <img
                    src={leftImageUrl}
                    style={{
                        maxWidth: isMobile ? "80%" : "220px",
                        height: "auto",
                    }}
                />
            </Box>

            {/* Información Central */}
            <Box sx={{textAlign: "center", mx: isMobile ? 0 : theme.spacing(2)}}>
                {/* Sitio Web */}
                <Typography variant="body1">
                    🌍{" "}
                    <a
                        href={companyWebsite}
                        target="_blank"
                        title={'Abrir sitio web'}
                        rel="noopener noreferrer"
                        style={{color: "inherit", textDecoration: "none"}}
                    >
                        www.travellerviajes.com.uy
                    </a>
                </Typography>

                {/* Dirección que abre en Google Maps */}
                <Typography variant="body1">
                    📍{" "}
                    <a
                        href={mapsUrl}
                        target="_blank"
                        title={'Abrir dirección en Google Map'}
                        rel="noopener noreferrer"
                        style={{color: "inherit", textDecoration: "none"}}
                    >
                        {companyAddress}
                    </a>
                </Typography>

                {/* Teléfono que inicia la llamada */}
                <Typography variant="body1">
                    📞{" "}
                    <a
                        title={'Llamar por teléfono'}
                        href={`tel:${phone}`}
                        style={{color: "inherit", textDecoration: "none"}}
                    >
                        598 2909 3222
                    </a>
                </Typography>


                {/* Redes Sociales */}
                <Box sx={{display: "flex", justifyContent: "center", mt: theme.spacing(1)}}>
                    <IconButton
                        href="https://www.facebook.com/travellerviajes"
                        target="_blank"
                        title={'Abrir página en facebook'}
                        rel="noopener noreferrer"
                        sx={{color: "white"}}
                    >
                        <FacebookIcon/>
                    </IconButton>
                    <IconButton
                        href="https://www.instagram.com/travellerviajes"
                        target="_blank"
                        title={'Abrir página en Instagram'}
                        rel="noopener noreferrer"
                        sx={{color: "white"}}
                    >
                        <InstagramIcon/>
                    </IconButton>
                    <IconButton
                        href="https://www.linkedin.com/company/traveller-viajes"
                        target="_blank"
                        title={'Abrir LinkedIn'}
                        rel="noopener noreferrer"
                        sx={{color: "white"}}
                    >
                        <LinkedInIcon/>
                    </IconButton>
                </Box>
            </Box>

            {/* Imagen Derecha */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: isMobile ? 2 : 0,
                }}
            >
                <img
                    src={rightImageUrl}
                    style={{
                        maxWidth: isMobile ? "80%" : "150px",
                        height: "auto",
                    }}
                />
            </Box>
        </Box>
    );
};

export default CompanyFooterPreview;
