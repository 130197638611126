import {AxiosResponse} from "axios";
import {IPaginatedResult, ISort} from "../../../../core/types";
import {formatSortingFields} from "../../../../core/helper";
import {ApiService} from "../../../../core/services";
import {
    IAutocompleteResponseLegacyQuotationFlightWhatIncludeTariff,
    IDetailLegacyQuotationFlightWhatIncludeTariff,
    ILegacyQuotationFlightWhatIncludeTariff,
    IListLegacyQuotationFlightWhatIncludeTariff
} from "./types";

const endpoints = {
    list: 'api/legacy-quotation-flights-what-include-tariff',
    listAll: '/api/legacy-quotation-flights-what-include-tariff/list/all',
    get: 'api/legacy-quotation-flights-what-include-tariff/:id',
    create: 'api/legacy-quotation-flights-what-include-tariff',
    update: 'api/legacy-quotation-flights-what-include-tariff/:id',
    delete: 'api/legacy-quotation-flights-what-include-tariff/:id',
}

export const getWhatIncludeTariffPage = (page = 1, pageSize = 10, sortingFields: ISort[], search: string): Promise<AxiosResponse<IPaginatedResult<IListLegacyQuotationFlightWhatIncludeTariff>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(search && search !== '' && {search: search})
        }
    });
};

export const getWhatIncludeTariff = (id: string): Promise<AxiosResponse<IDetailLegacyQuotationFlightWhatIncludeTariff>> => ApiService.get(endpoints.get.replace(':id', id));
export const createWhatIncludeTariff = (data: ILegacyQuotationFlightWhatIncludeTariff): Promise<AxiosResponse<{
    id: string
}>> => ApiService.post(endpoints.create, data);
export const updateWhatIncludeTariff = (id: string, data: ILegacyQuotationFlightWhatIncludeTariff): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':id', id), data);
export const deleteWhatIncludeTariff = (id: string): Promise<AxiosResponse<void>> => ApiService.delete(endpoints.delete.replace(':id', id));


export const getAllWhatIncludeTariffGrouped = (): Promise<AxiosResponse<IAutocompleteResponseLegacyQuotationFlightWhatIncludeTariff>> => ApiService.get(endpoints.listAll);
