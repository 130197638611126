import React, {useEffect, useRef, useState} from 'react';
import {Autocomplete, Box, Button, Card, CardContent, FormControl, TextField, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {IListCardPortfolioAuthorization} from "../../types";
import {IDetailAirline} from "../../../../../airlines/types";
import {IListAuthorizationOperator} from "../../../../../authorization-operators/types";
import {operatorIdPreAuthorized} from "../../../../../authorization-operators/constants";
import FileNumbersForm
    , {
    IFormInput
} from "../../../../../corporate-card/modules/corporate-card-authorization/components/FileNumbersForm/FileNumbersForm";

export interface CardPortfolioAuthorizationUpsertFormProps {
    handleSubmitForm: (value: IUpsertCardPortfolioAuthorizationFormValue) => void;
    loading: boolean;
    airlines: IDetailAirline[],
    operators: IListAuthorizationOperator[],
    value: IListCardPortfolioAuthorization | null,
    isUpdateOnlyAmount:boolean,
    handleClose: () => void,
}

export interface IUpsertCardPortfolioAuthorizationFormValue {
    airlineCode: string;
    amount: string;
    operatorId: string;
    fileNumbers?: string;
    authorizationCode: string;
}

const defaultValue: IUpsertCardPortfolioAuthorizationFormValue = {
    airlineCode: '',
    amount: '',
    operatorId: '',
    fileNumbers: '',
    authorizationCode: ''
};
export const CardPortfolioAuthorizationUpsertForm: React.FC<CardPortfolioAuthorizationUpsertFormProps> = ({
                                                                                                              handleSubmitForm,
                                                                                                              loading,
                                                                                                              handleClose,
                                                                                                              airlines,
                                                                                                              operators,
                                                                                                              value,
                                                                                                              isUpdateOnlyAmount,
                                                                                                          }) => {
    const formRefFileNumbers = useRef<any>(null);
    const [isFileNumbersFormValid, setIsFileNumbersFormValid] = useState(false);
    const convertToValueToForm = (x: IListCardPortfolioAuthorization) => {
        return {
            airlineCode: x.airlineCode,
            amount: Number(x.amount)+'',
            operatorId: String(x.operatorId),
            fileNumbers: x.fileNumbers ? x.fileNumbers : '',
            authorizationCode: x.authorizationCode
        }
    }


    const methods = useForm<IUpsertCardPortfolioAuthorizationFormValue>({
        mode: 'all',
        defaultValues: value ? convertToValueToForm(value) : defaultValue
    });

    const {
        control,
        watch,
        formState: {errors, isValid},
        getValues,
        trigger
    } = methods;

    const operatorIdValue = watch('operatorId');

    useEffect(() => {
        if (operatorIdValue !=operatorIdPreAuthorized && operatorIdValue) {
            trigger('fileNumbers');
            setIsFileNumbersFormValid(false);
        }

    }, [operatorIdValue]);

    useEffect(() => {
        if (formRefFileNumbers.current) {
            formRefFileNumbers.current.triggerValidation();
            setIsFileNumbersFormValid(formRefFileNumbers.current.isValidForm());
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (formRefFileNumbers.current) {
                formRefFileNumbers.current.triggerValidation();
                setIsFileNumbersFormValid(formRefFileNumbers.current.isValidForm());
            }
        }, 500);
        return () => clearInterval(interval);
    }, []);


    const getFileNumbersValue = () => {
        if (formRefFileNumbers.current) {
            const formValues:IFormInput = formRefFileNumbers.current.getFormValues();
            return formValues.fileNumbers.map(x=>x.fileNumber.replaceAll(' ','')).filter(x=>x!='').join(',') as string;
        }
        return '';
    };

    const getValuesAdapted = ()=>{
        const values = getValues();
        return {
            ...values,
            fileNumbers: values.operatorId == operatorIdPreAuthorized ? undefined : getFileNumbersValue()
        }
    }

    return (
        <>
            <Card sx={{maxWidth: 700, width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>{!value ?'Nueva': value && !isUpdateOnlyAmount?'Actualizar' : 'Actualizar monto de' } autorización</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>
                        <Box display="flex" alignItems="center" gap={2} flex={3} p={1}>
                            <Controller
                                name="airlineCode"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'}
                                }}
                                render={({field}) => (
                                    <FormControl sx={{width: '100%'}} >
                                        <Autocomplete
                                            options={airlines}
                                            disabled={isUpdateOnlyAmount}
                                            getOptionLabel={(option: IDetailAirline) => `${option.code} - ${option.name}`}
                                            value={airlines.find((option) => option.code === field.value) || null}
                                            onChange={(event, value) => {
                                                const selectedValues = (value as IDetailAirline)?.code || '';
                                                field.onChange(selectedValues);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    disabled={isUpdateOnlyAmount}
                                                    variant="outlined"
                                                    required
                                                    label="Seleccione la compañía aérea"
                                                    fullWidth
                                                    helperText={errors.airlineCode ? errors.airlineCode.message : ''}
                                                    error={!!errors.airlineCode}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}
                            />
                        </Box>

                        <Box display="flex" alignItems="center" gap={2} flex={3} p={1}>
                            <Controller
                                name="operatorId"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'}
                                }}
                                render={({field}) => (
                                    <FormControl sx={{width: '100%'}}>
                                        <Autocomplete
                                            disabled={isUpdateOnlyAmount}
                                            options={operators}
                                            getOptionLabel={(option: IListAuthorizationOperator) => `${option.name}`}
                                            value={
                                                operators.find((option) => option.id === field.value) || null
                                            }
                                            onChange={(event, value) => {
                                                const selectedValues = (value as IListAuthorizationOperator)?.id || '';
                                                field.onChange(selectedValues);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size={'small'}
                                                    variant="outlined"
                                                    required
                                                    disabled={isUpdateOnlyAmount}
                                                    label="Seleccione el operador"
                                                    fullWidth
                                                    helperText={errors.operatorId ? errors.operatorId.message : ''}
                                                    error={!!errors.operatorId}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}
                            />
                        </Box>


                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="amount"
                                control={control}
                                rules={{
                                    min: {value: 1, message: 'El valor mínimo es 1'},
                                    required: {value: true, message: 'Este campo es requerido'},
                                    pattern: {
                                        value: /^[0-9]*[.,]?[0-9]+$/,
                                        message: 'El campo monto debe ser un número válido'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Monto autorizado"
                                        size={'small'}
                                        type="text"
                                        disabled={value != null && !isUpdateOnlyAmount}
                                        helperText={errors.amount ? errors.amount.message : ''}
                                        error={!!errors.amount}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>

                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="authorizationCode"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'},
                                    maxLength: {
                                        value: 15,
                                        message: 'El campo excede el largo máximo permitido de: ' + 15 + ' caracteres'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        disabled={isUpdateOnlyAmount}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Código de autorización"
                                        size={'small'}
                                        type="text"
                                        helperText={errors.authorizationCode ? errors.authorizationCode.message : ''}
                                        error={!!errors.authorizationCode}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>




                        {operatorIdValue != '' && operatorIdValue != operatorIdPreAuthorized &&
                            <FileNumbersForm ref={formRefFileNumbers} initialData={value?.fileNumbers?value?.fileNumbers?.split(','):['']} allowEdit = {!isUpdateOnlyAmount} />}


                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading || ((operatorIdValue != '' && operatorIdValue != operatorIdPreAuthorized) &&!isFileNumbersFormValid)}
                                color="primary"
                                onClick={() => handleSubmitForm(getValuesAdapted())}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};