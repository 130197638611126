import React, {useCallback, useEffect, useState} from 'react';
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {createWhatInclude, getWhatInclude, updateWhatInclude} from "../../services";
import {appRoutes} from "../../../../../../core/const";
import {ShowAlertState} from "../../../../../../core/types";
import {IDetailLegacyQuotationWhatInclude, ILegacyQuotationWhatInclude} from "../../types";
import {extractErrorMessage, is404Error} from "../../../../../../core/helper";
import {
    LegacyQuotationWhatIncludeUpsertForm
} from "../../components/LegacyQuotationWhatIncludeUpsertForm/LegacyQuotationWhatIncludeUpsertForm";

const LegacyQuotationWhatIncludeUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [whatIncludeDetail, setWhatIncludeDetail] = useState<IDetailLegacyQuotationWhatInclude | null>(null);
    const { id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const isCreate = id === 'create'

    const loadWhatIncludeDetail = useCallback(async () => {
        try {
            if (id && id !== 'create') {
                setLoading(true);
                const result = (await getWhatInclude(id)).data;
                setWhatIncludeDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el que incluye')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate(`${appRoutes.legacyQuotations.whatInclude.list}`), 2500);
            }
        }
    }, [id, setLoading, setWhatIncludeDetail, setAlert]);


    useEffect(() => {
        loadWhatIncludeDetail();
    }, [loadWhatIncludeDetail]);

    const handleUpsertWhatInclude = async (value: ILegacyQuotationWhatInclude) => {
        const messageSuccess = isCreate ? 'creado' : 'actualizado';
        const messageError = isCreate ? 'crear' : 'actualizar';
        if (id) {
            try {
                setLoading(true);
                if (isCreate) {
                    await createWhatInclude(value);
                } else {
                    await updateWhatInclude(id, value);
                }

                setLoading(false);
                setAlert({
                    show: true,
                    severity: 'success',
                    message: 'Qué incluye ' + messageSuccess + ' satisfactoriamente'
                });
                setTimeout(() => navigate(`${appRoutes.legacyQuotations.whatInclude.list}`), 1500)
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al ' + messageError + ' el que incluye')
                });
                setLoading(false);
            }
        }

    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {id && id === 'create' &&
                <LegacyQuotationWhatIncludeUpsertForm loading={loading} handleSubmitForm={handleUpsertWhatInclude}/>}
            {id && id !== 'create' && whatIncludeDetail &&
                <LegacyQuotationWhatIncludeUpsertForm value={whatIncludeDetail} loading={loading}
                                                      handleSubmitForm={handleUpsertWhatInclude}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default LegacyQuotationWhatIncludeUpsertContainer;
