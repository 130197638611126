import React, {useState} from 'react';
import {
    Collapse,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Toolbar,
    useTheme
} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {AirplaneTicket, LocalAirport, Person} from "@mui/icons-material";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import ShieldIcon from '@mui/icons-material/Security';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import AirlinesIcon from '@mui/icons-material/Airlines';
import StyleIcon from '@mui/icons-material/Style';
import PublicIcon from '@mui/icons-material/Public';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ListIcon from '@mui/icons-material/List';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PolicyIcon from '@mui/icons-material/Policy';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import SettingsIcon from '@mui/icons-material/Settings';
import SummarizeIcon from '@mui/icons-material/Summarize';
import {appRoutes} from "../../const";
import NavListItem from "../NavListItem/NavListItem";
import {CookieService} from "../../../modules/auth/services/CookieService";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HotelIcon from '@mui/icons-material/Hotel';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import ViewListIcon from '@mui/icons-material/ViewList';
import NotesIcon from '@mui/icons-material/Notes';
import DescriptionIcon from '@mui/icons-material/Description';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

interface AppSidebarProps {
    open: boolean;
}

const AppSidebar: React.FC<AppSidebarProps> = ({ open }) => {
    const [openServicesMenu, setOpenServicesMenu] = useState(false);
    const [openQuotationMenu, setOpenQuotationMenu] = useState(false);
    const location = useLocation();
    const hasRoleSysAdmin = CookieService.hasRoleSysAdmin();
    const hasRoleIssuer = CookieService.hasRoleIssuer();
    const hasRoleIssuerCorporateCard = CookieService.hasRoleIssuerCorporateCard();
    const hasRoleSeller = CookieService.hasRoleSeller();
    const hasRoleDptoAdmin = CookieService.hasRoleDptoAdmin();
    const hasEnableAdditionalFeats = process.env.REACT_APP_ENABLE_ADDITIONAL_FEAT === '1';
    const theme = useTheme();
    const handleServicesMenuClick = () => setOpenServicesMenu(!openServicesMenu);
    const handleQuotationsMenuClick = () => setOpenQuotationMenu(!openQuotationMenu);

    const isEnableCorporateCardAuthConcepts =location.pathname.indexOf('corporate-card-authorization-concepts')!==-1
    const isOpenMenuServiceAndEnableSomeRouteOfService  = openServicesMenu && location.pathname.indexOf('/services/')!==-1;
    const isOpenMenuQuotationAndEnableSomeRouteOfQuotation  = openQuotationMenu && (location.pathname.indexOf('legacy-quotations')!==-1||location.pathname.indexOf('manual-hotels')!==-1);


    return (
        <Drawer
            variant="persistent"
            anchor="left"
            open={open}
            sx={{
                width: open ? 240 : 72, // Ajusta el ancho del Drawer
                maxWidth:240,
                // paddingBottom:200,
                // marginBottom:200,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: open ? 240 : 72,
                    zIndex: (theme) => theme.zIndex.drawer,
                    overflowX: 'hidden',
                    transition: 'width 0.3s', // Añade una transición suave para el ancho
                },
            }}
        >
            <Toolbar />
            <Divider />
            <List sx={{
                paddingBottom:'200px'}}>
                <NavListItem to="/"  primary="Home" icon={<HomeIcon />} exact={true} />

                {( hasRoleSeller ||hasRoleSysAdmin) && <ListItem button onClick={handleQuotationsMenuClick}  sx={{
                    backgroundColor: isOpenMenuQuotationAndEnableSomeRouteOfQuotation ? theme.palette.primary.main : 'inherit',
                    color: isOpenMenuQuotationAndEnableSomeRouteOfQuotation ? 'white' : 'inherit',
                    '&:hover': {
                        backgroundColor: isOpenMenuQuotationAndEnableSomeRouteOfQuotation ? theme.palette.primary.main : theme.palette.primary.light,
                        color: 'white',
                    },
                }}>
                    <ListItemIcon sx={{ color: isOpenMenuQuotationAndEnableSomeRouteOfQuotation ? 'white' : 'inherit' }}><DescriptionIcon /></ListItemIcon>
                    <ListItemText primary="Cotizaciones manuales" />
                    {openQuotationMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItem>}
                 <Collapse in={openQuotationMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {( hasRoleSeller ||hasRoleSysAdmin) &&  <NavListItem to={`${appRoutes.legacyQuotations.whatInclude.list}`}  primary="Qué incluye" icon={<FormatListBulletedIcon />} sx={{pl: 4}}/>}
                        {( hasRoleSeller ||hasRoleSysAdmin) &&  <NavListItem to={`${appRoutes.legacyQuotations.noInclude.list}`}  primary="No incluye" icon={<NotInterestedIcon />} sx={{pl: 4}}/>}
                        {( hasRoleSeller ||hasRoleSysAdmin) &&   <NavListItem to={`${appRoutes.legacyQuotations.additional.list}`}  primary="Adicionales" icon={<ViewListIcon />} sx={{pl: 4}}/>}
                        {( hasRoleSeller ||hasRoleSysAdmin) &&   <NavListItem to={`${appRoutes.legacyQuotations.quotationFlightWhatIncludeTariff.list}`}  primary="Qué incluye para tarifas de vuelos" icon={<AirplaneTicket />} sx={{pl: 4}}/>}
                        {hasRoleSysAdmin&& <NavListItem to={`${appRoutes.legacyQuotations.manualHotel.list}`}  primary="Hoteles manuales" icon={<HotelIcon />} sx={{pl: 4}}/>}
                        {hasRoleSysAdmin && <NavListItem to={`${appRoutes.legacyQuotations.exclusiveService.list}`}  primary="Servicios exclusivos" icon={<StarBorderPurple500Icon />} sx={{pl: 4}}/>}
                        {hasRoleSysAdmin && <NavListItem to={`${appRoutes.legacyQuotations.generalConditions.upsert}`}  primary="Condiciones generales" icon={<NotesIcon />} sx={{pl: 4}}/>}
                        {hasRoleSeller && <NavListItem to={`${appRoutes.legacyQuotations.quotationPackage.list}`}
                                                       primary="Cotizaciones de paquetes" icon={<DescriptionIcon />} sx={{pl: 4}}/>}
                        {hasRoleSeller && <NavListItem to={`${appRoutes.legacyQuotations.quotationFlight.list}`}
                                                       primary="Cotizaciones de vuelos" icon={<LocalAirport />} sx={{pl: 4}}/>}
                    </List>
                </Collapse>

                {( hasRoleSysAdmin) &&  <NavListItem to="/countries"  primary="Paises" icon={<PublicIcon />} />}
                {( hasRoleSysAdmin) &&<NavListItem to="/destinations"  primary="Destinos" icon={<LocationOnIcon />} />}
                {( hasRoleSysAdmin) &&<NavListItem to="/airports"  primary="Aeropuertos" icon={<ConnectingAirportsIcon />} />}
                {( hasRoleIssuer ||hasRoleSysAdmin) &&  <NavListItem to="/airlines"  primary="Aerolíneas" icon={<AirlinesIcon />} />}
            {hasEnableAdditionalFeats &&<NavListItem to="/tags"  primary="Etiquetas" icon={<StyleIcon />} />}
            {hasEnableAdditionalFeats &&<NavListItem to="/providers"  primary="Proveedores" icon={<BusinessCenterIcon />} />}


                {hasEnableAdditionalFeats && <ListItem button onClick={handleServicesMenuClick}  sx={{
                    backgroundColor: isOpenMenuServiceAndEnableSomeRouteOfService ? theme.palette.primary.main : 'inherit',
                    color: isOpenMenuServiceAndEnableSomeRouteOfService ? 'white' : 'inherit',
                    '&:hover': {
                        backgroundColor: isOpenMenuServiceAndEnableSomeRouteOfService ? theme.palette.primary.main : theme.palette.primary.light,
                        color: 'white',
                    },
                }}>
                    <ListItemIcon sx={{ color: isOpenMenuServiceAndEnableSomeRouteOfService ? 'white' : 'inherit' }}><FormatListBulletedIcon /></ListItemIcon>
                    <ListItemText primary="Servicios" />
                    {openServicesMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItem>}
                {hasEnableAdditionalFeats && <Collapse in={openServicesMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <NavListItem to="/services/transfer-services"  primary="Traslados" icon={<AirportShuttleIcon />} sx={{pl: 4}}/>
                        <NavListItem to="/services/insurance-services"  primary="Seguros" icon={<ShieldIcon />} sx={{pl: 4}}/>
                    </List>
                </Collapse>}
                {hasRoleSysAdmin && <NavListItem to="/users"  primary="Usuarios" icon={<Person />} />}
                {hasRoleSysAdmin && <NavListItem   to={appRoutes.paymentInformedConsent.list}  primary="Consentimiento informado" icon={<AnnouncementIcon />} />}
                {hasRoleSeller && <NavListItem   to='/sales-order'  primary="Órdenes de venta" icon={<ReceiptLongIcon />} />}
                {(hasRoleSeller||hasRoleDptoAdmin) && <NavListItem to={appRoutes.bambooSaleOrder.list} primary="Órdenes de venta de Bamboo"
                                                                   icon={<ReceiptLongIcon />}></NavListItem>}
                { hasRoleIssuer && <NavListItem    to={appRoutes.cardPortfolio.list} primary="Tarjetas en cartera" icon={<CreditScoreIcon />} />}
                { hasRoleIssuer && <NavListItem    to={appRoutes.cardPortfolio.summary} exact={true} primary="Reporte de Tarjetas en cartera" icon={<SummarizeIcon />} />}
                {hasRoleDptoAdmin && <NavListItem    to={appRoutes.receipts.list}  primary="Recibos" icon={<ReceiptIcon />} />}
                {hasRoleSysAdmin && <NavListItem    to={appRoutes.contactEmailConfiguration.update}  primary="Configuración de contactos para envío de emails" icon={<SettingsIcon />} />}
                {(hasRoleIssuer ||hasRoleIssuerCorporateCard || hasRoleSysAdmin) && <NavListItem    to={appRoutes.authorizationOperator.list}  primary="Operadores" icon={<ListIcon />} />}
                { hasRoleIssuer && <NavListItem    to={appRoutes.authorizations.list}  primary="Autorizaciones" icon={<DoneAllIcon />} />}

                {(hasRoleIssuer ||hasRoleIssuerCorporateCard || hasRoleSysAdmin) &&<ListItem
                    button
                    component={Link}
                    to={appRoutes.corporateCardAuthorizationConcept.list}
                    sx={{
                        backgroundColor: isEnableCorporateCardAuthConcepts ? theme.palette.primary.main : 'inherit',
                        color: isEnableCorporateCardAuthConcepts ? 'white' : 'inherit',
                        '&:hover': {
                            backgroundColor: isEnableCorporateCardAuthConcepts ? theme.palette.primary.main : theme.palette.primary.light,
                            color: 'white',
                        },
                    }}
                >
                    <ListItemIcon  sx={{ color: isEnableCorporateCardAuthConcepts ? 'white' : 'inherit' }}><AssignmentIcon/></ListItemIcon>
                    <ListItemText  primaryTypographyProps={{
                        noWrap: false, // Permite el ajuste de texto
                        sx: {
                            whiteSpace: 'normal', // Permite saltos de línea
                            wordBreak: 'break-word', // Rompe palabras largas
                        },
                    }}
                                   sx={{
                                       flexGrow: 1, // Permite que el texto ocupe el espacio disponible
                                       minWidth: 0, // Permite que el texto se encoja para ajustarse al contenedor
                                   }} primary="Conceptos de autorizaciones de tarjetas corporativas" />
                    {/* Agregar el icono de flecha más pequeño al final */}
                    <ListItemSecondaryAction>
                        <ArrowForwardIosIcon
                            sx={{
                                color: isEnableCorporateCardAuthConcepts ? 'white' : 'inherit',
                                fontSize: 'small' // Ajusta el tamaño del icono
                            }}
                        />
                    </ListItemSecondaryAction>
                </ListItem>}

                {(hasRoleIssuer || hasRoleIssuerCorporateCard)  && <NavListItem    to={appRoutes.corporateCard.list}  primary="Tarjetas corporativas" icon={<CardTravelIcon />} />}
                {hasRoleSysAdmin && <NavListItem    to={appRoutes.actionLog.list}  primary="Logs de acciones" icon={<PolicyIcon />} />}

            </List>
        </Drawer>
    );
}

export default AppSidebar;
