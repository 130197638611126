import React, {useEffect, useState} from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import {Add, AddCircle, Refresh} from '@mui/icons-material';
import {Controller, useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import {v4 as uuidv4} from 'uuid';
import DeleteIcon from '@mui/icons-material/Delete';
import {ILegacyQuotationFlightTariffOptionItemUpsert, ILegacyQuotationFlightTariffOptionUpsert} from '../../types';
import {flightTypeRTId, optionItemTypeGOING, optionItemTypeRETURN} from "../../constants";
import {
    IListAllLegacyQuotationFlightWhatIncludeTariffWithCategory
} from "../../../legacy-quotation-flight-what-include-tariff/types";
import LegacyQuotationFlightWhatIncludeFormModal
    from "../../../legacy-quotation-flight-what-include-tariff/components/LegacyQuotationFlightWhatIncludeFormModal/LegacyQuotationFlightWhatIncludeFormModal";

interface LegacyQuotationFlightTariffOptionsFormProps {
    name?: string;
    hasMinors: boolean;
    hasInfants: boolean;
    flightType: string;
    whatIncludesForTariffWithCategory: IListAllLegacyQuotationFlightWhatIncludeTariffWithCategory[],
    refreshWhatIncludesForTariff: () => Promise<void>,
    readonly?: boolean;
}

const LegacyQuotationFlightTariffOptionsForm: React.FC<LegacyQuotationFlightTariffOptionsFormProps> = ({
                                                                                                           name = 'options',
                                                                                                           hasMinors,
                                                                                                           hasInfants,
                                                                                                           flightType,
                                                                                                           whatIncludesForTariffWithCategory,
                                                                                                           refreshWhatIncludesForTariff,
                                                                                                           readonly = false,
                                                                                                       }) => {
    const {control, setValue, trigger} = useFormContext();

    const {fields, append, remove} = useFieldArray({
        control,
        name,
    });
    const [showModalCreateWhatIncludeForTariff, setShowModalCreateWhatIncludeForTariff] = useState<boolean>(false);
    const [preselectIdOfLastCreate, setPreselectIdOfLastCreate] = useState<string | null>(null);
    const [indexToSetValue, setIndexToSetValue] = useState<number | null>(null);
    const [itemIndexToSetValue, setItemIndexToSetValue] = useState<number | null>(null);

    const groupByFn = (option: IListAllLegacyQuotationFlightWhatIncludeTariffWithCategory) => option.category;

    const watchOptions = useWatch({
        control,
        name,
    }) as ILegacyQuotationFlightTariffOptionUpsert[];

    const handleCloseModal = (idCreated: string | null) => {
        setShowModalCreateWhatIncludeForTariff(false);
        if (idCreated) {
            setPreselectIdOfLastCreate(idCreated);
            refreshWhatIncludesForTariff();
        }
    }

    const handleOpenModal = (indexToSetValue: number, indexItemToSetValue: number) => {
        setIndexToSetValue(indexToSetValue);
        setItemIndexToSetValue(indexItemToSetValue);
        setShowModalCreateWhatIncludeForTariff(true);
    }

    const handleAddOption = () => {
        const itemsToAdd: ILegacyQuotationFlightTariffOptionItemUpsert[] = [];
        // Siempre se agrega el item para la ida
        itemsToAdd.push({
            itemId: uuidv4(),
            orderPosition: 1,
            whatIncludeTariffId: '',
            optionItemType: optionItemTypeGOING
        });
        // Si es RT, agregamos el item para la vuelta
        if (flightType === flightTypeRTId) {
            itemsToAdd.push({
                itemId: uuidv4(),
                orderPosition: 2,
                whatIncludeTariffId: '',
                optionItemType: optionItemTypeRETURN

            });
        }
        const newOption: ILegacyQuotationFlightTariffOptionUpsert = {
            optionId: uuidv4(),
            orderPosition: fields.length + 1,
            adultPrice: 0,
            minorPrice: hasMinors ? 0 : undefined,
            infantPrice: hasInfants ? 0 : undefined,
            items: itemsToAdd,
        };

        append(newOption);
    };

    useEffect(() => {
        if (!watchOptions) return;

        // Actualizamos cada opción para ajustar los precios de menores e infantes según las propiedades
        const updated = watchOptions.map(option => {
            let itemsToUpdate = option.items.filter(x => x.optionItemType === optionItemTypeGOING);

            if (flightType === flightTypeRTId) {
                if (option.items.length == 1) {
                    itemsToUpdate.push({
                        itemId: uuidv4(),
                        orderPosition: 2,
                        whatIncludeTariffId: '',
                        optionItemType: optionItemTypeRETURN
                    });
                } else if (option.items.length == 2) {
                    const itemReturn = option.items.find(x => x.optionItemType === optionItemTypeRETURN);
                    if (itemReturn) {
                        itemsToUpdate.push({
                            itemId: itemReturn.itemId,
                            orderPosition: 2,
                            whatIncludeTariffId: itemReturn.whatIncludeTariffId,
                            optionItemType: optionItemTypeRETURN
                        });
                    }
                }

            }
            return {
                ...option,
                items: itemsToUpdate.map(item => ({
                    ...item,

                })),
                minorPrice: hasMinors ? (option.minorPrice ?? 0) : undefined,
                infantPrice: hasInfants ? (option.infantPrice ?? 0) : undefined,
            };
        });

        setValue(name, updated);
    }, [hasMinors, hasInfants, flightType]);

    useEffect(() => {
        if (indexToSetValue != null && itemIndexToSetValue != null && preselectIdOfLastCreate) {
            const item = whatIncludesForTariffWithCategory.find(x => x.id == preselectIdOfLastCreate);
            if (item) {
                setValue(`${name}.${indexToSetValue}.items.${itemIndexToSetValue}.whatIncludeTariffId`, item.id);
                trigger(`${name}.${indexToSetValue}.items.${itemIndexToSetValue}.whatIncludeTariffId`);
                setItemIndexToSetValue(null);
                setIndexToSetValue(null);
                setPreselectIdOfLastCreate(null);
            }
        }
    }, [whatIncludesForTariffWithCategory]);

    return (
        <Box display="flex" flexDirection="column" gap={3}>
            {fields.map((option, index) => {
                return (
                    <Box
                        key={option.id}
                        sx={{border: '1px solid #ccc', p: 2, borderRadius: 2}}
                    >
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">
                                Opción #{index + 1}
                            </Typography>
                            {!readonly && (
                                <IconButton onClick={() => remove(index)} color="error">
                                    <DeleteIcon/>
                                </IconButton>
                            )}
                        </Box>

                        <Divider sx={{my: 2}}/>

                        {/* Renderizamos los controles para cada item */}
                        {watchOptions &&
                            watchOptions[index]?.items.map((item, itemIndex) => (
                                <Box
                                    key={item.itemId}
                                    display="flex"
                                    alignItems="center"
                                    gap={2}
                                    mt={3}
                                >
                                    {/* Etiqueta con ancho fijo y en negrita */}
                                    <Box sx={{minWidth: 50}}>
                                        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                                            {flightType === flightTypeRTId
                                                ? item.optionItemType === optionItemTypeGOING
                                                    ? "Ida"
                                                    : "Vuelta"
                                                : "Ida"}
                                        </Typography>
                                    </Box>

                                    <Controller
                                        name={`${name}.${index}.items.${itemIndex}.whatIncludeTariffId`}
                                        control={control}
                                        rules={{
                                            required: {value: true, message: 'Este campo es requerido'}
                                        }}
                                        render={({field, fieldState}) => (
                                            <FormControl sx={{width: '100%'}}>
                                                <Autocomplete
                                                    options={whatIncludesForTariffWithCategory}
                                                    groupBy={groupByFn}
                                                    disabled={readonly}
                                                    getOptionLabel={(option: IListAllLegacyQuotationFlightWhatIncludeTariffWithCategory) => option.name}
                                                    value={
                                                        whatIncludesForTariffWithCategory.find((option) => option.id === field.value) || null
                                                    }
                                                    onChange={(event, value) => {
                                                        const selectedValue = (value as IListAllLegacyQuotationFlightWhatIncludeTariffWithCategory)?.id || '';
                                                        field.onChange(selectedValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            size="small"
                                                            variant="outlined"
                                                            required
                                                            label="Seleccione lo que incluye la tarifa"
                                                            fullWidth
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error?.message}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {params.InputProps.endAdornment}
                                                                        <IconButton
                                                                            onClick={refreshWhatIncludesForTariff}
                                                                            title="Refrescar opciones" size="small"
                                                                            disabled={readonly}>
                                                                            <Refresh/>
                                                                        </IconButton>
                                                                        <IconButton
                                                                            onClick={() => handleOpenModal(index, itemIndex)}
                                                                            title="Crear uno nuevo" size="small"
                                                                            disabled={readonly}>
                                                                            <Add/>
                                                                        </IconButton>
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                </Box>
                            ))
                        }
                        <Divider sx={{my: 2}}/>

                        <Box
                            display="flex"
                            alignItems="center"
                            gap={2}
                            mt={3}
                        >

                            <Box sx={{minWidth: 50}}>
                                <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                                    Precios
                                </Typography>
                            </Box>

                            <Controller
                                name={`${name}.${index}.adultPrice`}
                                control={control}
                                rules={{
                                    required: 'Precio requerido',
                                    validate: value =>
                                        value > 0 || 'El precio debe ser mayor a 0',
                                }}
                                render={({field, fieldState}) => (
                                    <TextField
                                        label="Precio por adulto"
                                        type="number"
                                        size="small"
                                        {...field}
                                        disabled={readonly}
                                        onChange={e => field.onChange(Number(e.target.value))}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                        sx={{width: '30%'}}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">USD</InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />

                            {hasMinors && (
                                <Controller
                                    name={`${name}.${index}.minorPrice`}
                                    control={control}
                                    rules={{
                                        required: 'Precio por menor requerido',
                                        validate: value =>
                                            value > 0 || 'El precio debe ser mayor a 0',
                                    }}
                                    render={({field, fieldState}) => (
                                        <TextField
                                            label="Precio por niño"
                                            type="number"
                                            size="small"
                                            {...field}
                                            disabled={readonly}
                                            onChange={e => field.onChange(Number(e.target.value))}
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                            sx={{width: '30%'}}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">USD</InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            )}

                            {hasInfants && (
                                <Controller
                                    name={`${name}.${index}.infantPrice`}
                                    control={control}
                                    rules={{
                                        required: 'Precio por infante requerido',
                                        validate: value =>
                                            value >= 0 || 'El precio debe ser mayor o igual a 0',
                                    }}
                                    render={({field, fieldState}) => (
                                        <TextField
                                            label="Precio por infante"
                                            type="number"
                                            size="small"
                                            {...field}
                                            disabled={readonly}
                                            onChange={e => field.onChange(Number(e.target.value))}
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                            sx={{width: '30%'}}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">USD</InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            )}
                        </Box>
                    </Box>
                );
            })}






            {showModalCreateWhatIncludeForTariff &&
                <LegacyQuotationFlightWhatIncludeFormModal open={showModalCreateWhatIncludeForTariff}
                                                           handleClose={handleCloseModal}/>}

            {!readonly && (
                <Button
                    variant="outlined"
                    onClick={handleAddOption}
                    startIcon={<AddCircle/>}
                >
                    Agregar opción
                </Button>
            )}
        </Box>
    );
};

export default LegacyQuotationFlightTariffOptionsForm;
