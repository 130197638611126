import React, { FC, useState } from 'react';
import {TableCell, IconButton, Tooltip, useTheme} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface LinkWithCopyProps {
    url: string;
}

const LinkWithCopy: FC<LinkWithCopyProps> = ({ url }) => {
    const [copied, setCopied] = useState(false);
    const theme= useTheme();

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(url);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Error al copiar:', err);
        }
    };

    return (
        <TableCell>
            {url}
            <Tooltip title={copied ? '¡Copiado!' : 'Copiar enlace'}>
                <IconButton onClick={handleCopy}>
                    <ContentCopyIcon
                        sx={{ color: copied ? theme.palette.success.light : 'inherit' }}
                    />
                </IconButton>
            </Tooltip>
        </TableCell>
    );
};

export default LinkWithCopy;
