import React, {useCallback, useEffect, useState} from 'react';
import {IListLegacyQuotationExclusiveService, IUpsertLegacyQuotationExclusiveService} from "../../types";
import {useNavigate, useParams} from "react-router-dom";
import {createExclusiveService, getExclusiveService, updateExclusiveService} from "../../services";
import {ShowAlertState} from "../../../../../../core/types";
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {extractErrorMessage, is404Error} from "../../../../../../core/helper";
import {appRoutes} from "../../../../../../core/const";
import {
    LegacyQuotationExclusiveServiceUpsertForm
} from "../../components/LegacyQuotationExclusiveServiceUpsertForm/LegacyQuotationExclusiveServiceUpsertForm";
import Box from "@mui/material/Box";


const LegacyQuotationExclusiveServiceUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [exclusiveServiceItem, setExclusiveServiceItem] = useState<IListLegacyQuotationExclusiveService | null>(null);
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();

    const loadExclusiveService = useCallback(async () => {
        try {
            if (id && id !== 'create') {
                setLoading(true);
                const result = (await getExclusiveService(id)).data;
                setExclusiveServiceItem(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el servicio exclusivo')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate(`${appRoutes.legacyQuotations.exclusiveService.list}`), 2500);
            }
        }
    }, [id, setLoading, setExclusiveServiceItem, setAlert]);


    useEffect(() => {
        loadExclusiveService();
    }, [loadExclusiveService]);

    const handleCreateExclusiveService = async (value: IUpsertLegacyQuotationExclusiveService) => {
        try {
            setLoading(true);
            await createExclusiveService(value);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Servicio exclusivo creado satisfactoriamente'
            });
            setTimeout(() => navigate(`${appRoutes.legacyQuotations.exclusiveService.list}`), 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al crear el servicio exclusivo')
            });
            setLoading(false);
        }
    }

    const handleUpdateProvider = async (value: IUpsertLegacyQuotationExclusiveService) => {
        try {
            setLoading(true);
            await updateExclusiveService(id||'', value);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Servicio exclusivo actualizado satisfactoriamente'
            });
            setTimeout(() => navigate(`${appRoutes.legacyQuotations.exclusiveService.list}`), 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al actualizar el servicio exclusivo')
            });
            setLoading(false);
        }
    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {id && id === 'create' && <LegacyQuotationExclusiveServiceUpsertForm loading={loading}
                                                                                 handleSubmitForm={handleCreateExclusiveService}/>}
            {id && id !== 'create' && exclusiveServiceItem &&
                <LegacyQuotationExclusiveServiceUpsertForm value={exclusiveServiceItem} loading={loading}
                                                           handleSubmitForm={handleUpdateProvider}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default LegacyQuotationExclusiveServiceUpsertContainer;
