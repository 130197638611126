// @ts-ignore
import Cookies from 'js-cookie';
import {IUserLoginExtraData, RoleEnum} from "../types";
import {IUserProfile} from "../../users/types";

const TOKEN_KEY = 'TOKEN_API_TRAVELLER';
const USER_NAME_KEY = 'USER_NAME_API_TRAVELLER';
const USER_EXTRA_DATA_KEY = 'USER_EXTRA_DATA_API_TRAVELLER';
const USER_PROFILE_DATA_KEY = 'USER_PROFILE_DATA_API_TRAVELLER';

export class CookieService {
    static setToken = (token: string, hours: number): string | undefined =>
        Cookies.set(TOKEN_KEY, token, {expires: Math.floor(hours / 24)});

    static setUserName = (userName: string): string | undefined =>
        Cookies.set(USER_NAME_KEY, userName, {expires: 1});

    static setUserExtraData = (extraData: IUserLoginExtraData) => {
        const result = JSON.stringify(extraData);
        Cookies.set(USER_EXTRA_DATA_KEY, result, {expires: 1});
    }

    static setUserProfileData = (extraData: IUserProfile) => {
        const result = JSON.stringify(extraData);
        Cookies.set(USER_PROFILE_DATA_KEY, result);
    }

    static getProfileData = (): IUserProfile => {
        try {
            return JSON.parse(Cookies.get(USER_PROFILE_DATA_KEY)) as IUserProfile;
        } catch (e) {
            return {name: '',  phoneContact: '',jobTitle:'',email:''};
        }

    }

    static getUserExtraData = (): IUserLoginExtraData => {
        try {
            return JSON.parse(Cookies.get(USER_EXTRA_DATA_KEY)) as IUserLoginExtraData;
        } catch (e) {
            return {userId: 0, name: '', roles: [], username: ''};
        }

    }

    static hasRole = (role: RoleEnum): boolean => CookieService.getUserExtraData().roles.includes(role);
    static hasRoleSysAdmin = (): boolean => CookieService.hasRole(RoleEnum.ROLE_SYS_ADMIN);
    static hasRoleIssuer = (): boolean => CookieService.hasRole(RoleEnum.ROLE_ISSUER);
    static hasRoleIssuerCorporateCard = (): boolean => CookieService.hasRole(RoleEnum.ROLE_ISSUER_CORPORATE_CARD);
    static hasRoleAmountAuthorizationCardPortfolioEditor = (): boolean => CookieService.hasRole(RoleEnum.ROLE_AMOUNT_AUTHORIZATION_CARD_PORTFOLIO_EDITOR);
    static hasRoleSeller = (): boolean => CookieService.hasRole(RoleEnum.ROLE_SELLER);
    static hasRoleDptoAdmin = (): boolean => CookieService.hasRole(RoleEnum.ROLE_DPTO_ADMIN);


    static clearToken = (): void => Cookies.remove(TOKEN_KEY);
    static clearUserName = (): void => Cookies.remove(USER_NAME_KEY);
    static clearUserExtraData = (): void => Cookies.remove(USER_EXTRA_DATA_KEY);

    static getToken = (): string | undefined => Cookies.get(TOKEN_KEY);
    static getUserName = (): string | undefined => Cookies.get(USER_NAME_KEY);
}
