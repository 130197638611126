import {AxiosResponse} from "axios";
import {ApiService} from "../../../../core/services";
import {IListNomenclator} from "./types";


const endpoints = {
    listHotelRegimens: 'api/hotel-regimens',
    listRoomTypes: 'api/room-types',
}

export const getAllHotelRegimens = (): Promise<AxiosResponse<IListNomenclator[]>> => ApiService.get(endpoints.listHotelRegimens);
export const getAllRoomTypes = (): Promise<AxiosResponse<IListNomenclator[]>> => ApiService.get(endpoints.listRoomTypes);
