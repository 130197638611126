import {RoleEnum} from "../auth/types";

export interface IRoleToView {
    id: string;
    name: string
}

export const rolesToViewList: IRoleToView[] = [{
    id: RoleEnum.ROLE_SYS_ADMIN, name: 'Administrador del sistema'
},
    {id: RoleEnum.ROLE_ISSUER, name: 'Emisor'},
    {id: RoleEnum.ROLE_AMOUNT_AUTHORIZATION_CARD_PORTFOLIO_EDITOR, name: 'Editor de monto en autorizaciones de tarjetas en cartera'},
    {id: RoleEnum.ROLE_ISSUER_CORPORATE_CARD, name: 'Emisor tarjetas corporativas'},
    {id: RoleEnum.ROLE_SELLER, name: 'Vendedor'},
    {
        id: RoleEnum.ROLE_DPTO_ADMIN, name: 'Departamento de administración'
    }]