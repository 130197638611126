import {IListNomenclator} from "../commons/types";
import {
    ILegacyIQuotationPackageRoomTypeDistributionPrice,
    ILegacyQuotationPackageAdditionalOptionPreview,
    ILegacyQuotationPackageOptionAdditionalItemPreview,
    ILegacyQuotationPackageOptionHotelPreview,
    ILegacyQuotationPackageOptionPreview
} from "../legacy-quotation-package-preview/types";
import {ILegacyQuotationPackageAdditionalOptionUpsert, ILegacyQuotationPackageOptionUpsert} from "./types";
import {IDestinationFormItem} from "./components/MultiDestinationForm/MultiDestinationForm";
import {IListAllLegacyQuotationWhatInclude} from "../legacy-quotation-what-includes/types";
import {IListManualHotelByDestination} from "../manual-hotels/types";
import {IListAllLegacyQuotationAdditional} from "../legacy-quotations-additionals/types";

const transformCheckInCheckOutToPreview = (dateStr: string | null): string => {
    if (dateStr) {
        let valueDateStrFormatYYYYMMDD = dateStr;
        if (dateStr.indexOf('T') !== -1) {
            valueDateStrFormatYYYYMMDD = dateStr.split('T')[0];
        }
        if (valueDateStrFormatYYYYMMDD.length == 10 && dateStr.indexOf('-') !== -1) {
            const [year, month, day] = valueDateStrFormatYYYYMMDD.split("-");
            return `${day}/${month}/${year}`;
        }
        return valueDateStrFormatYYYYMMDD;

    } else return '';
}

interface IDestinationWithCheckinCheckoutToPreview {
    destinationName: string;
    checkInDMY: string
    checkOutDMY: string
}

export const transformWhatIncludeToPreview = (items: IListAllLegacyQuotationWhatInclude[], destinationsConfig: IDestinationFormItem[]): IListAllLegacyQuotationWhatInclude[] => {
    const toPreview: IListAllLegacyQuotationWhatInclude[] = [];
    items.forEach(x => {
        if (x.isTemplateDestinationNight) {

            destinationsConfig.forEach(destinationConfigLine => {
                let nameToPreview = x.name.replace('x', (destinationConfigLine.nights || 0) + '').replace('destino', destinationConfigLine.destination?.name || '')
                if (Number(destinationConfigLine.nights) > 1) {
                    nameToPreview = nameToPreview.replace('noche', 'noches');
                }
                toPreview.push({...x, name: nameToPreview} as IListAllLegacyQuotationWhatInclude);
            })

        } else {
            toPreview.push(x);
        }
    });
    return toPreview;
}

export const transformDataToQuotationOptionPreview = (
    destinationLineWithCheckinCheckOut: IDestinationFormItem[],
    regimens: IListNomenclator[],
    roomTypes: IListNomenclator[],
    hotelsByDestination: Record<string, IListManualHotelByDestination[]>,
    options: ILegacyQuotationPackageOptionUpsert[]): ILegacyQuotationPackageOptionPreview[] => {

    const recordDestinations: Record<string, IDestinationWithCheckinCheckoutToPreview> = {};
    const recordRoomTypes: Record<string, string> = {};
    const recordRegimens: Record<string, string> = {};
    destinationLineWithCheckinCheckOut.forEach(x => {
        if (x.destination) {
            recordDestinations[x.destination.code] = {
                destinationName: x.destination.name,
                checkInDMY: transformCheckInCheckOutToPreview(x.checkIn),
                checkOutDMY: transformCheckInCheckOutToPreview(x.checkOut)
            }
        }
    });
    roomTypes.forEach(roomType => {
        recordRoomTypes[roomType.id] = roomType.name;
    });
    regimens.forEach(regimen => {
        recordRegimens[regimen.id] = regimen.name;
    });

    return options.map((option) => {

        const hotelsDetails = option.hotelOptions.map(hotelOption => {
            const maybeHotel = (hotelsByDestination[hotelOption.destinationCode] || []).find(x => x.id == hotelOption.manualHotelId);
            const toReturn = {
                hotelName: maybeHotel?.name || '',
                destinationName: recordDestinations[hotelOption.destinationCode]?.destinationName || '',
                regimenName: recordRegimens[hotelOption.regimenId] || '',
                checkInDMY: recordDestinations[hotelOption.destinationCode]?.checkInDMY || '',
                checkOutDMY: recordDestinations[hotelOption.destinationCode]?.checkOutDMY || '',
            } as ILegacyQuotationPackageOptionHotelPreview;
            if (maybeHotel && maybeHotel.urlToViewDescription) {
                toReturn['urlToViewDescription'] = maybeHotel.urlToViewDescription;
            }
            return toReturn;

        });

        const roomTypesDistributionPrices = option.optionPricesByRoomTypeDistribution.map(optionPrice => {
            return {
                roomTypeName: recordRoomTypes[optionPrice.roomTypeId] || '',
                adultPrice: optionPrice.adultPrice,
                minorPrice: optionPrice.minorPrice
            } as ILegacyIQuotationPackageRoomTypeDistributionPrice
        })

        return {
            hotelsDetail: hotelsDetails,
            roomTypeDistributionsPrices: roomTypesDistributionPrices
        } as ILegacyQuotationPackageOptionPreview;


    })
}

export const transformDataToQuotationAdditionalOptionPreview = (destinationLineWithCheckinCheckOut: IDestinationFormItem[]
    , additionalsByDestination: Record<string, IListAllLegacyQuotationAdditional[]>,
                                                                additionalOptions: ILegacyQuotationPackageAdditionalOptionUpsert[]): ILegacyQuotationPackageAdditionalOptionPreview[] => {

    return additionalOptions.map((option) => {


        const itemsPreview = option.additionalOptionItems.map(item => {
            const destinationName = destinationLineWithCheckinCheckOut.find(x => x.destination?.code == item.destinationCode)?.destination?.name;
            const additional = (additionalsByDestination[item.destinationCode] || []).find(x => x.id == item.additionalId);
            return {
                optionAdditionalItemId: item.optionAdditionalItemId,
                destinationName: destinationName || '',
                additionalName: additional?.name || '',
                additionalIcon: additional?.iconName,
                adultPrice: item.adultPrice !=null && item.adultPrice !=''?item.adultPrice:undefined,
                minorPrice:item.minorPrice !=null && item.minorPrice !=''? item.minorPrice:undefined

            } as ILegacyQuotationPackageOptionAdditionalItemPreview
        });

        return {
            optionId: option.optionId,
            orderPosition: option.orderPosition,
            additionalOptionItems: itemsPreview
        } as ILegacyQuotationPackageAdditionalOptionPreview
    });
}

export const toolbarOptionsForObservationsInQuotation = [
    [{size: ["small"]}]
];

export const containsVisibleText = (html: string): boolean => {
    const div = document.createElement("div");
    div.innerHTML = html;

    // Obtener el texto visible (excluye etiquetas)
    const text = div.textContent?.trim() ?? "";

    return text.length > 0;
}

export const adaptAdditionalOptionToSend = (x: ILegacyQuotationPackageAdditionalOptionUpsert[]): ILegacyQuotationPackageAdditionalOptionUpsert[]=>{


  return  x.map((x, index) => {
    const additionalOptionItems = x.additionalOptionItems.map(item=>{
        if(item.adultPrice =='') {
            delete item.adultPrice;
        }

        if(item.minorPrice =='') {
            delete item.minorPrice;
        }

        if(item.adultPrice !=null && item.adultPrice !='' ) {
             item.adultPrice = Number(item.adultPrice);
        }

        if(item.minorPrice !=null && item.minorPrice !='' ) {
            item.minorPrice = Number(item.minorPrice);
        }
return item;
    })

       return  {...x, orderPosition: index,additionalOptionItems:additionalOptionItems}

    });
}