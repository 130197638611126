import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StoreIcon from '@mui/icons-material/Store';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const PaymentMediaPreview: React.FC = () => {
    const theme = useTheme();

    const paymentMethods = [
        {
            label: 'Tarjetas de crédito',
            icon: <CreditCardIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />,
        },
        {
            label: 'Transferencia bancaria',
            icon: <AccountBalanceIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />,
        },
        {
            label: 'Pago en el local',
            icon: <StoreIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />,
        },
        {
            label: 'Redes de cobranza',
            icon: <AccountBalanceWalletIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />,
        },
    ];

    return (
        <Box
            sx={{
                border: 0,
                pl: '20px',
                mt: 1,
            }}
        >
            <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                 sx={{backgroundColor: theme.palette.primary.main, borderRadius: 2}} mb={1} mr={2}>
                <Typography variant="h6" sx={{color: 'white'}}>
                    Medios de pago
                </Typography>
            </Box>
            {/* Agregamos un Box con ml y mr para que empiece a la misma distancia */}
                <Grid container>
                    {paymentMethods.map((method, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index} textAlign="center">
                            <Box display="flex" flexDirection="column" alignItems="center">
                                {method.icon}
                                <Typography variant="body2" mt={1}>
                                    {method.label}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
        </Box>
    );
};

export default PaymentMediaPreview;
