import React from "react";
import {Box, Typography, useTheme} from "@mui/material";

interface LegacyQuotationHeaderPreviewProps {
    backgroundImage: string;
    logo: string;
    quoteNumber: number | string;
}

    const LegacyQuotationHeaderPreview: React.FC<LegacyQuotationHeaderPreviewProps> = ({
                                                                                           backgroundImage,
                                                                                           logo,
                                                                                           quoteNumber,
                                                                                       }) => {
        const theme = useTheme();
        return (
            <Box
                sx={{
                    position: "relative",
                    width: "100%",
                    height: "100px",
                    background: theme.palette.primary.main,
                    backgroundPosition: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingX: "20px",
                    paddingY: "10px",
                    color: "white",
                }}
            >


                {/* Logo en la esquina izquierda */}
                <Box
                    component="img"
                    src={logo}
                    alt="Company Logo"
                    sx={{
                        height: "70px",
                        width: "auto",
                        alignSelf: "center",
                        marginRight: "10px",
                        marginBottom:"10px"
                    }}
                />
            </Box>
    );
};

export default LegacyQuotationHeaderPreview;
