import {AxiosResponse} from "axios";
import {
    IFilterListLegacyQuotationPackage,
    IFlightItinerarySegmentInformation,
    ILegacyQuotationPackageUpsert,
    IListLegacyQuotationPackage
} from "./types";
import {ApiService} from "../../../../core/services";
import {IPaginatedResult, ISort} from "../../../../core/types";
import {cleanFilters, formatSortingFields} from "../../../../core/helper";

const endpoints = {
    list: 'api/legacy-quotation-package',
    getDetailToForm: 'api/legacy-quotation-package/:id',
    copy: 'api/legacy-quotation-package/:id/copy',
    upsert: 'api/legacy-quotation-package/:id',
    delete: 'api/legacy-quotation-package/:id',
    parsePNRToFlightItinerary: 'api/flight-itinerary/parse',
}

export const getLegacyQuotationPackages = (page = 1, pageSize = 10, sortingFields: ISort[], search: string, filters?: IFilterListLegacyQuotationPackage): Promise<AxiosResponse<IPaginatedResult<IListLegacyQuotationPackage>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(filters && cleanFilters(filters)), ...(search && search !== '' && {search: search})
        }
    });
};
export const getDetailLegacyQuotationPackage = (id: string): Promise<AxiosResponse<ILegacyQuotationPackageUpsert>> => ApiService.get(endpoints.getDetailToForm.replace(':id', id));
export const copyLegacyQuotationPackage = (id: string): Promise<AxiosResponse<{id:string}>> => ApiService.post(endpoints.copy.replace(':id', id), {});
export const upsertLegacyQuotationPackage = (id: string, data: ILegacyQuotationPackageUpsert): Promise<AxiosResponse<void>> => ApiService.put(endpoints.upsert.replace(':id', id), data);
export const deleteLegacyQuotationPackage = (id: string): Promise<AxiosResponse<void>> => ApiService.delete(endpoints.delete.replace(':id', id));
export const parsePNRToFlightItinerary = (pnr: string): Promise<AxiosResponse<IFlightItinerarySegmentInformation[]>> => ApiService.post(endpoints.parsePNRToFlightItinerary, {pnrToParse: pnr});

