import {AxiosResponse} from "axios";
import {formatSortingFields} from "../../../../core/helper";
import {IPaginatedResult, ISort} from "../../../../core/types";
import {
    IAutocompleteResponseIListLegacyQuotationExclusiveService,
    IListLegacyQuotationExclusiveService,
    IUpsertLegacyQuotationExclusiveService
} from "./types";
import {ApiService} from "../../../../core/services";

const endpoints = {
    list: 'api/legacy-quotation-exclusive-services',
    listAll: 'api/legacy-quotation-exclusive-services/list/all',
    get: 'api/legacy-quotation-exclusive-services/:id',
    create: 'api/legacy-quotation-exclusive-services',
    update: 'api/legacy-quotation-exclusive-services/:id',
    delete: 'api/legacy-quotation-exclusive-services/:id',
}

export const getExclusiveServices = (page = 1, pageSize = 10, sortingFields: ISort[], search: string): Promise<AxiosResponse<IPaginatedResult<IListLegacyQuotationExclusiveService>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(search && search !== '' && {search: search})
        }
    });
};
export const getAllExclusiveServicesGrouped = (): Promise<AxiosResponse<IAutocompleteResponseIListLegacyQuotationExclusiveService>> => ApiService.get(endpoints.listAll);
export const getExclusiveService = (id: string): Promise<AxiosResponse<IListLegacyQuotationExclusiveService>> => ApiService.get(endpoints.get.replace(':id', id ));
export const createExclusiveService = (data: IUpsertLegacyQuotationExclusiveService): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create, data);
export const updateExclusiveService = (id: string, data: IUpsertLegacyQuotationExclusiveService): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':id', id ), data);
export const deleteExclusiveService = (id: string): Promise<AxiosResponse<void>> => ApiService.delete(endpoints.delete.replace(':id', id ));
