import React, {useCallback, useEffect, useState} from 'react';
import {ILegacyQuotationFlightUpsert} from "../../types";
import {useNavigate, useParams} from "react-router-dom";
import {ShowAlertState} from "../../../../../../core/types";
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {extractErrorMessage, is404Error} from "../../../../../../core/helper";
import {appRoutes} from "../../../../../../core/const";

import Box from "@mui/material/Box";
import {
    LegacyQuotationFlightUpsertForm
} from "../../components/LegacyQuotationFlightUpsertForm/LegacyQuotationFlightUpsertForm";
import {IAutocompleteResponseIListLegacyQuotationWhatInclude} from "../../../legacy-quotation-what-includes/types";
import {getAllWhatIncludesGrouped} from "../../../legacy-quotation-what-includes/services";
import {getDetailLegacyQuotationFlight, upsertLegacyQuotationFlight} from "../../services";
import {parsePNRToFlightItinerary} from "../../../legacy-quotation-packages/services";
import {IFlightItinerarySegmentInformation} from "../../../legacy-quotation-packages/types";
import {noIncludesResultEmpty, whatIncludeResultEmpty} from "../../../commons/types";
import {
    IListAllLegacyQuotationFlightWhatIncludeTariffWithCategory
} from "../../../legacy-quotation-flight-what-include-tariff/types";
import {getAllWhatIncludeTariffGrouped} from "../../../legacy-quotation-flight-what-include-tariff/services";
import {
    convertAllWhatIncludeTariffGroupedToItemsWithCategory
} from "../../../legacy-quotation-flight-what-include-tariff/helpers";
import {IAutocompleteResponseIListLegacyQuotationNoInclude} from "../../../legacy-quotation-no-includes/types";
import {getAllNoIncludesGrouped} from "../../../legacy-quotation-no-includes/services";


const LegacyQuotationFlightUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [whatIncludeResult, setWhatIncludeResult] = useState<IAutocompleteResponseIListLegacyQuotationWhatInclude>(whatIncludeResultEmpty);
    const [noIncludeResult, setNoIncludeResult] = useState<IAutocompleteResponseIListLegacyQuotationNoInclude>(noIncludesResultEmpty);
    const [whatIncludeForTariffWithCategoryResult, setWhatIncludeForTariffWithCategoryResult] = useState<IListAllLegacyQuotationFlightWhatIncludeTariffWithCategory[]>([]);
    const [quotationFlightDetail, setQuotationFlightDetail] = useState<ILegacyQuotationFlightUpsert | null>(null);


    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();

    const loadQuotationFlight = useCallback(async () => {
        try {
            if (id && !id.startsWith('create')) {
                setLoading(true);
                const result = (await getDetailLegacyQuotationFlight(id)).data;
                setQuotationFlightDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el detalle de la cotización')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate(`${appRoutes.legacyQuotations.quotationFlight.list}`), 2500);
            }
        }
    }, [id, setLoading, setAlert]);


    const loadWhatIncludes = async () => {
        try {
            setLoading(true);
            const result = await getAllWhatIncludesGrouped();
            const finalResult = {
                ...result.data,
                system: result.data.system.filter(x => x.isTemplateDestinationNight == null)
            };
            //para solo vuelos no dice x noches en el destino porque no hace falta
            setWhatIncludeResult(finalResult);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el listado de que incluye')
            });
        } finally {
            setLoading(false);
        }
    };

    const loadWhatIncludesForTariff = async () => {
        try {
            setLoading(true);
            const result = await getAllWhatIncludeTariffGrouped();
            const finalResult = convertAllWhatIncludeTariffGroupedToItemsWithCategory(result.data);
            setWhatIncludeForTariffWithCategoryResult(finalResult);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el listado de que incluye en las tarifas de vuelos')
            });
        } finally {
            setLoading(false);
        }
    };

    const loadNoIncludes = async () => {
        try {
            setLoading(true);
            const result = await getAllNoIncludesGrouped();
            setNoIncludeResult(result.data);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el listado de no incluye')
            });
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        loadWhatIncludes();
    }, []);

    useEffect(() => {
        loadNoIncludes();
    }, []);


    useEffect(() => {
        loadWhatIncludesForTariff();
    }, []);

    useEffect(() => {
        loadQuotationFlight();
    }, [id]);


    const handleUpsertQuotationFlight = async (x: ILegacyQuotationFlightUpsert) => {
        try {
            setLoading(true);
            await upsertLegacyQuotationFlight(x.quotationId, x);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Cotización guardada satisfactoriamente'
            });
            setTimeout(() => window.location.href = appRoutes.legacyQuotations.quotationFlight.upsert.replace(':id', x.quotationId), 1500);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al guardar la cotización')
            });
        } finally {
            setLoading(false);
        }
    }
    const fetchItinerarySegmentsByPNR = async (pnr: string): Promise<IFlightItinerarySegmentInformation[]> => {
        try {
            setLoading(true);
            return ((await parsePNRToFlightItinerary(pnr)).data)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al extraer los datos del PNR')
            });
            return [];
        } finally {
            setLoading(false);
        }
    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {id && id.startsWith('create') && <LegacyQuotationFlightUpsertForm
                fetchItinerarySegmentsByPNR={fetchItinerarySegmentsByPNR}
                whatIncludesContainer={whatIncludeResult}
                noIncludesContainer={noIncludeResult}
                whatIncludesForTariffWithCategory={whatIncludeForTariffWithCategoryResult}
                loading={loading} id={id}
                refreshWhatIncludes={loadWhatIncludes}
                refreshNoIncludes={loadNoIncludes}
                refreshWhatIncludesForTariff={loadWhatIncludesForTariff}
                handleSubmitForm={handleUpsertQuotationFlight}/>}

            {id && !id.startsWith('create') && quotationFlightDetail && <LegacyQuotationFlightUpsertForm
                fetchItinerarySegmentsByPNR={fetchItinerarySegmentsByPNR}
                whatIncludesContainer={whatIncludeResult}
                noIncludesContainer={noIncludeResult}
                whatIncludesForTariffWithCategory={whatIncludeForTariffWithCategoryResult}
                loading={loading}
                refreshWhatIncludes={loadWhatIncludes}
                refreshNoIncludes={loadNoIncludes}
                refreshWhatIncludesForTariff={loadWhatIncludesForTariff}
                id={id}
                value={quotationFlightDetail}
                linkToClient={quotationFlightDetail.linkToClient||''}
                handleSubmitForm={handleUpsertQuotationFlight}/>}

            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default LegacyQuotationFlightUpsertContainer;
