import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
} from '@mui/material';
import {Add as AddIcon} from '@mui/icons-material';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import {
    ICreateCardPortfolioAuthorization,
    IListCardPortfolioAuthorization,
    IUpdateCardPortfolioAuthorization, IUpdateOnlyAmountCardPortfolioAuthorization
} from '../../types';
import {ShowAlertState} from "../../../../../../core/types";
import {
    createAuthorization,
    getAuthorizations,
    updateAuthorization,
    updateOnlyAmountInAuthorization
} from "../../services";
import {extractErrorMessage} from "../../../../../../core/helper";
import {IListAuthorizationOperator} from "../../../../../authorization-operators/types";
import {IDetailAirline} from "../../../../../airlines/types";
import {
    CardPortfolioAuthorizationUpsertForm,
    IUpsertCardPortfolioAuthorizationFormValue
} from "../CardPortfolioAuthorizationUpsertForm/CardPortfolioAuthorizationUpsertForm";
import {getAllAirlines} from "../../../../../airlines/services";
import {getAllAuthorizationOperatorsToCardPortfolio} from "../../../../../authorization-operators/services";
import EditIcon from "@mui/icons-material/Edit";
import {travellerPosName} from "../../constants";
import EmptyTable from "../../../../../../core/components/EmptyTable/EmptyTable";

interface CardPortfolioAuthorizationsListProps {
    cardPortfolioId: number;
    onLoading: (loading: boolean) => void;
    loading: boolean;
    setAlert: (alert: ShowAlertState) => void;
    onChangeAuthorizationsAmount: () => void;
    totalSale: number;
    allowCreateAuthorizations: boolean;
    allowEditAuthorizations: boolean;
    allowEditAmountInAuthorizations: boolean;
}

const CardPortfolioAuthorizationsList: React.FC<CardPortfolioAuthorizationsListProps> = ({
                                                                                             cardPortfolioId,
                                                                                             onLoading,
                                                                                             loading,
                                                                                             setAlert,
                                                                                             onChangeAuthorizationsAmount,
                                                                                             totalSale: totalOfSale,
                                                                                             allowCreateAuthorizations,
                                                                                             allowEditAuthorizations,
                                                                                             allowEditAmountInAuthorizations
                                                                                         }) => {
    const [authorizations, setAuthorizations] = useState<IListCardPortfolioAuthorization[]>([]);
    const [operators, setOperators] = useState<IListAuthorizationOperator[]>([]);
    const [airlines, setAirlines] = useState<IDetailAirline[]>([]);
    const [showCreateAuthorizationForm, setShowCreateAuthorizationForm] = useState<boolean>(false);
    const [showUpdateAuthorizationForm, setShowUpdateAuthorizationForm] = useState<boolean>(false);
    const [showUpdateOnlyAmountAuthorizationForm, setShowUpdateOnlyAmountAuthorizationForm] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<null | IListCardPortfolioAuthorization>(null);

    const loadAuthorizations = async () => {
        try {
            onLoading(true);
            const result = (await getAuthorizations(cardPortfolioId)).data;
            setAuthorizations(result);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar las autorizaciones de la tarjeta en cartera')
            });
        } finally {
            onLoading(false);
        }
    }

    const loadAirlines = async () => {
        try {
            onLoading(true);
            const result = (await getAllAirlines()).data;
            setAirlines(result);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar las compañías aéreas')
            });
        } finally {
            onLoading(false);
        }
    }

    const loadOperators = async () => {
        try {
            onLoading(true);
            const result = (await getAllAuthorizationOperatorsToCardPortfolio()).data;
            setOperators(result);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar los operadores')
            });
        } finally {
            onLoading(false);
        }
    }

    useEffect(() => {
        loadAuthorizations();
    }, [cardPortfolioId]);

    useEffect(() => {
        loadAirlines();
    }, []);

    useEffect(() => {
        loadOperators();
    }, []);

    const handleSubmitFormCreateAuthorization = async (value: IUpsertCardPortfolioAuthorizationFormValue) => {
        try {
            if(loading) return;
            onLoading(true);
            const valueToSend: ICreateCardPortfolioAuthorization = {
                airlineCode: value.airlineCode,
                authorizationCode: value.authorizationCode,
                amount: Number(value.amount),
                operatorId: value.operatorId,
                fileNumbers:  value.fileNumbers
            }
            await createAuthorization(cardPortfolioId, valueToSend);
            setShowCreateAuthorizationForm(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Autorización creada satisfactoriamente'
            });
            setTimeout(() => {
                onChangeAuthorizationsAmount();
                loadAuthorizations();
            }, 1500);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al crear la autorización')
            });
        } finally {
            onLoading(false);
        }
    }


    const handleSubmitFormUpdateAuthorization = async (value: IUpsertCardPortfolioAuthorizationFormValue) => {
        try {
            if(loading) return;
            onLoading(true);
            const valueToSend: IUpdateCardPortfolioAuthorization = {
                operatorId: value.operatorId,
                fileNumbers:  value.fileNumbers,
                airlineCode: value.airlineCode,
                authorizationCode: value.authorizationCode,
            }
            await updateAuthorization(cardPortfolioId, selectedItem?.id || -1, valueToSend);
            handleCloseUpdateDialog();
            setAlert({
                show: true,
                severity: 'success',
                message: 'Autorización actualizada satisfactoriamente'
            });
            setTimeout(() => {
                onChangeAuthorizationsAmount();
                loadAuthorizations();
            }, 1500);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al actualizar la autorización')
            });
        } finally {
            onLoading(false);
        }
    }


    const handleSubmitFormUpdateAmountInAuthorization = async (value: IUpsertCardPortfolioAuthorizationFormValue) => {
        try {
            if(loading) return;
            onLoading(true);
            const valueToSend: IUpdateOnlyAmountCardPortfolioAuthorization = {
                amount: Number(value.amount)
            }
            await updateOnlyAmountInAuthorization(cardPortfolioId, selectedItem?.id || -1, valueToSend);
            handleCloseUpdateOnlyAmountDialog();
            setAlert({
                show: true,
                severity: 'success',
                message: 'Monto de autorización actualizado satisfactoriamente'
            });
            setTimeout(() => {
                onChangeAuthorizationsAmount();
                loadAuthorizations();
            }, 1500);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al actualizar el monto de la autorización')
            });
        } finally {
            onLoading(false);
        }
    }



    const handleClickEdit = (item: IListCardPortfolioAuthorization) => {
        setSelectedItem(item);
        setShowUpdateAuthorizationForm(true);
    }

    const handleCloseUpdateDialog = () => {
        setSelectedItem(null);
        setShowUpdateAuthorizationForm(false);
    }

    const handleClickEditAmount = (item: IListCardPortfolioAuthorization) => {
        setSelectedItem(item);
        setShowUpdateOnlyAmountAuthorizationForm(true);
    }
    const handleCloseUpdateOnlyAmountDialog = () => {
        setSelectedItem(null);
        setShowUpdateOnlyAmountAuthorizationForm(false);
    }



    return (
        <Box sx={{width: '100%', padding: 3}}>
            <Typography variant="h5" component="div" sx={{mb: 2}}>
                Autorizaciones
            </Typography>
            {allowCreateAuthorizations &&
                <Toolbar sx={{display: 'flex', justifyContent: 'space-between', mb: 2}} style={{paddingLeft: 0}}>

                    <Button
                        variant="contained"
                        startIcon={<AddIcon/>}
                        onClick={() => setShowCreateAuthorizationForm(true)}
                    >
                        Nueva autorización
                    </Button>
                </Toolbar>}
            <TableContainer component="main" sx={{width: '100%'}}>
                <Table sx={{width: '100%'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                ID
                            </TableCell>
                            <TableCell>
                                Compañía aérea
                            </TableCell>
                            <TableCell>
                                Monto autorizado
                            </TableCell>
                            <TableCell>
                                Operador
                            </TableCell>
                            <TableCell>
                                File(s)
                            </TableCell>

                            <TableCell>
                                Código de autorización
                            </TableCell>

                            <TableCell>
                                Fecha de autorización
                            </TableCell>
                            <TableCell>
                                Autorizado por
                            </TableCell>

                            <TableCell>
                                Fecha de modificación
                            </TableCell>
                            <TableCell>
                                Modificado por
                            </TableCell>

                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {authorizations.map((item) => (
                            <TableRow
                                key={item.id}
                                sx={{

                                    backgroundColor: item.expiringSoon
                                        ? 'rgba(255, 99, 71, 0.2)'  // Fondo rojo claro si está próximo a vencer y no está eliminada
                                        : 'inherit',
                                    backgroundSize: '16px 16px',
                                    '&:hover': {

                                        backgroundColor: item.expiringSoon
                                            ? 'rgba(255, 99, 71, 0.3)'  // Más intenso al pasar el mouse
                                            : 'inherit',
                                    },
                                    position: 'relative',
                                }}
                                title={item.expiringSoon ? 'Próxima a vencer' : ''}
                            >
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item.airlineName ? item.airlineName : travellerPosName}</TableCell>
                                <TableCell>{item.amount} USD</TableCell>
                                <TableCell>{ item.operatorName ? item.operatorName : '-'}</TableCell>
                                <TableCell>{item.fileNumbers ? item.fileNumbers : '-'}</TableCell>
                                <TableCell>{item.authorizationCode}</TableCell>
                                <TableCell>{item.createdAt}</TableCell>
                                <TableCell>{item.createdBy}</TableCell>
                                <TableCell>{item.updatedAt ? item.updatedAt : '-'}</TableCell>
                                <TableCell>{item.updatedBy ? item.updatedBy : '-'}</TableCell>
                                <TableCell sx={{width: 150}}>
                                    {allowEditAuthorizations &&
                                        <IconButton title={'Modificar'} onClick={() => handleClickEdit(item)}>
                                            <EditIcon/>
                                        </IconButton>}
                                    {allowEditAmountInAuthorizations &&
                                        <IconButton title={'Modificar monto'} onClick={() => handleClickEditAmount(item)}>
                                            <CurrencyExchangeIcon/>
                                        </IconButton>}
                                </TableCell>
                            </TableRow>
                        ))}
                        {authorizations.length === 0 && <EmptyTable colSpan={11} message={'Sin autorizaciones para mostrar'} />}
                    </TableBody>
                </Table>
            </TableContainer>

            {showCreateAuthorizationForm &&
                <Dialog fullWidth={true} open={showCreateAuthorizationForm}
                        onClose={() => setShowCreateAuthorizationForm(false)}>
                    <DialogContent>

                        <DialogContentText>
                            <CardPortfolioAuthorizationUpsertForm loading={loading}
                                                                  handleClose={() => setShowCreateAuthorizationForm(false)}
                                                                  airlines={airlines}
                                                                  operators={operators}
                                                                  handleSubmitForm={handleSubmitFormCreateAuthorization}
                                                                  value={null}
                                                                  isUpdateOnlyAmount={false}
                            />
                        </DialogContentText>

                    </DialogContent>

                </Dialog>}

            {showUpdateAuthorizationForm && selectedItem != null &&
                <Dialog fullWidth={true} open={true}
                        onClose={() => handleCloseUpdateDialog()}>
                    <DialogContent>

                        <DialogContentText>
                            <CardPortfolioAuthorizationUpsertForm loading={loading}
                                                                  handleClose={handleCloseUpdateDialog}
                                                                  airlines={airlines}
                                                                  operators={operators}
                                                                  handleSubmitForm={handleSubmitFormUpdateAuthorization}
                                                                  value={selectedItem}
                                                                  isUpdateOnlyAmount={false}
                            />
                        </DialogContentText>

                    </DialogContent>

                </Dialog>}

            {showUpdateOnlyAmountAuthorizationForm && selectedItem != null &&
                <Dialog fullWidth={true} open={true}
                        onClose={() => handleCloseUpdateOnlyAmountDialog()}>
                    <DialogContent>

                        <DialogContentText>
                            <CardPortfolioAuthorizationUpsertForm loading={loading}
                                                                  handleClose={handleCloseUpdateOnlyAmountDialog}
                                                                  airlines={airlines}
                                                                  operators={operators}
                                                                  handleSubmitForm={handleSubmitFormUpdateAmountInAuthorization}
                                                                  value={selectedItem}
                                                                  isUpdateOnlyAmount={true}
                            />
                        </DialogContentText>

                    </DialogContent>

                </Dialog>}
        </Box>
    );
};

export default CardPortfolioAuthorizationsList;
