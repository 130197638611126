import {ILegacyQuotationFlightTariffOptionUpsert} from "./types";
import {ILegacyQuotationFlightTariffOptionPreview} from "../legacy-quotation-flight-preview/types";
import {
    IListAllLegacyQuotationFlightWhatIncludeTariffWithCategory
} from "../legacy-quotation-flight-what-include-tariff/types";

export const convertOptionsFromFormToOptionsToPreview = (
    options: ILegacyQuotationFlightTariffOptionUpsert[],
    whatIncludesForTariffWithCategory: IListAllLegacyQuotationFlightWhatIncludeTariffWithCategory[]
): ILegacyQuotationFlightTariffOptionPreview[] => {
    return options.map((option) => ({
        optionId: option.optionId,
        orderPosition: option.orderPosition,
        adultPrice: option.adultPrice,
        minorPrice: option.minorPrice,
        infantPrice: option.infantPrice,
        items: option.items.map((item) => {
            // Buscamos el objeto correspondiente para obtener el nombre de lo que incluye la tarifa
            const matchingInclude = whatIncludesForTariffWithCategory.find(
                (include) => include.id === item.whatIncludeTariffId
            );
            return {
                itemId: item.itemId,
                orderPosition: item.orderPosition,
                whatIncludeTariffName: matchingInclude ? matchingInclude.name : '',
                optionItemType: item.optionItemType,

            };
        }),
    }));
};
