import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import {Alert, Backdrop, CircularProgress, Paper, Snackbar, styled, useTheme} from '@mui/material';
import {ILegacyQuotationAdditional} from "../../types";
import {createAdditional} from "../../services";
import {ShowAlertState} from "../../../../../../core/types";
import {extractErrorMessage} from "../../../../../../core/helper";
import {
    LegacyQuotationAdditionalUpsertForm
} from "../LegacyQuotationAdditionalUpsertForm/LegacyQuotationAdditionalUpsertForm";
import {getAllDestinations} from "../../../../../destinations/services";
import {IDestinationList} from "../../../../../destinations/types";


type LegacyQuotationAdditionalsFormModalProps = {
    open: boolean;
    handleClose: (id: string | null) => void;
    destinationCode?:string
};

const StyledPaper = styled(Paper)(({theme}) => ({
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}));

const LegacyQuotationAdditionalsFormModal: React.FC<LegacyQuotationAdditionalsFormModalProps> = ({
                                                                                                     open,
                                                                                                     handleClose,
                                                                                                     destinationCode
                                                                                                 }) => {
    const theme = useTheme();

    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [destinations, setDestinations] = useState<IDestinationList[]>([]);

    const handleUpsertAdditional = async (value: ILegacyQuotationAdditional) => {
        try {
            if(loading) return;
            setLoading(true);
            const result = await createAdditional(value);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Adicional creado satisfactoriamente'
            });
            setTimeout(() => handleClose(result.data.id), 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al crear el adicional')
            });
            setLoading(false);
        }

    }

    const loadDestinations =  async () => {
        try {

            setLoading(true);
            const result = (await getAllDestinations()).data;
            setDestinations(result);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar los destinos')
            });
            setLoading(false);
        }
    }

    useEffect(()=>{
        loadDestinations();
    },[]);

    const handleContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    return (
        <>
            <Backdrop sx={{
                zIndex: theme.zIndex.modal + 1,
                color: '#fff',
            }} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Modal open={open}>
                <StyledPaper onClick={handleContentClick}>
                    <LegacyQuotationAdditionalUpsertForm loading={loading}
                                                         handleSubmitForm={handleUpsertAdditional}
                                                         destinations={destinations}
                                                         showReturnToList={false}
                                                         destinationCode={destinationCode}
                                                         handleClose={() => handleClose(null)}
                    />
                </StyledPaper>
            </Modal>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={alert.show} autoHideDuration={1500}
                      onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
};

export default LegacyQuotationAdditionalsFormModal;
