import {IFlightTypeWithName} from "./types";

export const flightTypeRTId ='RT';
export const flightTypeOWId ='OW';


export const flightTypesWithName:IFlightTypeWithName[] = [
    {id:flightTypeOWId, name:'Solo ida (One Way)'},
    {id:flightTypeRTId, name:'Ida y vuelta (Round Trip)'},
]

export const optionItemTypeGOING ='GOING'
export const optionItemTypeRETURN ='RETURN'