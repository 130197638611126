import React from 'react';
import {Card, CardContent, Typography, Box, useTheme, Grid} from '@mui/material';

const BaggageInfoCardPreview: React.FC = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                border: 0,
                pl: '20px',
                mt: 1,
            }}
        >
            <Box display="flex" alignItems="center" gap={1} flex={1} p={1}
                 sx={{backgroundColor: theme.palette.primary.main, borderRadius: 2}} mb={1} mr={2}>
                <Typography variant="h6" sx={{color: 'white'}}>
                    Información sobre equipajes
                </Typography>
            </Box>

            <Card variant="outlined" sx={{ borderRadius: 3, mb: 2,mr:2 }}>
                <CardContent>
                    <Box mb={2}>
                        <Typography
                            variant="body1"
                            sx={{ fontWeight: 'bold', textDecoration: 'underline' }}
                        >
                            Artículo personal:
                        </Typography>
                        <Typography variant="body2">
                            Bolso o mochila pequeña, incluido en todas las tarifas
                        </Typography>
                        <Typography variant="body2">
                            Peso máximo 10 kg (22lb)
                        </Typography>
                    </Box>
                    <Box mb={2}>
                        <Typography
                            variant="body1"
                            sx={{ fontWeight: 'bold', textDecoration: 'underline' }}
                        >
                            Equipaje de mano:
                        </Typography>
                        <Typography variant="body2">
                            Carry on 10 (22 lb)
                        </Typography>
                    </Box>
                    <Box mb={2}>
                        <Typography
                            variant="body1"
                            sx={{ fontWeight: 'bold', textDecoration: 'underline' }}
                        >
                            Equipaje en bodega:
                        </Typography>
                        <Typography variant="body2">
                            Peso máximo 23 kg (51 lb)
                        </Typography>
                    </Box>
                    <Box mt={3}>
                        <Typography variant="body2">
                            Para más información sobre las dimensiones, excesos de equipajes, artículos permitidos y políticas generales se recomienda visitar el sitio web de la compañía con la que realiza el viaje.
                        </Typography>
                    </Box>
                </CardContent>
            </Card>

        </Box>
    );

};

export default BaggageInfoCardPreview;
