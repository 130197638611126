import React from 'react';
import {Box, Button, Card, CardContent, styled, TextField, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {useNavigate} from "react-router-dom";
import {IDetailLegacyQuotationFlightWhatIncludeTariff, ILegacyQuotationFlightWhatIncludeTariff} from "../../types";
import {appRoutes} from "../../../../../../core/const";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export interface LegacyQuotationFlightWhatIncludeTariffUpsertFormProps {
    handleSubmitForm: (value: ILegacyQuotationFlightWhatIncludeTariff) => void;
    loading: boolean;
    showReturnToList?: boolean;
    handleClose?: () => void;
    value?: IDetailLegacyQuotationFlightWhatIncludeTariff
}

const DivTitle = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

export const LegacyQuotationFlightWhatIncludeTariffUpsertForm: React.FC<LegacyQuotationFlightWhatIncludeTariffUpsertFormProps> = ({
                                                                                                                                      handleSubmitForm,
                                                                                                                                      loading,
                                                                                                                                      showReturnToList = true,
                                                                                                                                      handleClose,
                                                                                                                                      value
                                                                                                                                  }) => {

    const methods = useForm<ILegacyQuotationFlightWhatIncludeTariff>({
        mode: 'all',
        defaultValues: {name: value?.name || ''}
    });

    const navigate = useNavigate();
    const {
        control,
        formState: {errors, isValid},
        getValues,
    } = methods;


    return (
        <>
            <Card sx={{maxWidth: 600, width: '100%'}}>
                <CardContent>
                    <DivTitle>
                        <Typography variant="h6" style={{fontWeight: 'bold'}}
                                    p={1}>{value ? 'Actualizar qué incluye para tarifas de vuelo' : 'Crear nuevo qué incluye para tarifas de vuelo'}</Typography>
                        {handleClose && <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>}
                    </DivTitle>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>

                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'},
                                    maxLength: {
                                        value: 500,
                                        message: 'El campo excede el largo máximo permitido de: 500 caracteres'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Nombre"
                                        size={'small'}
                                        type="text"
                                        helperText={errors.name ? errors.name.message : ''}
                                        error={!!errors.name}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>


                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            {showReturnToList && <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate(`${appRoutes.legacyQuotations.quotationFlightWhatIncludeTariff.list}`)}
                            >
                                Volver al listado
                            </Button>}
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={() => handleSubmitForm(getValues())}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};