import {getAllWhatIncludeTariffGrouped} from "./services";
import {
    IAutocompleteResponseLegacyQuotationFlightWhatIncludeTariff,
    IListAllLegacyQuotationFlightWhatIncludeTariffWithCategory
} from "./types";

export const convertAllWhatIncludeTariffGroupedToItemsWithCategory = (data:IAutocompleteResponseLegacyQuotationFlightWhatIncludeTariff):IListAllLegacyQuotationFlightWhatIncludeTariffWithCategory[]=>{
    return [
        ...data.system.map((it) => ({...it, category: "Creados por el sistema" as const})),
        ...data.mine.map((it) => ({...it, category: "Creados por mi" as const})),
        ...data.others.map((it) => ({...it, category: "Creados por otros" as const})),
    ];

}