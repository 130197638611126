import React from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Box,
    Typography
} from '@mui/material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { IFlightItinerarySegmentInformation } from "../../../legacy-quotation-packages/types";

interface FlightItineraryTableProps {
    segments: IFlightItinerarySegmentInformation[];
}

const formatDateToRenderToUser = (dateStr: string, hourStr: string): string => {
    const combinedDate = new Date(`${dateStr}T${hourStr}`);
    return format(combinedDate, 'dd MMM - HH:mm', { locale: es });
};

const formatFlightNumber = (airlineCode:string, flightNumber:string):string=>{
    let flightNumberNew = flightNumber;
    if(flightNumber.indexOf(airlineCode)!==-1){
        flightNumberNew=  flightNumberNew.replace(airlineCode,'');
        flightNumberNew=  flightNumberNew.replace(' ','');
    }
    return flightNumberNew;
}

export const FlightItineraryTable: React.FC<FlightItineraryTableProps> = ({ segments }) => {
    if (!segments || segments.length === 0) {
        return (
            <Box mt={2}>
                <Typography variant="body1" color="textSecondary">
                    No hay segmentos de itinerario para mostrar.
                </Typography>
            </Box>
        );
    }

    // Estilos comunes para celdas: sin salto de línea, padding responsivo y con borde.
    const cellStyles = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '1rem',
        fontWeight: 'bold',
        textAlign: 'center' as const,
        minWidth: '100px',
        p: { xs: 1, sm: 1.7 },
        border: '1px solid #e0e0e0'
    };

    // Estilo especial para la columna "Vuelo" (más pequeña)
    const vueloCellStyles = {
        ...cellStyles,
        width: '90px',   // ancho fijo menor
        minWidth: '90px'
    };

    return (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
                <TableHead sx={{ backgroundColor: '#f0f0f0' }}>
                    <TableRow>
                        <TableCell sx={cellStyles}>Aerolínea</TableCell>
                        <TableCell sx={vueloCellStyles}>Vuelo</TableCell>
                        <TableCell sx={cellStyles}>Salida</TableCell>
                        <TableCell sx={cellStyles}>De</TableCell>
                        <TableCell sx={cellStyles}>Llegada</TableCell>
                        <TableCell sx={cellStyles}>A</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {segments.map((seg, index) => (
                        <TableRow
                            key={index}
                            sx={{
                                backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff'
                            }}
                        >
                            <TableCell sx={{ ...cellStyles, textAlign: 'left' }}>
                                <Box display="flex" alignItems="center" gap={1}>
                                    {seg.airline.image && (
                                        <img
                                            src={seg.airline.image}
                                            alt={seg.airline.name}
                                            style={{ width: 25, height: 25, objectFit: 'contain' }}
                                        />
                                    )}
                                    <Typography variant="body2">
                                        {seg.airline.name}
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell sx={vueloCellStyles}>
                                <Typography variant="body2">
                                    {formatFlightNumber(seg.airline.code,seg.flightNumber)}
                                </Typography>
                            </TableCell>
                            <TableCell sx={cellStyles}>
                                <Typography variant="body2" fontWeight="bold">
                                    {formatDateToRenderToUser(seg.departure.date, seg.departure.hour)}
                                </Typography>
                            </TableCell>
                            <TableCell sx={cellStyles}>
                                <Typography variant="body2">
                                    {seg.departure.airport.cityName} - {seg.departure.airport.code}
                                </Typography>
                            </TableCell>
                            <TableCell sx={cellStyles}>
                                <Typography variant="body2" fontWeight="bold">
                                    {formatDateToRenderToUser(seg.arrival.date, seg.arrival.hour)}
                                </Typography>
                            </TableCell>
                            <TableCell sx={cellStyles}>
                                <Typography variant="body2">
                                    {seg.arrival.airport.cityName} - {seg.arrival.airport.code}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
