import {IAutocompleteResponseIListLegacyQuotationWhatInclude} from "../legacy-quotation-what-includes/types";
import {IAutocompleteResponseIListLegacyQuotationNoInclude} from "../legacy-quotation-no-includes/types";

export interface IListNomenclator {
    id: string;
    name: string;
}

export const whatIncludeResultEmpty: IAutocompleteResponseIListLegacyQuotationWhatInclude = {
    system: [],
    others: [],
    mine: []
};

export const noIncludesResultEmpty: IAutocompleteResponseIListLegacyQuotationNoInclude = {
    system: [],
    others: [],
    mine: []
};