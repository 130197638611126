import React, {useEffect, useState} from "react";
import {Box, Button, IconButton, List, ListItem, ListItemIcon, ListItemText, TextField,} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import {Add, AddCircle, Refresh} from "@mui/icons-material";
import {
    IAutocompleteResponseIListLegacyQuotationNoInclude,
    IListAllLegacyQuotationNoInclude,
} from "../../../legacy-quotation-no-includes/types";
import {IconRenderer} from "../IconRenderer/IconRenderer";
import LegacyQuotationNoIncludeFormModal
    from "../../../legacy-quotation-no-includes/components/LegacyQuotationNoIncludeFormModal/LegacyQuotationNoIncludeFormModal";

/** Para agrupar en el Autocomplete */
type CategoryType = "Creados por el sistema" | "Creados por mi" | "Creados por otros";

/** Agregamos 'category' internamente */
interface IListItemWithCategory extends IListAllLegacyQuotationNoInclude {
    category: CategoryType;
}

/** Props del componente hijo */
interface NoIncludeSelectorProps {
    data: IAutocompleteResponseIListLegacyQuotationNoInclude;
    preselectedIds?: string[];
    onChange?: (selected: IListAllLegacyQuotationNoInclude[]) => void;
    refreshData: () => Promise<void>;
    readonly?: boolean
}

/** Helper para comparar IDs */
function haveSameIds(
    arrA: IListAllLegacyQuotationNoInclude[],
    arrB: IListAllLegacyQuotationNoInclude[]
): boolean {
    if (arrA.length !== arrB.length) return false;
    for (let i = 0; i < arrA.length; i++) {
        if (arrA[i].id !== arrB[i].id) return false;
    }
    return true;
}

const NoIncludeSelectorComponent: React.FC<NoIncludeSelectorProps> = ({
                                                                          data,
                                                                          preselectedIds,
                                                                          onChange,
                                                                          refreshData,
                                                                          readonly = false
                                                                      }) => {
    const [showModalCreateNoInclude, setShowModalCreateNoInclude] = useState<boolean>(false);
    const groupByFn = (option: IListItemWithCategory) => option.category;

    const [allItems, setAllItems] = useState<IListItemWithCategory[]>([]);
    const [selectedItems, setSelectedItems] = useState<IListItemWithCategory[]>([]);
    const [currentSelection, setCurrentSelection] = useState<IListItemWithCategory | null>(null);
    const [prevOriginal, setPrevOriginal] = useState<IListAllLegacyQuotationNoInclude[]>([]);
    const [preselectIdOfLastCreate, setPreselectIdOfLastCreate] = useState<string | null>(null);

    useEffect(() => {
        // Aplanamos la data
        const flattened: IListItemWithCategory[] = [
            ...data.system.map((it) => ({...it, category: "Creados por el sistema" as const})),
            ...data.mine.map((it) => ({...it, category: "Creados por mi" as const})),
            ...data.others.map((it) => ({...it, category: "Creados por otros" as const})),
        ];

        if (preselectedIds && preselectedIds.length && selectedItems.length === 0) {
            // Primera carga: asignar preseleccionados y el resto
            const pre: IListItemWithCategory[] = [];
            const rest: IListItemWithCategory[] = [];

            flattened.forEach((item) => {
                if (preselectedIds.includes(item.id)) {
                    pre.push(item);
                } else if (preselectIdOfLastCreate === item.id) {
                    pre.push(item);
                } else {
                    rest.push(item);
                }
            });
            setSelectedItems(pre);
            setAllItems(rest);
        } else {
            // Si ya hay selección, preservarla y actualizar la lista de opciones (allItems)
            let rest = flattened.filter(
                (item) => !selectedItems.find((selected) => selected.id === item.id)
            );
            if (preselectIdOfLastCreate) {
                const itemToSet = rest.find(x => x.id === preselectIdOfLastCreate);
                rest = rest.filter(x => x.id != preselectIdOfLastCreate);
                if (itemToSet) {
                    setSelectedItems([...selectedItems, itemToSet])
                }
            }
            setAllItems(rest);
        }
        if (preselectIdOfLastCreate) {
            setPreselectIdOfLastCreate(null);
        }
        // No reiniciamos prevOriginal para mantener la comparación de cambios
    }, [data, preselectedIds]);

    useEffect(() => {
        if (!onChange) return;
        const originalShape = selectedItems.map(({category, ...rest}) => rest);
        if (!haveSameIds(originalShape, prevOriginal)) {
            onChange(originalShape);
            setPrevOriginal(originalShape);
        }
    }, [selectedItems, onChange, prevOriginal]);

    const handleAdd = () => {
        if (!currentSelection) return;
        setSelectedItems((prev) => [...prev, currentSelection]);
        setAllItems((prev) => prev.filter((x) => x.id !== currentSelection.id));
        setCurrentSelection(null);
    };

    const handleRemove = (id: string) => {
        const found = selectedItems.find((it) => it.id === id);
        if (!found) return;
        setSelectedItems((prev) => prev.filter((it) => it.id !== id));
        setAllItems((prev) => [...prev, found]);
    };

    const handleCloseModal = (idCreated: string | null) => {
        setShowModalCreateNoInclude(false);
        if (idCreated) {
            setPreselectIdOfLastCreate(idCreated);
            refreshData();
        }
    }


    return (
        <Box>
            <Box display="flex" gap={2} alignItems="center" mb={2}>
                <Autocomplete
                    options={allItems}
                    groupBy={groupByFn}
                    disabled={readonly}
                    getOptionLabel={(option) => option.name}
                    value={currentSelection}
                    onChange={(_, newValue) => setCurrentSelection(newValue)}
                    sx={{width: 400}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Seleccione un elemento"
                            variant="outlined"
                            size="small"
                            disabled={readonly}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {params.InputProps.endAdornment}
                                        <IconButton onClick={refreshData} title="Refrescar opciones" size="small"
                                                    disabled={readonly}>
                                            <Refresh/>
                                        </IconButton>
                                        <IconButton onClick={() => setShowModalCreateNoInclude(true)}
                                                    title="Crear uno nuevo" size="small" disabled={readonly}>
                                            <Add/>
                                        </IconButton>
                                    </>
                                ),
                            }}
                        />
                    )}
                />
                {!readonly && <Button
                    onClick={handleAdd}
                    variant="outlined"
                    disabled={!currentSelection}
                    startIcon={<AddCircle/>}
                >
                    Agregar
                </Button>}
            </Box>

            <List dense>
                {selectedItems.map((item) => (
                    <ListItem
                        key={item.id}
                        secondaryAction={
                            !readonly ? <IconButton color="error" edge="end" onClick={() => handleRemove(item.id)}>
                                <DeleteIcon/>
                            </IconButton> : <></>
                        }
                    >
                        <ListItemIcon
                            sx={{
                                // Reducir el minWidth para compactar
                                minWidth: 32,
                            }}
                        >
                            <IconRenderer iconName={item.iconName}/>
                        </ListItemIcon>
                        <ListItemText primary={item.name}/>
                    </ListItem>
                ))}
            </List>
            {showModalCreateNoInclude &&
                <LegacyQuotationNoIncludeFormModal open={showModalCreateNoInclude} handleClose={handleCloseModal}/>}
        </Box>
    );
};

export const NoIncludeSelector = React.memo(NoIncludeSelectorComponent);
